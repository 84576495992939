import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";

import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController,} from "../../context";
import {AppContext} from "../../lib/AppContext";
import Drawer from "@mui/material/Drawer";
import InventoryCardTable from "../inventory/pane/InventoryCardTable";
import {clientAddToCart} from "../common/api/CartApi";

function ProductSearchNavbar({
                               absolute = false,
                               light = false,
                               isMini = false,
                               title,
                               client,
                               open = false,
                               handleClose,
                               handleSave
                             }) {

  const [searchCriteria, setSearchCriteria] = useState();

  const [navbarType, setNavbarType] = useState();
  const [context, setContext] = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const sendToCartFn = (item) => {
    let _item = {...item};
    _item.quantity = 1;
    clientAddToCart(_item, client?.guid).then(resp => {
      let _context = {...context};
      _context.toast = {
        message: 'Added to cart',
      }
      setContext({..._context});
      handleSave()
    });
  }

  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <>
      <Drawer
        sx={{
          width: '60%',
          padding: '5px 20px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {width: '60%', padding: '5px 20px', boxSizing: 'border-box'},
        }}
        onClose={(e) => {
          handleClose(false);
        }}
        open={open}
        anchor={'right'}
        variant={'temporary'}>
        <InventoryCardTable
          client={client}
          handleClose={()=>{
            handleClose(false);
          }}
          handleCartView={()=>{
            handleClose(true);
          }}
          sendToCart={(e) => {
            sendToCartFn(e);
          }}
        />
      </Drawer>

    </>
  );
}

// Typechecking props for the ProductSearchNavbar
ProductSearchNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  client: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func
};

export default ProductSearchNavbar;
