import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../lib/AppContext";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import {getCustomerSales} from "../../common/api/CustomerProfileApi";
import {Chip} from "@mui/material";
import {formatCurrency, formatDateTime} from "../../common/Utils";
import Icon from "@mui/material/Icon";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import NotificationItem from "../../Items/NotificationItem";
import ViewSaleItemModal from "../../inventory/ViewSaleItemModal";
import MDButton from "../../../components/MDButton";
import Loading from "../../common/Loading";

export default function CustomerSaleHistory(props) {
  const [context, setContext] = useContext(AppContext);
  const customer = props.customer;
  const [salesResp, setSalesResp] = useState();
  const [viewingSale, setViewingSale] = useState(false);
  const [saleItem, setSaleItem] = useState({});
  const [savingSaleItem, setSavingSaleItem] = useState(false);

  const [salesLoading, setSalesLoading] = useState(false);

  useEffect(()=>{
    getCustomerSalesFn(props?.customer?.guid);
  }, [props?.saleHistoryIndex]);

  const getCustomerSalesFn = async (guid) => {
    setSalesLoading(true);
    let result = await getCustomerSales(guid);
    setSalesResp(result);
    setSalesLoading(false);
  }

  useEffect(() => {
    getCustomerSalesFn(props?.customer?.guid);
  }, [props?.customer?.guid]);

  const viewSale = (saleItem) => {
    props.modalActive();
    setSaleItem(saleItem);
    setViewingSale(true);
  }

  const saveSaleItem = async () => {
    setSavingSaleItem(false);
    setViewingSale(false);
    props.modalInactive();
    getCustomerSalesFn(props?.customer?.guid);
  }

  const SaleItemActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <span variant="contained" {...bindTrigger(popupState)}>
           <MDButton
             variant="outlined"
             color="secondary"
             size="small"
             circular
             iconOnly
           >
            <Icon>more_horiz</Icon>
          </MDButton>
          </span>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                viewSale(item)
              }} icon={<Icon>receipt</Icon>} title="View Sale"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const closeSaleItemModal = () => {
    setViewingSale(false);
    props.modalInactive();
  }

  return (
    <>
      <div id="chart">
        <MDTypography variant="h5" fontWeight="regular">
          Customer Sale History
        </MDTypography>
        <Divider/>
        <ul className={'list-group'}>
          {salesLoading && <Loading />}
          {!salesLoading && salesResp?.salesItems?.length === 0 && <li className={'list-group-item'}>
            <center>No recent sales</center>
          </li>
          }
          {!salesLoading && salesResp?.salesItems.sort((a, b) => b.id - a.id).map((item, index) => {
              return (
                <li
                  className={'list-group-item'}
                  key={index}>


                                    <span
                                      className={'pull-right'}>

                                     <span className="d-block pull-right px-2 cursor-pointer">
                                        <SaleItemActions item={item}/>
                                      </span>
                                    </span>
                  {item?.itemName}
                  <br/>
                  <small>{formatDateTime(item.purchaseDate)}</small>
                  <br/>
                  <Chip
                    label={<>
                      {item.refunded && <>

                        {(item.chargeType?.name || 'Unspecified') + ' - '}
                        <span className={'text-decoration-line-through'}>
                      {formatCurrency(item.chargeAmount)}
                    </span>
                        &nbsp;(refunded {formatCurrency(item.refundAmount)})
                      </>
                      }
                      {!item.refunded && <>
                        {(item.chargeType?.name || 'Unspecified') + ' - ' + formatCurrency(item.chargeAmount)}
                      </>}
                    </>
                    }
                    variant={item.refunded ? 'filled' : 'outlined'}
                    color={item.refunded ? 'secondary' : 'primary'}/>
                </li>
              )
            }
          )}
        </ul>
      </div>

      {viewingSale && <ViewSaleItemModal
        saleItem={saleItem}
        show={viewingSale}
        saving={savingSaleItem}
        handleSave={saveSaleItem}
        handleClose={closeSaleItemModal}/>}

    </>
  );
}