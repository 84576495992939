import React, {useContext, useEffect, useState} from "react";
import Meta from "./common/Meta";
import ContextSearchNavbar from "./navbars/ContextSearchNavbar";
import DashboardLayout from "./LayoutContainers/DashboardLayout";
import MDBox from "../components/MDBox";
import Grid from "@mui/material/Grid";
import Footer from "./Footer";
import StatisticsCard from "../app/reports/card/StatisticsCard";

import {
  getActiveMembers,
  getReportDataLastMonth,
  getReportDataThisMonth,
  getReportDataToday,
} from "./common/api/ReportApi";
import {formatCurrency, formatDateMask} from "./common/Utils";
import {AppContext} from "../lib/AppContext";
import SalesCard from "./reports/card/SalesCard";
import moment from "moment";

export default function SalesDashboard(props) {

  const [context, setContext] = useContext(AppContext);

  const [reportDataSales, setReportDataSales] = useState();
  const [reportDataMonthlyBilling, setReportDataMonthlyBilling] = useState();
  const [reportDataLastMonthBilling, setReportDataLastMonthBilling] = useState();

  const [reportDataVisits, setReportDataVisits] = useState();

  const [reportDataTodaySales, setReportDataTodaySales] = useState();
  const [reportDataTodayVisits, setReportDataTodayVisits] = useState();

  const [lastMonthSalesData, setLastMonthSalesData] = useState();
  const [activeMembers, setActiveMembers] = useState();
  const [passMembers, setPassMembers] = useState();
  const [lastMonthVisitsData, setLastMonthVisitsData] = useState();

  const getSalesData = () => {
    getReportDataToday().then((response) => {
      setReportDataTodaySales(formatCurrency(response?.totalSales));
      setReportDataTodayVisits(response?.totalVisits || 0);
    });
    getReportDataThisMonth().then((response) => {
      setReportDataSales(formatCurrency(response?.totalSales));
      setReportDataVisits(response?.totalVisits || 0);
      setReportDataMonthlyBilling(formatCurrency(response?.totalBilling));
    });

    getActiveMembers().then((response) => {
      setActiveMembers(response?.activeMembers || 0);
      setPassMembers(response?.passMembers || 0);
    });

    getReportDataLastMonth().then((response) => {
      setLastMonthSalesData(formatCurrency(response?.totalSales));
      setLastMonthVisitsData(response?.totalVisits || 0);
    });
  }

  useEffect(() => {
    getSalesData();
  }, []);

  const [lastMonthRange, setLastMonthRange] = useState();
  const [currentMonthRange, setCurrentMonthRange] = useState();

  const getLastMonthSalesRange = () => {
    let today = moment();
    let lastMonth = today.subtract(1, 'month');
    let d = new Date(lastMonth);
    return d.getFullYear() + '/' + formatDateMask(d, 'MMM');
  }

  const getCurrentMonthSalesRange = () => {
    const d = new Date();
    return d.getFullYear() + '/' + formatDateMask(d, 'MMM');
  }

  useEffect(()=>{
    setLastMonthRange(getLastMonthSalesRange());
    setCurrentMonthRange(getCurrentMonthSalesRange());
  },[])


  return (
    <DashboardLayout>

      <Meta pageTitle={'Sales Dashboard'}/>

      <ContextSearchNavbar title="Sales Dashboard"/>

      <MDBox py={3}>

        <MDBox mb={3}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={4}>

              {reportDataTodaySales &&
                <>
                  <SalesCard
                    reportBaseUrl={'sales'}
                    title="sales"
                    count={reportDataTodaySales}
                    range={null}
                    percentage={{
                      color: "success",
                      label: "Sales Today",
                    }}
                  />

                </>
              }

            </Grid>

            <Grid item xs={12} sm={4}>


              {reportDataSales &&
                <>
                  <SalesCard
                    reportBaseUrl={'sales'}
                    title="sales"
                    count={reportDataSales}
                    range={currentMonthRange}
                    percentage={{
                      color: "success",
                      label: "Sales This Month",
                    }}
                  />

                </>
              }

            </Grid>

            <Grid item xs={12} sm={4}>
              {lastMonthSalesData &&
                <>
                  <SalesCard
                    reportBaseUrl={'sales'}
                    title="sales"
                    range={lastMonthRange}
                    count={lastMonthSalesData}
                    percentage={{
                      color: "success",
                      label: "Sales Last Month",
                    }}
                  />
                </>
              }
            </Grid>

          </Grid>
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12} sm={4}>
              {activeMembers >= 0 &&
                <>
                  <StatisticsCard
                    reportBaseUrl={'memberships'}
                    range={currentMonthRange}
                    title="Memberships"
                    count={activeMembers}
                    percentage={{
                      color: "success",
                      label: "Active Recurring Memberships",
                    }}
                  />
                </>
              }
            </Grid>

            <Grid item xs={12} sm={4}>
              {passMembers >= 0 &&
                <>
                  <StatisticsCard
                    reportBaseUrl={'memberships'}
                    range={currentMonthRange}
                    title="Memberships"
                    count={passMembers}
                    percentage={{
                      color: "success",
                      label: "Active Pass Memberships",
                    }}
                  />
                </>
              }
            </Grid>

            <Grid item xs={12} sm={4}>
              {reportDataMonthlyBilling &&
                <>
                  <SalesCard
                    title="billing"
                    count={reportDataMonthlyBilling}
                    range={currentMonthRange}
                    reportBaseUrl={'billing'}
                    percentage={{
                      color: "success",
                      label: "Recurring Membership Billing",
                    }}
                  />
                </>
              }
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              {reportDataTodayVisits &&
                <MDBox mt={2}>
                  <StatisticsCard
                    title="visits"
                    reportBaseUrl={'visits'}
                    count={reportDataTodayVisits}
                    percentage={{
                      color: "success",
                      label: "Visits Today",
                    }}
                  />
                </MDBox>
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              {reportDataVisits &&
                <MDBox mt={2}>
                  <StatisticsCard
                    reportBaseUrl={'visits'}
                    range={currentMonthRange}
                    title="visits"
                    count={reportDataVisits}
                    percentage={{
                      color: "success",
                      label: "Visits This Month",
                    }}
                  />
                </MDBox>
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              {lastMonthVisitsData &&
                <MDBox mt={2}>
                  <StatisticsCard
                    reportBaseUrl={'visits'}
                    range={lastMonthRange}
                    title="visits"
                    count={lastMonthVisitsData}
                    percentage={{
                      color: "success",
                      label: "Visits Last Month",
                    }}
                  />
                </MDBox>
              }
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}