import {Col, Row} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {
  assumeIdentity,
  getCompany,
  getCompanyEmployees,
  getStates,
  saveCompany,
  saveEmployee
} from "../common/api/WebApi";
import {displayPhone, formatDate} from "../common/Utils";
import {Card, CardContent} from "@mui/material";
import ManageCompanyModal from "./ManageCompanyModal";
import ManageEmployeeModal from "../employee/ManageEmployee";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import Footer from "../Footer";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Meta from "../common/Meta";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import PageLoading from "../common/PageLoading";

export default function Company(props) {

  const [context, setContext] = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  let {guid} = useParams();
  const [refreshCompany, setRefreshCompany] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [onEdit, setOnEdit] = useState(guid == null);
  const [newCompany, setNewCompany] = useState(guid == null);
  const [company, setCompany] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [originalCompany, setOriginalCompany] = useState({});
  const [states, setStates] = useState([]);
  const [manageEmployee, setManageEmployee] = useState(false);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setStates(result);
      }
    });
  }, []);

  useEffect(() => {
    setCompany({});
  }, [location]);

  const keyPressListener = (event) => {
    if (onEdit || manageEmployee) {
      if (onEdit && event.keyCode === 27) {
        handleClose();
      }
      return;
    } else {
      if (event.keyCode === 69) {
        setOnEdit(true);
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener('keydown', keyPressListener);
    return () => {
      document.body.removeEventListener('keydown', keyPressListener);
    };
  }, [keyPressListener]);

  useEffect(() => {
    if (!guid) {
      setOnEdit(true);
      setNewCompany(true);
    } else {
      getCompanyFn().then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          let c = {...result};
          setCompany({...c});
          setOriginalCompany({...c});
          setRefreshCompany(false);
        }
      });

      getCompanyEmployeesFn().then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          setEmployees(result);
        }
      });
    }
  }, [guid, refreshCompany]);

  const getCompanyFn = async () => {
    return await getCompany(guid);
  }

  const getCompanyEmployeesFn = async () => {
    return await getCompanyEmployees(guid);
  }

  const todoFn = () => {
    alert('todo')
  };

  const handleCloseEmployee = () => {
    setEmployee(null);
    setManageEmployee(false);
  };

  const handleSaveEmployee = async (employee) => {
    setSaving(true);
    employee.companyGuid = company.guid;
    let result = await saveEmployee(employee);
    setSaving(false);

    handleCloseEmployee();

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      getCompanyEmployeesFn().then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          setEmployees(result);
        }
      });
    }

  };

  const startManageEmployeeFn = (e) => {
    let _emp = {...e};
    e.newEmployee = true;
    setEmployee(e);
  };

  const handleSaveCompany = async (company) => {

    setSaving(true);

    let result = await saveCompany(company);

    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshCompany(true);
      setOnEdit(false);

      if (result.guid && guid !== result.guid) {
        navigate('./' + result.guid);
      }
    }
  }

  const handleClose = () => {
    setOnEdit(false);
    if (newCompany) {
      navigate('/');
    } else {
      setCompany({...originalCompany});
    }
  };

  const assumeIdentityFn = async (employee) => {
    let result = await assumeIdentity(employee.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setContext({sessionUser: null, refreshIdentity: true});
      navigate('/');
    }
  };

  return (
    <>
      <DashboardLayout>
        <Meta pageTitle={'View Company'}/>
        <ContextSearchNavbar title="View Company"/>
        {!(company?.guid) && <>
          <PageLoading/>
        </>}

        {company?.guid && <>
          <Meta pageTitle={'View Company - ' + company?.companyName}/>
          <Grid container spacing={2} mt={2}>
            <Grid item sm={7}>
              <Card>
                <MDBox display="block" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDButton
                    onClick={(e) => {
                      e.preventDefault();
                      setOnEdit(true);
                    }}
                    className={'pull-right'}
                    color="primary">
                    Edit
                  </MDButton>
                  <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize">
                    <MDTypography fontWeight="medium">
                      {company.companyName}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <MDBox mb={2} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                      Member since: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="light">
                      {formatDate(company.createDate)}
                      <p>{company.address1} {company.address2}
                        <br/>{company.city}, {company.state.stateName} {company.postalCode}</p>
                      {company.primaryPhone &&
                        <p>{displayPhone(company.primaryPhone)}</p>
                      }
                      <MDBox>
                        <Divider/>
                      </MDBox>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item sm={5}>
              <Card>
                <CardContent>
                  <MDTypography variant="h5" color="secondary" fontWeight="regular">Employees
                    <MDButton
                      color="secondary"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        setManageEmployee(true);
                        startManageEmployeeFn({});
                      }}
                      className={'pull-right'}><Icon>add</Icon>&nbsp;Add
                      Employee</MDButton>
                  </MDTypography>

                  <ul className={'list-group mt-2'}>
                    {employees?.map((employee, index) => {
                        return (
                          <li
                            className={'list-group-item xxx-cursor-pointer hover-shadow'}
                            key={index}>
                            <Row>
                              <Col sm={6}>
                                {employee.firstName} {employee.lastName}
                              </Col>
                              <Col sm={6}>
                                <MDButton
                                  color="secondary"
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    assumeIdentityFn(employee)
                                  }}
                                  className={'pull-right'}>
                                  <span className={'fa fa-user-plus'}></span>&nbsp;Assume Identity
                                </MDButton>
                              </Col>
                            </Row>
                          </li>
                        )
                      }
                    )}
                  </ul>

                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container my={1} spacing={2}>
            <Grid item sm={12}>
              <Card>
                <CardContent>
                  <h5>Billing History</h5>
                  <Divider />
                  <p>TODO</p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container my={1} spacing={2}>
            <Grid item sm={12}>
              <Card>
                <CardContent>
                  <h5>Company Notes</h5>
                  <Divider />
                  <p>TODO</p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {
            <>
              <ManageCompanyModal
                company={company}
                show={onEdit}
                saving={saving}
                handleSaveCompany={handleSaveCompany}
                handleClose={handleClose}/>

              {employee && <ManageEmployeeModal
                employee={employee}
                show={manageEmployee}
                saving={saving}
                handleSaveEmployee={handleSaveEmployee}
                handleClose={handleCloseEmployee}/>
              }

            </>
          }
        </>
        }

        <Footer mt={5}/>

      </DashboardLayout>
    </>
  );
}