import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {getStates} from "../common/api/WebApi";
import {displayPhone, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDInput from "../../components/MDInput";
import {NativeSelect} from "@mui/material";
import MDButton from "../../components/MDButton";
import {getReferralTypes} from "../common/api/InventoryApi";

export default function ManageClientModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [customer, setCustomer] = useState(props.customer);

  const [newCustomer, setNewCustomer] = useState(!!!props.customer?.guid);
  const [states, setStates] = useState([]);
  const [referralTypes, setReferralTypes] = useState([]);
  const [formInvalid, setFormInvalid] = useState(false);
  const [statesReady, setStatesReady] = useState(false);

  useEffect(() => {
    getReferralTypes().then(result=> {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _types = result;
        let _choose = {id: -10, name: 'Choose Referral Type...'};
        _types.unshift(_choose);
        setReferralTypes(_types);
        setStatesReady(true);
      }
    })
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _states = result;
        let _choose = {stateId: -10, stateName: 'Choose State...'};
        _states.unshift(_choose);

        setStates(_states);
        setStatesReady(true);
      }
    });
  }, [props.show]);

  useEffect(() => {
    if (statesReady) {
      if (!customer || !customer.state || !customer.state.stateId) {
        setValue('state.stateId', states[0].stateId);
      }
    }
  }, [statesReady]);

  const {
    register, handleSubmit,
    setValue,
    control,
    reset,
    formState: {errors}
  } = useForm({
    values: handleDefaultValues(customer),
  });

  useEffect(() => {
    setCustomer({...props.customer});
  }, [props.customer]);

  useEffect(() => {
    reset();
  }, [newCustomer]);
  const handleClose = () => {
    props.handleClose();
  };

  const handleSaveCustomer = (data) => {
    props.handleSaveCustomer(data);
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form
        autoComplete={'off'}
        onSubmit={handleSubmit(handleSaveCustomer)} id={'customerForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{newCustomer ? 'Create New' : 'Manage'} Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col py-2 py-md-0'} sm={6} xs={12}>
                    <div className="form-group">
                      <MDInput type="text" required
                               {...register('firstName')}
                               autoComplete="off"
                               variant="outlined"
                               className="form-control" id="firstName"
                               label="Enter First name"/>
                    </div>
                  </Col>

                  <Col className={'col py-2 py-md-0'} sm={6} xs={12}>
                    <div className="form-group">
                      <MDInput type="text" required
                               {...register('lastName')}
                               variant="outlined"
                               className="form-control" id="lastName"
                               label="Enter Last name"/>
                    </div>
                  </Col>
                </Row>
                <Row className={'my-4'}>
                  <Col className={'col'} md={6} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="email" required
                        {...register('email')}
                        autoComplete="off"
                        variant="outlined"
                        className="form-control" id="email"
                        label="Enter Email"/>

                      <label htmlFor="emailEnabled" className={'my-4'}>
                        <input type="checkbox"
                               defaultChecked={newCustomer ? true : customer?.emailEnabled}
                               {...register('emailEnabled')}
                               autoComplete="off"
                               id="emailEnabled"/>
                        &nbsp;Email Allowed
                      </label>
                    </div>
                  </Col>

                  <Col className={'col'} md={6} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        required
                        variant="outlined" type="text"
                        {...register('primaryPhone', {
                          required: true,
                          onChange: (e) => {
                            e.target.value = displayPhone(e.target.value);
                          }
                        })}
                        autoComplete="off"
                        className="form-control" id="primaryPhone"
                        label="Enter Phone"/>

                      <label htmlFor="smsEnabled" className={'my-4'}>
                        <input type="checkbox"
                               defaultChecked={newCustomer ? true : customer?.smsEnabled}
                               {...register('smsEnabled')}
                               id="smsEnabled"/>
                        &nbsp;SMS Allowed
                      </label>
                    </div>
                  </Col>

                  <Col className={'col py-2 py-sm-0'} sm={6} xs={12}>
                    <div className="form-group">
                      <MDInput
                        required
                        variant="outlined" type="text"
                        {...register('address1', {
                          required: true
                        })}
                        autoComplete="off"
                        className="form-control" id="address1"
                        label="Enter Address"/>
                    </div>
                  </Col>

                  <Col className={'col py-2 py-sm-0'} sm={6} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        {...register('address2')}
                        autoComplete="off"
                        className="form-control" id="address2"
                        label="Enter Address 2"/>
                    </div>
                  </Col>

                </Row>

                <Row className={'my-4'}>
                  <Col className={'col col py-2 py-sm-0'} sm={4} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        required
                        {...register('city', {
                          required: true
                        })}
                        autoComplete="off"
                        className="form-control" id="city"
                        label="Enter City"/>
                    </div>
                  </Col>

                  <Col className={'col col py-2 py-sm-0'} sm={4} xs={12}>
                    <div className="form-group">
                      {states?.length &&
                        <NativeSelect required
                                      autoComplete="off"
                                      variant="outlined"
                                      id={'state'} name={'state'} className={'form-control'}
                                      {...register('state.stateId',
                                        {required: true, min: 1})}>
                          {
                            states.map((c) =>
                              <option value={c.stateId} key={c.stateId}>
                                {c.stateName}
                              </option>)
                          }
                        </NativeSelect>}
                      {errors['state.stateId'] && errors['state.stateId'].type === "required" && (
                        <span className={'text-danger'}>Please choose a State</span>
                      )}
                      {errors.state?.stateId && errors.state?.stateId.type === "min" && (
                        <span className={'text-danger'}>Please choose a State</span>
                      )}
                    </div>
                  </Col>

                  <Col className={'col col py-2 py-sm-0'} sm={4} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        {...register('zipCode',
                          {required: true, minLength: 5})}
                        autoComplete="off"
                        required
                        className="form-control" id="zipCode"
                        label="Enter Zip"/>
                      {errors.zipCode && errors.zipCode.type === "minLength" && (
                        <span className={'text-danger'}>Please enter valid Postal Code</span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} xs={12}>
                    <div className="form-group">
                      <label>Referral Type</label>
                      {referralTypes?.length > 0 &&
                        <NativeSelect
                                      autoComplete="off"
                                      variant="outlined"
                                      id={'referralType'} name={'referralType'} className={'form-control'}
                                      {...register('referralType.id')}>
                          {
                            referralTypes.map((c) =>
                              <option value={c.id} key={c.id}>
                                {c.name}
                              </option>)
                          }
                        </NativeSelect>}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}