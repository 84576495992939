import {Divider, Grid} from "@mui/material";
import {formatCurrency, formatDateTime} from "../../common/Utils";
import MDTypography from "../../../components/MDTypography";
import React, {useEffect, useState} from "react";
import MDButton from "../../../components/MDButton";
import Checkbox from "@mui/material/Checkbox";
import {removeCartItem} from "../api/WebApi";

export default function CartItems({cart, familyMembers, getCartFullFn, callback}) {

  const [renderIdx, setRenderIdx] = useState(1);

  const deleteInventoryItem = async (item) => {
    removeCartItem(item).then((result) => {
      getCartFullFn();
    });
  };

  const updateCartAttendees = () => {
    let validCartAttendees = true;
    cart?.items?.map((item, index) => {
      if (item?.inventoryItem?.eventType?.requireAttendees &&
        (!item?.cartAttendees || item?.cartAttendees?.length <= 0)) {
        validCartAttendees = false;
      }
    });
    cart.validCartAttendees = validCartAttendees;
    callback(cart);
  }

  useEffect(() => {
    updateCartAttendees();
  }, [cart]);

  const updateFamilyMemberCartItem = (member, item) => {
    let _attendees = [...item.cartAttendees];
    let index = _attendees.map(x => {
      return x.id;
    }).indexOf(member.id);

    if (index > -1) {
      member.checked = false;
      _attendees.splice(index, 1);
    } else {
      member.checked = true;
      _attendees.push(member);
    }

    item.cartAttendees = [..._attendees];
    setRenderIdx(renderIdx + 1);

    updateCartAttendees();
  }

  const isAttendee = (item, member) => {
    let _attendees = [...item.cartAttendees];
    let index = _attendees.map(x => {
      return x.id;
    }).indexOf(member.id);
    return index > -1;
  }

  return (
    <>
      <Grid item xs={12} md={cart?.checkoutDisposition === 'REQUIRE_ATTENDEES' ? 10 : 6} p={2}>
        <h5 className={'text-right'}>
          Your {cart.items.length > 1 ? ' items ' : ' item '} ({cart.items.length})
        </h5>
        <Divider/>
        {cart && cart?.items
          .sort((a, b) => a.cartItemId > b.cartItemId ? 1 : -1)
          .map((item, index) => {
            return (
              <dl key={index}
                  className={'row'}>
                <dd className={'col col-sm col-sm-2'}>
                  <center>
                    {item.inventoryItem?.imageUrl ? <img
                      className="img-thumbnail m-2"
                      alt={item.inventoryItem?.name}
                      src={item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl}
                    /> : <span className="fa fa-2x fa-calendar-check m-2"></span>
                    }
                  </center>
                </dd>
                <dd className={'col col-sm col-sm-10'}>
                  {item.inventoryItem.name}
                  <p className={'my-0'}>
                    {item.inventoryItem?.eventType?.id && <>
                      <small>
                        {formatDateTime(item.inventoryItem.eventDate, 'hh:mm a')} - {formatDateTime(item.inventoryItem.eventEndDate, 'hh:mm a')}
                        <br/>
                      </small>
                    </>
                    }
                  </p>
                  <p className={'my-0'}>
                               <span
                                 className={'text-primary ' + (item.discountItem ? 'text-decoration-line-through' : '')}>
                                {formatCurrency(item.inventoryItem.price)}
                              </span>
                    {item.discountItem && <>
                      <br/>
                      <span className={'text-primary'}>
                                {formatCurrency(item.inventoryItem.price - item.discountItem.discountAmount)}
                        <br/>{item.discountItem.discountReason}
                         </span>
                    </>
                    }

                    {item.inventoryItem?.eventType?.specialRequest && <span className="d-block">

                                <label htmlFor="specialRequestInput">Note:&nbsp;</label>
                                <MDTypography variant="p" fontWeight="light">
                                  This event supports a special request. Your request may be
                                  submitted from the Reservations section of your profile.
                                </MDTypography>
                              </span>}

                    <a className={'p-1 m-0 pull-right cursor-pointer'}
                       onClick={(e) => {
                         deleteInventoryItem(item)
                       }}>
                      <small><span className={'fa fa-times'}></span> Remove</small>
                    </a>
                  </p>
                  {!item?.inventoryItem?.eventType?.requireAttendees && <small>
                    Qty: {item.quantity}
                  </small>
                  }

                  {renderIdx &&
                    (cart?.checkoutDisposition === 'CHECKOUT_VALID' || cart?.checkoutDisposition === 'QUICK_CHECKOUT')
                    && item?.inventoryItem?.eventType?.requireAttendees &&
                    <div>
                      <small>
                        Qty: {item.cartAttendees.length}
                      </small>
                      <h6>Attendees:</h6>
                      <ul className="list-group">{item?.inventoryItem?.eventType?.requireAttendees && <>

                        {item?.cartAttendees?.map((m, index) => {
                          return (<li className="list-group-item" key={index}>
                            <span className="fa fa-check"></span>&nbsp;{m.firstName} {m.lastName}
                          </li>)
                        })}
                      </>}
                      </ul>
                    </div>}
                  {renderIdx && cart?.checkoutDisposition === 'REQUIRE_ATTENDEES'
                    && item?.inventoryItem?.eventType?.requireAttendees &&
                    <div>
                      <small>
                        Qty: {item.cartAttendees.length}
                      </small>

                      {item.cartAttendees.length <= 0 &&
                        <p className="text-info">Update or add attendees for this event below</p>
                      }
                      <ul className="list-group">{item?.inventoryItem?.eventType?.requireAttendees && <>

                        {familyMembers.map((m, index) => {
                          return (<li className="list-group-item" key={index}>

                            <Checkbox
                              checked={isAttendee(item, m)}
                              onChange={() => {
                                updateFamilyMemberCartItem(m, item)
                              }}
                              label={m.firstName + ' ' + m.lastName}
                            />

                            &nbsp;<span style={{
                            color: !isAttendee(item, m) ? '#ccc' : 'inherit'
                          }}>{m.firstName} {m.lastName}</span>
                          </li>)
                        })}
                        <li className="list-group-item">
                          <MDButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() => {
                              let _cart = {...cart};
                              _cart.checkoutDisposition = 'MANAGE_FAMILY_MEMBERS'
                              callback(_cart);
                            }}>
                            <span className="fa fa-plus"></span>&nbsp;Add Family Member
                          </MDButton>
                        </li>
                      </>}
                      </ul>
                    </div>}
                </dd>

                <Divider/>
              </dl>
            )
          })
        }

      </Grid>

      {cart?.checkoutDisposition !== 'REQUIRE_ATTENDEES' &&
        <Grid item xs={12} md={6} p={2}>
          <dl className={'row'}>
            <dt className={'col col-sm col-sm-6'}>
              Items Total:
            </dt>
            <dd className={'col col-sm col-sm-6 text-right'}>
              {formatCurrency(cart.itemsTotal)}
            </dd>
          </dl>
          <dl className={'row'}>
            <dt className={'col col-sm col-sm-6'}>
              Sales Tax:
            </dt>
            <dd className={'col col-sm col-sm-6 text-right'}>
              {formatCurrency(cart.totalTax)}
            </dd>
          </dl>
          <dl className={'row'}>
            <dt className={'col col-sm col-sm-6'}>
              Order Total:
            </dt>
            <dd className={'col col-sm col-sm-6 text-right'}>
              {formatCurrency(cart.cartTotal)}
            </dd>
          </dl>
        </Grid>
      }
    </>
  )
}