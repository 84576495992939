import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {deleteCompanyTaskTemoplate, getTaskLists} from "../common/api/InventoryApi";
import {Card} from "@mui/material";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {useMaterialUIController} from "../../context";
import MDButton from "../../components/MDButton";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import Confirm from "../common/Confirm";
import ManageTaskListModal from "../inventory/ManageTaskListModal";

import 'ckeditor5/ckeditor5.css';


export default function TaskLists(props) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [initialLoad, setInitialLoad] = useState(true);

  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [taskLists, setTaskLists] = useState([]);

  const [inventoryEventTypes, setInventoryEventTypes] = useState([]);
  const [inventoryType, setInventoryType] = useState(null);
  const [taskList, setTaskList] = useState(null);

  const [accountTypes, setAccountTypes] = useState([]);
  const [ageRangeTypes, setAgeRangeTypes] = useState([]);
  const [eventLocations, setEventLocations] = useState([]);

  const [savingCompany, setSavingCompany] = useState(false);
  const [manageInventoryType, setManageInventoryType] = useState(false);
  const [manageTaskList, setManageTaskList] = useState(false);
  const [sortEventTypes, setSortEventTypes] = useState(false);
  const [sortLocations, setSortLocations] = useState(false);
  const [sortAgeRangeTypes, setSortAgeRangeTypes] = useState(false);
  const [sortAccountTypes, setSortAccountTypes] = useState(false);
  const [sortInventoryTypes, setSortInventoryTypes] = useState(false);
  const [isManageCompanyDetailsModal, setIsManageCompanyDetailsModal] = useState(false);

  const [manageInventoryEventType, setManageInventoryEventType] = useState(false);
  const [manageEventLocation, setManageEventLocation] = useState(false);

  const [inventoryEventType, setInventoryEventType] = useState(null);
  const [eventLocation, setEventLocation] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteAgeType, setConfirmDeleteAgeType] = useState(false);
  const [confirmDeleteInventoryType, setConfirmDeleteInventoryType] = useState(false);
  const [confirmDeleteTaskTemplate, setConfirmDeleteTaskTemplate] = useState(false);

  const [confirmDeleteAccountType, setConfirmDeleteAccountType] = useState(false);
  const [confirmDeleteLocation, setConfirmDeleteLocation] = useState(false);

  const [accountType, setAccountType] = useState(null);
  const [ageRangeType, setAgeRangeType] = useState(null);
  const [manageAccountType, setManageAccountType] = useState(false);
  const [manageAgeRangeType, setManageAgeRangeType] = useState(false);

  const [isInventoryEvent, setIsInventoryEvent] = useState(false);

  const [eventsLoading, setEventsLoading] = useState(true);

  const [init, setInit] = useState(false);

  const [company, setCompany] = useState();
  const [states, setStates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templatesReady, setTemplatesReady] = useState();
  const [saving, setSaving] = useState(false);

  const [reloadInventory, setReloadInventory] = useState(false);

  useEffect(() => {
    if (!init) {
      return;
    }
    setInit(false);
    loadTaskLists();
  }, [init]);

  useEffect(() => {
    setInit(true);
  }, []);

  const loadTaskLists = () => {
    getTaskLists().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTaskLists(result);
      }
    });
  }

  const handleSaveTask = async () => {
    closeTaskListFn();
    loadTaskLists();
  }

  const manageTaskListFn = (taskList, isSort) => {
    let e = {...taskList};
    e.isSort = isSort;
    setTaskList({...e});
    setManageTaskList(true);
  }

  const addTaskListFn = () => {
    setTaskList(null);
    setManageTaskList(true);
  }

  const closeTaskListFn = () => {
    setTaskList(null);
    setManageTaskList(false);
  }

  const handleArchiveTaskTemplate = async (inventory) => {
    let result = await deleteCompanyTaskTemoplate(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDeleteTaskTemplate(false);
      loadTaskLists();
    }
  }

  const handleCloseTaskTemplate = () => {
    setConfirmDeleteTaskTemplate(false);
  };

  const confirmDeleteTaskListFn = (taskTemplate) => {
    let confirmation = {
      title: 'Archive ' + taskTemplate.name + '?',
      message: 'Confirm archive ' + taskTemplate.name + '?',
      entity: taskTemplate
    }
    setConfirmation(confirmation);
    setConfirmDeleteTaskTemplate(true);
  }

  const TaskListActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <span variant="contained" {...bindTrigger(popupState)}>
            <MDButton
              variant="outlined"
              color="secondary"
              size="small"
              circular
              iconOnly
            >
              <Icon>more_horiz</Icon>
            </MDButton>
          </span>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageTaskListFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageTaskListFn(item, true)
              }} icon={<Icon>sort</Icon>} title="Sort"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteTaskListFn(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const displayRepeats = (item) => {
    if (item.repeatExpression) {
      let arr = item.repeatExpression.split(',');
      let days = [];
      arr.forEach((a, i) => {
        days.push(a.replace('MON', 'Monday') //
          .replace('TUE', 'Tuesday') //
          .replace('WED', 'Wednesday') //
          .replace('THU', 'Thursday') //
          .replace('FRI', 'Friday') //
          .replace('SAT', 'Saturday') //
          .replace('SUN', 'Sunday'));
      });
      if (days.length === 7) {
        return 'Repeats Daily';
      }
      return 'Repeats ' + days.join(', ');
    }
    return '';
  }

  return (
    <DashboardLayout>
      <Meta pageTitle={'Task Lists'}/>
      <ContextSearchNavbar title="Task Lists"/>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="regular">
                    &nbsp;
                    <MDButton
                      color="secondary"
                      size="small"
                      className={'pull-right'}
                      onClick={(e) => {
                        addTaskListFn();
                      }}>
                      <Icon>check</Icon>&nbsp;Add Task List
                    </MDButton>
                  </MDTypography>
                  <p className={'py-2 fw-light'}>
                    Use task lists to define daily tasks for your company and employees.
                    For example, "clean the play space", "check inventory", "open the facility", etc.
                    <br/> Tasks will be created each day from the defined templates.
                  </p>

                  <ul className={'list-group'}>
                    {taskLists.map((type, index) => {
                      return (
                        <li
                          className={'list-group-item'}
                          key={index}>
                          <>
                                <span className="d-block pull-right">
                                    <TaskListActions item={type}/>
                                  </span>
                            <h5>
                              <a
                                className={'link cursor-pointer'}
                                onClick={(e) => {
                                  manageTaskListFn(type);
                                }}>{type.name}</a>
                            </h5>
                          </>
                          {type.desc && <>
                            <small className={'text-small mt-n2'}>{type.desc}</small>
                          </>}

                          {type.items &&
                            <ul
                              className={'list-group'}> {type.items.sort((a, b) => a.sortOrder - b.sortOrder).map((task, index) => {
                              return (
                                <li key={index} className={'list-group-item'}>
                                  {task.name}
                                  {task.description && <small
                                    className={'text-small my-n2'}><br/>{task.description}
                                    <br/>
                                    {displayRepeats(task)}
                                  </small>}
                                </li>
                              );
                            })
                            }</ul>
                          }
                        </li>
                      );
                    })}
                  </ul>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      {manageTaskList && <ManageTaskListModal
        taskList={taskList}
        show={manageTaskList}
        saving={savingCompany}
        handleSave={handleSaveTask}
        handleClose={closeTaskListFn}/>}

      {confirmDeleteTaskTemplate && <Confirm confirmation={confirmation}
                                             show={confirmDeleteTaskTemplate}
                                             saving={saving}
                                             handleConfirm={handleArchiveTaskTemplate}
                                             handleClose={handleCloseTaskTemplate}/>}

      <Footer mt={5}/>

    </DashboardLayout>
  );
}