import Icon from "@mui/material/Icon";

// Images
import ListCustomers from "./app/client/ListCustomers";
import {CONSTANTS} from "./app/common/constants";
import UserBadge from "./app/client/UserBadge";
import UserDisplayName from "./app/client/UserDisplayName";
import ListCompanies from "./app/companies/ListCompanies";
import NewCompany from "./app/companies/NewCompany";
import DropIdentity from "./app/client/DropIdentity";
import {dropIdentity, logout} from "./app/common/api/WebApi";
import Inventory from "./app/inventory/Inventory";
import Events from "./app/inventory/Events";
import Client from "./app/client/Client";
import Membership from "./app/inventory/Membership";
import CompanyCustomization from "./app/company/CompanyCustomization";
import Employees from "./app/company/Employees";
import SalesDashboard from "./app/SalesDashboard";
import TaskHistory from "./app/company/TaskHistory";
import DashboardSwitch from "./app/DashboardSwitch";
import ListCommunicationTemplates from "./app/company/ListCommunicationTemplates";
import ListCustomerWaivers from "./app/company/ListCustomerWaivers";
import TaskLists from "./app/company/TaskLists";
import Timesheets from "./app/company/Timesheets";
import LogoBadge from "./app/client/LogoBadge";

const routes = [
  {
    type: "collapse",
    key: "psb-home",
    route: "/",
    name: "Dashboard",
    icon: <LogoBadge admin={true}/>,
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    noCollapse: true,
    component: <DashboardSwitch/>
  },
  {type: "divider", key: "divider-logo", userTypes: [CONSTANTS.UTYPE_CLIENT, CONSTANTS.UTYPE_ADMIN]},

  {type: "title", title: "Admin", key: "admin-title", userTypes: [CONSTANTS.UTYPE_ADMIN]},
  {
    type: "collapse",
    name: <UserDisplayName admin={true}/>,
    key: "admin-item",
    userTypes: [CONSTANTS.UTYPE_ADMIN],
    icon: <UserBadge admin={true}/>,
    collapse: [
      {
        name: <DropIdentity/>,
        title: "Drop Identity",
        key: "drop-identity",
        type: "collapse",
        noCollapse: true,
        href: '#',
        clickHandler: (e) => {
          e.preventDefault();
          e.stopPropagation();
          dropIdentity().then(() => {
            window.location = '/';
          });
        },
        icon: <Icon fontSize="medium">face-5</Icon>,
        userTypes: [CONSTANTS.UTYPE_ADMIN],
        route: "/admin/drop/identity",
      },
      {
        name: "Logout",
        title: "Logout",
        key: "admin-logout",
        route: "/companies/new",
        component: <NewCompany/>,
        href: "#",
        clickHandler: (e) => {
          e.preventDefault();
          e.stopPropagation();
          logout().then(() => {
            window.location = '/';
          });
        },
        icon: <Icon fontSize="medium">map-big</Icon>,
        userTypes: [CONSTANTS.UTYPE_ADMIN]
      },
      {
        name: "New Company",
        title: "New Company",
        key: "companies-new",
        route: "/companies/new",
        component: <NewCompany/>,
        icon: <Icon fontSize="medium">map-big</Icon>,
        userTypes: [CONSTANTS.UTYPE_ADMIN]
      },
    ]
  },
  {type: "divider", key: "divider-admin-1", userTypes: [CONSTANTS.UTYPE_ADMIN]},
  {
    type: "collapse",
    name: "List Companies",
    title: "Companies",
    key: "list-companies",
    noCollapse: true,
    route: "/companies/list",
    component: <ListCompanies/>,
    icon: <Icon fontSize="medium">map-big</Icon>,
    userTypes: [CONSTANTS.UTYPE_ADMIN]
  },
  {type: "divider", key: "divider-admin", userTypes: [CONSTANTS.UTYPE_ADMIN]},
  {
    type: "collapse",
    name: <UserDisplayName/>,
    key: "employee",
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    icon: <UserBadge/>,
    collapse: [
      {
        name: "Logout",
        key: "logout",
        href: "#",
        clickHandler: (e) => {
          e.preventDefault();
          e.stopPropagation();
          logout().then(() => {
            window.location = '/';
          });
        },
      },
    ],
  },

  {
    name: "Dashboard",
    type: "collapse",
    key: "",
    route: "/",
    userTypes: [CONSTANTS.UTYPE_CUSTOMER],
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    component: <DashboardSwitch/>,
  },
  {type: "divider", key: "divider-8", userTypes: [CONSTANTS.UTYPE_CUSTOMER]},
  {type: "title", title: "Customers", key: "title-customers", userTypes: [CONSTANTS.UTYPE_CUSTOMER]},
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="medium">coffee</Icon>,
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    collapse: [
      {
        type: "collapse",
        name: "List Customers",
        key: "list-customers",
        route: "/customers/list-customers",
        icon: <Icon fontSize="medium"></Icon>,
        userTypes: [CONSTANTS.UTYPE_CLIENT],
        component: <ListCustomers/>,
      },
      {
        type: "collapse",
        name: "Create Customer",
        key: "customers-create",
        route: "/customers/create",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        noCollapse: true,
        userTypes: [CONSTANTS.UTYPE_CLIENT],
        component: <Client createNew/>,
      }]
  },
  {type: "divider", key: "divider-9", userTypes: [CONSTANTS.UTYPE_CUSTOMER]},
  {type: "title", title: "Daily Operations", key: "operations-title", userTypes: [CONSTANTS.UTYPE_CUSTOMER]},
  {
    name: "Events",
    key: "sales-events",
    type: "collapse",
    route: "/sales/events",
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    noCollapse: true,
    icon: <Icon fontSize="medium">today</Icon>,
    component: <Events specialEvents={false}/>
  },

  {
    name: "Parties",
    key: "parties-events",
    type: "collapse",
    route: "/parties/events",
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    noCollapse: true,
    icon: <Icon fontSize="medium">cake</Icon>,
    component: <Events specialEvents={true}/>
  },


  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Icon fontSize="medium">playlist_add_check</Icon>,
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    collapse: [
      {
        type: "collapse",
        name: "Tasks History",
        key: "history",
        route: "/tasks/history",
        noCollapse: true,
        icon: <Icon fontSize="medium"></Icon>,
        userTypes: [CONSTANTS.UTYPE_CLIENT],
        component: <TaskHistory/>,
      },
      {
        type: "collapse",
        name: "Manage Tasks",
        key: "manage-tasks",
        route: "/tasks/manage-tasks",
        noCollapse: true,
        icon: <Icon fontSize="medium">plus</Icon>,
        userTypes: [CONSTANTS.UTYPE_CLIENT],
        component: <TaskLists />,
      }]
  },
  {type: "divider", key: "divider-20", userTypes: [CONSTANTS.UTYPE_CLIENT]},
  {type: "title", title: "Products", key: "title-sales", userTypes: [CONSTANTS.UTYPE_CUSTOMER]},
  {
    name: "Sales Dashboard",
    type: "collapse",
    key: "sales-dashboard",
    route: "/sales/dashboard",
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    noCollapse: true,
    icon: <Icon fontSize="medium">grid_view</Icon>,
    component: <SalesDashboard/>
  },{
    name: "Inventory",
    key: "sales-inventory",
    type: "collapse",
    route: "/sales/inventory",
    icon: <Icon fontSize="medium">shopping_cart</Icon>,
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    noCollapse: true,
    component: <Inventory/>
  },
  {
    name: "Memberships",
    key: "sales-memberships",
    type: "collapse",
    route: "/sales/memberships",
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    noCollapse: true,
    icon: <Icon fontSize="medium">book</Icon>,
    component: <Membership/>
  },
  {type: "divider", key: "divider-10", userTypes: [CONSTANTS.UTYPE_CLIENT]},
  {
    type: "collapse",
    name: "Company",
    key: "company",
    icon: <Icon fontSize="medium">store</Icon>,
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    collapse: [
      {
        name: "Customization",
        key: "customization",
        route: "/company/customization",
        component: <CompanyCustomization />,
      },
      {
        name: "Forms & Waivers",
        key: "waivers",
        route: "/communication/waivers",
        component: <ListCustomerWaivers />,
      },
      {
        name: "Email Templates",
        key: "templates",
        route: "/communication/templates",
        component: <ListCommunicationTemplates/>,
      },
    ],
  },

  {
    type: "collapse",
    name: "Employees",
    key: "employees-top",
    icon: <Icon fontSize="medium">supervised_user_circle_icon</Icon>,
    userTypes: [CONSTANTS.UTYPE_CLIENT],
    collapse: [
      {
        name: "Employees",
        key: "employees",
        route: "/company/employees",
        component: <Employees/>,
      },
      {
        name: "Time Sheets",
        key: "time-sheets",
        route: "/company/time-sheets",
        component: <Timesheets/>,
      },
    ],
  },

  {type: "divider", key: "divider-999", userTypes: [CONSTANTS.UTYPE_CLIENT]},

];

export default routes;
