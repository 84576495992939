import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../lib/AppContext";
import {
  checkout,
  getCustomerCartFamilyMembers,
  getCustomerCartFull,
  saveCustomerFamily,
  updateAttendees
} from "../api/CartApi";

import {Col, Modal} from "react-bootstrap";
import MDButton from "../../../components/MDButton";
import MDBadge from "../../../components/MDBadge";
import IconButton from "@mui/material/IconButton";
import CartItems from "./CartItems";
import LoginForm from "../../common/LoginForm";
import CreateAccount from "../../common/CreateAccount";
import Loading from "../../common/Loading";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {capitalize} from "../../common/Utils";
import CheckoutPage from "../../CheckoutPage";
import {useNavigate} from "react-router-dom";
import CartFamilyMembers from "./CartFamilyMembers";
import {Grid} from "@mui/material";
import {CONSTANTS} from "../constants";
import CartSuccess from "./CartSuccess";

export default function CartFinal({client, callback, cartActive}) {

  const [context, setContext] = useContext(AppContext);
  const [cartContext, setCartContext] = useContext(AppContext);

  const [cartReady, setCartReady] = useState(false);

  const [cartItems, setCartItems] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [cart, setCart] = useState();

  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [card, setCard] = useState(null);
  const [cardInvalid, setCardInvalid] = useState(true);
  const [showLogin, setShowLogin] = useState(true);
  const [action, setAction] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setAddPaymentMethod(false);
  }, [cartActive]);

  useEffect(() => {
    if (cart?.checkoutDisposition === 'VIEW_CHECKOUT' && cart?.billings?.length === 0) {
      setAddPaymentMethod(true);
    }
    if (cart?.checkoutDisposition === 'REQUIRE_ATTENDEES') {
      getCartFamilyMembersFn();
    }
  }, [cart?.checkoutDisposition]);

  const getCartFamilyMembersFn = async () => {
    getCustomerCartFamilyMembers(client).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _members = [];
        for (let j in result) {
          if (result[j].familyMemberType.id === 1) {
            _members.push(result[j]);
          }
        }
        setFamilyMembers(_members);
      }
    });
  }

  const getCartFullFn = async (referrer) => {
    setCartReady(false);
    callback({
      action: 'reload'
    })
    setCartContext({...cartContext, reloadCart: true})
    getCustomerCartFull(client.guid, referrer).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setCart(result);
        setCartReady(true);
      }
    });
  }

  useEffect(() => {
    setCartItems(cartContext?.cartItems);
  }, [cartContext?.cartItems])


  useEffect(() => {
    if (cartActive) {
      getCartFullFn();
    }
  }, [cartActive]);

  const handleClose = (action) => {
    const _c = {
      action: action || 'close'
    }
    callback(_c);
  };

  const updateCartAttendees = async () => {
    updateAttendees(cart).then(result => {
      setCartContext({...cartContext, reloadCart: true});
      getCartFullFn();
    });
  }

  const [checkingOut, setCheckingOut] = useState(false);

  const checkoutFn = async () => {
    setCheckingOut(true);
    const resp = await checkout(cart, client);
    if (resp) {
      setCheckingOut(false);

      let _cart = {...cart};
      _cart.checkoutDisposition = 'CHECKOUT_SUCCESS';
      _cart.paymentId = resp.paymentId
      setCart(_cart);

      setAction('success')

    }
  }

  const continueToCheckout = () => {
    getCartFullFn(cart?.checkoutDisposition === 'VIEW_CART' ? 'VIEW_CHECKOUT' : cart?.checkoutDisposition);
  }

  const viewCart = () => {
    setCartContext({...cartContext, viewCart: true});
  }

  const handleLogin = (obj) => {
    continueToCheckout();
  }

  const updatePaymentMethod = (item) => {
    setAddPaymentMethod(false);
    let _cart = {...cart};
    _cart.paymentMethod = item;
    setCart(_cart);
  };

  const toggleCashPaymentMethod = () => {
    setCard(null);
    setAddPaymentMethod(false);

    let _item = {
      cash: true,
      chargeTypeId: CONSTANTS.CHARGE_TYPE.CASH
    }
    updatePaymentMethod(_item);
  };

  const addPaymentMethodFn = () => {
    setCard(null);
    setAddPaymentMethod(true);
  };

  const handleCardChange = (card) => {
    if (!card) {
      return;
    }
    if (!cart) {
      return;
    }
    setCard(card);
    setCardInvalid(!card?.card?.isValid);

    let _paymentMethod = {...card};
    let _cart = {...cart};
    _cart.paymentMethod = _paymentMethod;
    setCart(_cart);
  };

  const [cartRenderIdx, setCartRenderIdx] = useState(1);
  const [familyMemberRenderIdx, setFamilyMemberRenderIdx] = useState(1);

  const cartCallback = (data) => {
    setCartRenderIdx(cartRenderIdx + 1);
    setCart(data);
    callback({
      action: 'reload',
    })
  }

  const [familyMemberForm, setFamilyMemberForm] = useState();

  const saveChildMember = () => {
    saveCustomerFamily(familyMemberForm).then(r => {
      getCartFullFn();
    });
  }

  const familyMemberCallback = (form) => {
    let _form = {...form};
    _form.parentGuid = client.guid;
    setFamilyMemberForm(_form);
    setFamilyMemberRenderIdx(familyMemberRenderIdx + 1);
  }

  const dispositionHelp = (disposition) => {
    console.log('Disposition ' + disposition);
    let helpText = '';
    if (disposition === 'MANAGE_FAMILY_MEMBERS') {
      helpText = ' - Add Child';
    }
    if (disposition === 'REQUIRE_ATTENDEES') {
      helpText = ' - Attendees Required';
    }
    if (disposition === 'VIEW_CHECKOUT') {
      helpText = ' - Checkout';
    }
    if (disposition === 'CHALLENGE_ACCOUNT') {
      helpText = ' - Please Sign In';
    }
    if (disposition === 'CHECKOUT_VALID') {
      helpText = ' - Complete Purchase';
    }
    if (disposition === 'QUICK_CHECKOUT') {
      helpText = ' - Complete Purchase';
    }
    return helpText;
  }

  const handleCartSuccess = (e) => {
    let _cart = {...cart};
    _cart.checkoutDisposition = 'CHECKOUT_SUCCESS';
    _cart.paymentId = e.paymentIntent.id
    setCart(_cart);

    setAction('success')
  }


  const handleSuccessDisposition = (disposition) => {
    let _cart = {...cart};
    _cart.checkoutDisposition = disposition;
    setCart(_cart);
  }

  return (
    <>
      <IconButton
        size="small"
        disableRipple
        color="primary"
        aria-controls="notification-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={() => {
          viewCart()
        }}
      >
        {cartItems?.length > 0 && <MDBadge badgeContent={cartItems?.length} color="error" size="xs" circular>
          <span className="fa fa-cart-shopping"/>
        </MDBadge>}
      </IconButton>

      <Modal size={'xl'} onHide={() => {
        handleClose(action)
      }}
             show={cartActive}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Cart {dispositionHelp(cart?.checkoutDisposition)}</Modal.Title>
        </Modal.Header>
        {!cartReady &&
          <Loading/>
        }
        {cartReady && <>
          <Modal.Body className={'modal-overflow'}>
            {(cart?.checkoutDisposition === 'CHECKOUT_SUCCESS' || cart?.checkoutDisposition === 'CHECKOUT_SUCCESS_CONFIRMATION') &&
              cart?.paymentId &&
              <CartSuccess paymentId={cart.paymentId} clientGuid={client.guid}
                           handleSuccess={handleSuccessDisposition}/>}

            {cart?.checkoutDisposition === 'ADD_FAMILY_MEMBERS' && <center style={{
              width: '80%',
              margin: '0 auto'
            }}>

              <MDTypography variant="h5" fontWeight="light">An event you are registering for requires
                attendees</MDTypography>
              <MDTypography variant="p" className={'mt-3'} fontWeight="regular">
                Please continue to add family members.
              </MDTypography>

              <div>
                <MDButton
                  className={'mt-3'}
                  color="primary"
                  onClick={() => {
                    let _cart = {...cart};
                    _cart.checkoutDisposition = 'MANAGE_FAMILY_MEMBERS'
                    setCart(_cart);
                  }}>Continue</MDButton>
              </div>

            </center>}

            {cart?.checkoutDisposition === 'MANAGE_FAMILY_MEMBERS' && <>
              <CartFamilyMembers familyMembers={familyMembers}
                                 callback={familyMemberCallback}/>
            </>}
            <Grid container p={2}>
              {(cart?.checkoutDisposition === 'VIEW_CART'
                || cart?.checkoutDisposition === 'REQUIRE_ATTENDEES'
                || cart?.checkoutDisposition === 'VIEW_CHECKOUT'
                || cart?.checkoutDisposition === 'CHECKOUT_VALID'
                || cart?.checkoutDisposition === 'QUICK_CHECKOUT') && <>
                <CartItems
                  familyMembers={familyMembers}
                  cart={cart}
                  getCartFullFn={getCartFullFn}
                  callback={cartCallback}/>
              </>}
              {cart?.checkoutDisposition === 'CART_EMPTY' && <>
                <Col xs={12}>
                  <h5>Your cart is empty.&nbsp;
                    <span
                      className="link cursor-pointer text-info text-decoration-underline"
                      onClick={() => {
                        handleClose();
                      }}>
                Continue shopping?</span>
                  </h5>
                </Col>
              </>}

              {cart?.checkoutDisposition === 'CHALLENGE_ACCOUNT' && <>
                {!showLogin && <Grid item xs={12}>
                  <CreateAccount
                    handleCancel={() => {
                      setShowLogin(true);
                    }}
                    handleSave={() => {
                      continueToCheckout();
                    }}
                  />
                </Grid>
                }

                {showLogin && <>
                  <LoginForm
                    showCreateAccount={() => {
                      setShowLogin(false);
                    }}
                    handleLogin={handleLogin}/>
                </>
                }
              </>
              }
              {(cart?.checkoutDisposition === 'VIEW_CHECKOUT' ||
                cart?.checkoutDisposition === 'CHECKOUT_VALID') && <Grid item xs={12} sm={12}>

                <MDBox mt={2}>
                  <MDTypography variant="h4" color="secondary" fontWeight="light">
                    Choose Payment Method
                  </MDTypography>

                  {cart && cart?.billings?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                    .map((item, index) => {
                      return (
                        <dl className={'row'} key={index}>
                          <dt className={'col col-sm col-sm-6'}>
                            <label>
                              <input className={'mx-2'} type={'radio'}
                                     defaultChecked={item.primaryCard}
                                     name={'paymentMethod'}
                                     onChange={(e) => {
                                       updatePaymentMethod(item);
                                     }}
                              />
                              {capitalize(item.cardType)} ending in **** {item.cardNumber}
                            </label>
                          </dt>
                          <dd className={'col col-sm col-sm-6 text-right'}>
                            ({item.ccExpy})
                          </dd>
                        </dl>
                      )
                    })}

                  <dl className={'row'}>
                    <dt className={'col col-sm col-12'}>
                      <label>
                        <input className={'mx-2'} type={'radio'}
                               name={'paymentMethod'}
                               defaultChecked={cart?.billings?.length === 0}
                               onChange={(e) => {
                                 addPaymentMethodFn(true);
                               }}
                        />New Payment Method</label>
                    </dt>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-12'}>
                      <label>
                        <input className={'mx-2'} type={'radio'}
                               name={'paymentMethod'}
                               onChange={(e) => {
                                 toggleCashPaymentMethod();
                               }}
                        />Cash Payment</label>
                    </dt>
                  </dl>
                  {addPaymentMethod &&
                    <CheckoutPage
                      handleSuccess={(e) => {
                        handleCartSuccess(e)
                      }}
                      handleError={(e) => {
                        console.dir(e);
                        let _cart = {...cart};
                        _cart.checkoutDisposition = 'CART_API_ERROR';
                        _cart.error = e.message;
                        setCart(_cart);
                      }}
                      client={client}
                    />
                  }
                </MDBox>
              </Grid>
              }
            </Grid>
          </Modal.Body>
          {cart?.checkoutDisposition === 'CHECKOUT_SUCCESS_CONFIRMATION' && <>
            <Modal.Footer>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  handleClose('success')
                }}>
                Close
              </MDButton>
            </Modal.Footer>
          </>}

          {!addPaymentMethod && <>
            {cart?.checkoutDisposition === 'VIEW_CART' && <>
              <Modal.Footer>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    continueToCheckout()
                  }}>
                  Continue to Checkout
                </MDButton>
              </Modal.Footer>
            </>
            }

            {cart?.checkoutDisposition !== 'CHECKOUT_SUCCESS_CONFIRMATION' &&
              (cart?.checkoutDisposition === 'CHECKOUT_VALID' ||
                cart?.checkoutDisposition === 'QUICK_CHECKOUT'
                || cart?.paymentMethod?.cash) && <>
                <Modal.Footer>
                  <MDButton variant="gradient"
                            color="info"
                            disabled={checkingOut}
                            onClick={() => {
                              checkoutFn();
                            }}>Complete Purchase
                  </MDButton>
                </Modal.Footer>
              </>
            }
            {cart?.checkoutDisposition === 'MANAGE_FAMILY_MEMBERS' && <>
              <Modal.Footer>
                {familyMemberRenderIdx &&
                  <>
                    {familyMembers?.length > 0 &&
                      <span style={{
                        float: 'left'
                      }}>
                      <MDButton color="primary"
                                onClick={() => {
                                  getCartFullFn();
                                }}>Back to Cart
                    </MDButton>&nbsp;
                    </span>
                    }
                    <MDButton color="info"
                              disabled={!familyMemberForm?.valid}
                              onClick={saveChildMember}>Save Child
                    </MDButton>

                  </>
                }
              </Modal.Footer>
            </>}
            {cart?.checkoutDisposition === 'REQUIRE_ATTENDEES' && <>
              <Modal.Footer>
                {cartRenderIdx && <MDButton variant="gradient"
                                            color="info"
                                            disabled={!cart?.validCartAttendees}
                                            onClick={updateCartAttendees}>Update Cart
                </MDButton>}
              </Modal.Footer>
            </>
            }
          </>
          }
        </>
        }
      </Modal>
    </>
  )
}