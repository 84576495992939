import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import {
  deleteInventory,
  getAccountTypes,
  getInventoryEventTypes,
  getInventoryMemberships,
  getInventoryTypes,
  saveAccountType,
  saveInventory,
  saveInventoryMembership
} from "../common/api/InventoryApi";
import {Card, CardContent} from "@mui/material";
import Meta from "../common/Meta";
import Confirm from "../common/Confirm";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";

import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import ManageAccountTypeModal from "./ManageAccountTypeModal";
import ManageInventoryMembershipModal from "./ManageInventoryMembershipModal";
import NotificationItem from "../Items/NotificationItem";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MDButton from "../../components/MDButton";

export default function Membership(props) {

  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [renderIndex, setRenderIndex] = useState(1);

  const myRefs = useRef([]);

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };

  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [inventoryEventTypes, setInventoryEventTypes] = useState([]);
  const [inventoryType, setInventoryType] = useState(null);
  const [accountType, setAccountType] = useState(null);

  const [renderDate, setRenderDate] = useState(new Date().getTime());

  const [saving, setSaving] = useState(false);
  const [manageAccountType, setManageAccountType] = useState(false);

  const [manageInventory, setManageInventory] = useState(false);
  const [isInventoryEvent, setIsInventoryEvent] = useState(false);
  const [inventory, setInventory] = useState(null);
  const [inventories, setInventories] = useState(null);
  const [sortInventory, setSortInventory] = useState({field: 'id', direction: 'asc'});

  const [confirmation, setConfirmation] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const confirmDeleteInventory = (inventory) => {
    let confirmation = {
      title: 'Delete ' + inventory.name + '?',
      message: 'Delete ' + inventory.name + ' Permanently?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDelete(true);
  }

  const handleDeleteInventory = async (inventory) => {
    let result = await deleteInventory(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDelete(false);
      loadMembershipInventoryFn();
    }
  }

  const handleCloseDeleteInventory = async () => {
    setConfirmDelete(false);
  };

  const loadInventoryTypes = () => {
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryTypes(result);
      }
    });
  }

  const loadAccountTypes = () => {
    getAccountTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAccountTypes(result);
      }
    });
  }

  const loadInventoryEventTypes = () => {
    getInventoryEventTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryEventTypes(result);
      }
    });
  }

  const loadMembershipInventoryFn = () => {
    getInventoryMemberships().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setRenderDate(new Date().getTime());
        setInventories(result);
      }
    });
  }

  const saveAccountTypeFn = async (accountType) => {
    let result = await saveAccountType(accountType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeAccountTypeFn();
      loadAccountTypes();
    }
  }

  const saveInventoryFn = async (inventory) => {
    let result = await saveInventory(inventory);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryFn();
      loadInventoryTypes();
    }
  }

  const handleSaveInventory = async (inventory) => {
    const inv = {...inventory};
    inv.inventoryEvent = isInventoryEvent;
    let result = await saveInventoryMembership(inv);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryFn();
      loadMembershipInventoryFn();
    }
  }

  const handleCloseInventory = () => {
    setManageInventory(false);
  }

  useEffect(() => {
    loadMembershipInventoryFn();
    loadAccountTypes();
  }, []);

  const addAccountTypeFn = () => {
    setAccountType(null);
    setManageAccountType(true);
  }

  const manageAccountTypeFn = (accountType) => {
    let t = null;
    if (accountType) {
      t = {...accountType};
    }
    t.recurrencePattern = t.recurrencePattern + '';
    setAccountType(t);
    setManageAccountType(true);
  }

  const addInventoryFn = () => {
    setInventory(null);
    setIsInventoryEvent(false);
    setManageInventory(true);
  }

  const addInventoryEventFn = () => {
    setInventory(null);
    setIsInventoryEvent(true);
    setManageInventory(true);
  }

  const manageInventoryFn = (inventory) => {
    let t = null;
    if (inventory) {
      t = {...inventory};
    }
    setIsInventoryEvent(false);
    setInventory(t);
    setManageInventory(true);
  }

  const manageInventoryEventFn = (inventory) => {
    let t = null;
    if (inventory) {
      t = {...inventory};
    }
    setIsInventoryEvent(true);
    setInventory(t);
    setManageInventory(true);
  }

  const closeAccountTypeFn = () => {
    setAccountType(null);
    setManageAccountType(false);
  }
  const closeInventoryFn = () => {
    setInventory(null);
    setManageInventory(false);
  }

  const sortInventoryFn = (a, b) => {
    const sortDir = sortInventory.direction === 'asc' ? 1 : -1;
    if (sortInventory.field === 'name') {
      return a.name.toUpperCase() > b.name.toUpperCase() ? sortDir : sortDir * -1;
    }
    if (sortInventory.field === 'id') {
      return a.id > b.id ? sortDir : sortDir * -1;
    }
    return a.id > b.id ? sortDir : sortDir * -1;
  }

  return (
    <DashboardLayout>

      <Meta pageTitle={'Inventory - Memberships'}/>

      <ContextSearchNavbar title="Inventory Memberships"/>
      <MDBox py={3}>
        <MDBox mb={3}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Card mt={2}>
                <MDBox p={2}>
                  <MDTypography mb={2} lineHeight={1} variant="h5" fontWeight="regular">
                    Account Types
                  </MDTypography>
                  <ul className={'list-group'}>
                    {accountTypes.map((type, index) => {
                      return (
                        <li
                          className={'list-group-item '}
                          key={index}>
                          {type.id <= -1 && type.name}
                          {type.id > -1 && type.name &&
                            <a
                              className={'link cursor-pointer'}
                              onClick={(e) => {
                                manageAccountTypeFn(type);
                              }}>{type.name}</a>

                          }
                          {type.desc && <>
                            <small className={'text-small my-n2'}><br/>{type.desc}</small>
                          </>}
                        </li>
                      );
                    })}
                    <li
                      className={'list-group-item background-primary cursor-pointer'}
                      onClick={addAccountTypeFn}>
                      <span className={'fa fa-plus'}></span> Add Account Type
                    </li>
                  </ul>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Card>

                <CardContent>

                  <div className={'row'}>
                    <div className={'col col-12'}>
                      <MDTypography variant="h5" fontWeight="regular">
                        Your Memberships
                        <span className={'meta-container'}>
                            <button className={'btn btn-sm btn-secondary p-1 my-1 mx-2 pull-right'}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addInventoryFn();
                                    }}>
                              <span className={'fa fa-plus'}></span> Add New
                            </button>
                            <span className={'pull-right d-block p-1'}>
                              <span className={'d-inline form-control-sm p-1'}>Sort:</span>
                              <select className={'d-inline form-control-sm p-1'} onChange={
                                (e) => {
                                  setSortInventory({...sortInventory, field: e.target.value});
                                }
                              }>
                              <option value={'name'}>Name</option>
                              <option value={'id'}>Id</option>
                            </select>
                            <select className={'d-inline form-control-sm p-1'} onChange={
                              (e) => {
                                setSortInventory({...sortInventory, direction: e.target.value});
                              }
                            }>
                              <option value={'asc'}>Asc</option>
                              <option value={'desc'}>Desc</option>
                            </select>
                           </span>
                          </span>
                      </MDTypography>
                    </div>
                  </div>
                  <Divider/>
                  <ul className={'list-group'}>
                    {inventories && inventories
                      .sort((a, b) => sortInventoryFn(a, b))
                      .map((item, index) => {
                        return (
                          <div style={{
                            'position': 'relative'
                          }} className={'list-group-item cursor-pointer'} key={index}>
                            <div className={'pull-right px-2'}
                                 onClick={(e) => {
                                   refHandler('item' + index)
                                 }}
                                 ref={el => (myRefs.current['item' + index] = el)}>
                              <MDButton
                                variant="outlined"
                                color="secondary"
                                size="small"
                                circular
                                iconOnly
                              >
                                <Icon>more_horiz</Icon>
                              </MDButton>
                              {renderIndex && myRefs.current['item' + index]?.visible &&
                                <Menu open={true} anchorReference={null} anchorEl={myRefs.current['item' + index]}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}>
                                  <NotificationItem
                                    onClick={(e) => {
                                      manageInventoryFn(item)
                                    }} icon={<Icon>edit</Icon>} title="Edit"/>
                                  <NotificationItem onClick={(e) => {
                                    confirmDeleteInventory(item)
                                  }} icon={<Icon>delete</Icon>} title="Delete"/>
                                </Menu>
                              }
                            </div>
                            <a
                              className={'link'}
                              onClick={(e) => {
                                e.preventDefault();
                                manageInventoryFn(item);
                              }}>&nbsp;{item.name} ({item.inventoryType?.name})</a>
                            <Avatar alt={item?.name}
                                    className={'pull-left mr-2'}
                                    sx={{width: 32, height: 32}}>
                              {item?.imageUrl && <img src={item?.imageThumbUrl || item?.imageUrl}
                                                           style={{width: '200%', height: 'auto'}}
                                                           alt={item?.name}/> }
                            </Avatar>
                            {item.desc && <>
                              <small className={'text-small my-n2'}><br/>{item.desc}</small>
                            </>}
                          </div>
                        )
                      })
                    }
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {manageAccountType && <ManageAccountTypeModal
          accountType={accountType}
          show={manageAccountType}
          saving={saving}
          handleSave={saveAccountTypeFn}
          handleClose={closeAccountTypeFn}/>}

          {manageInventory && <ManageInventoryMembershipModal
          inventory={inventory}
          isInventoryEvent={isInventoryEvent}
          show={manageInventory}
          saving={saving}
          handleSave={handleSaveInventory}
          handleClose={handleCloseInventory}/>}


          {confirmDelete && <Confirm confirmation={confirmation}
                                     show={confirmDelete}
                                     saving={saving}
                                     handleConfirm={handleDeleteInventory}
                                     handleClose={handleCloseDeleteInventory}/>}
        </MDBox>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}