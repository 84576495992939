import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {forwardRef, useContext, useEffect, useState} from "react";

import Meta from "../common/Meta";
import {AppContext} from "../../lib/AppContext";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Footer from "../Footer";
import {getReportByTypeRange, getReportByTypeYearMonth} from "../common/api/ReportApi";
import {CardContent, Link} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Loading from "../common/Loading";
import {formatDateMask, formatDateTime} from "../common/Utils";
import BootyPagination from "../common/bootyPagination";
import DataTable from "react-data-table-component";
import * as pagination from "../common/pagination";
import {useForm} from "react-hook-form";
import Icon from "@mui/material/Icon";
import {BarChart} from '@mui/x-charts/BarChart';
import MDButton from "../../components/MDButton";
import StatisticsCard from "./card/StatisticsCard";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import {getCookie, setCookie} from "../common/appCookie";
import {Col} from "react-bootstrap";
import Switch from "@mui/material/Switch";

export default function VisitsReportDetails({type, range}) {
  const widthFn = () => {
    let w = parseInt(window.innerWidth, 10) * .5;
    return parseInt(window.innerWidth, 10) * .5;
  }
  const [docWidth, setDocWidth] = useState(100);

  useEffect(() => {
    function handleWidthFn() {
      setDocWidth(widthFn());
    }

    window.addEventListener("resize", handleWidthFn);
    handleWidthFn();
    return () => window.removeEventListener("resize", handleWidthFn);
  }, []);

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();
  const [reportPeriod, setReportPeriod] = useState();
  const [reportData, setReportData] = useState();

  const [eventDate, setEventDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();

  const [reportEndPeriod, setReportEndPeriod] = useState();
  const [rangeReport, setRangeReport] = useState(range);

  const _BootyPagination = {...BootyPagination};

  let {reportYear, reportMonth, reportEndYear, reportEndMonth} = useParams();

  useEffect(() => {

    let _period = reportYear + '/' + reportMonth;
    setReportPeriod(_period);

    let _d = new Date();
    _d.setFullYear(reportYear);
    let m = moment(reportMonth, 'MMM')
    _d.setMonth(m.month());
    setEventDate(_d);

    if (!range) {
      setReportEndPeriod(_period);
      setEventEndDate(_d);

      getSalesReportByYearMonthFn(_period);

      return;
    }

    let _endPeriod = reportEndYear + '/' + reportEndMonth;
    setReportEndPeriod(_endPeriod);
    getSalesReportByRangeFn(_period, _endPeriod);
    let _dd = new Date();
    _dd.setFullYear(reportEndYear);
    let mm = moment(reportEndMonth, 'MMM')
    _dd.setMonth(mm.month());

    setReportEndPeriod(_endPeriod);
    setEventEndDate(_dd);

  }, []);

  useEffect(() => {
    _BootyPagination.reportData = {...reportData};
  }, [reportData]);


  const [labels, setLabels] = useState();
  const [chartData, setChartData] = useState();

  const formatChartData = (report) => {
    setChartData(report?.pieChartData);
  }

  const getSalesReportByRangeFn = (startPeriod, endPeriod) => {

    setReportData(null);

    getReportByTypeRange(type, startPeriod, endPeriod).then(r => {
      if (r.error) {
        setContext({...context, error: {message: r.message}});
        setReportData({});
      } else {
        setReportData(r);
        formatChartData(r);
      }
    });

  }


  const getSalesReportByYearMonthFn = (period) => {

    setReportData(null);

    getReportByTypeYearMonth(type, period).then(r => {
      if (r.error) {
        setContext({...context, error: {message: r.message}});
        setReportData({});
      } else {
        setReportData(r);
        formatChartData(r);
      }
    });

  }

  const _columns = [
    {
      name: <span className='th'>Visit Date</span>,
      id: 1,
      selector: row => row.visitDate,
      cell: row => {
        return formatDateTime(row.visitDate);
      },
      sortType: (a, b) => {
        return new Date(b.visitDate) - new Date(a.visitDate);
      },
      sortable: true,
    },
    {
      name: <span className='th'>Customer</span>,
      id: 2,
      selector: row => row.member?.lastName?.toUpperCase() + '-' + row.member?.firstName?.toUpperCase(),
      cell: row => {
        return <Link className="link"
                     href={'/client/' + row.member.guid}>
          {row.member?.lastName + (row.member?.firstName ? ', ' + row.member?.firstName : '')}
        </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Event</span>,
      id: 14,
      selector: row => row.event.inventory.name.toUpperCase(),
      cell: row => {
        return  <Link className="link"
                      href={'/event/view/' + row.event.inventory.guid}>
          {row.event.inventory.name}
        </Link>
        // return <>{row.event.inventory.name}</>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Event Type</span>,
      id: 15,
      selector: row => row.event.inventory.eventType?.name?.toUpperCase(),
      cell: row => {
        return <>{row.event.inventory.eventType?.name || 'None'}</>;
      },
      sortable: true,
    },
  ];

  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);
  const [columns, setColumns] = useState(_columns);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);
  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  const toggleReportRange = () => {
    setRangeReport(!rangeReport)
  }

  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);

  const exportCsv = () => {
    let _rows = {...reportData};
    let d = _rows?.reportItems;
    let filename = 'Visitor-Export-' + formatDateMask(new Date(), 'MM-DD-YYYY-hh-mm-ss') + '.csv';

    let rows = [];
    let _dd = [];
    _dd.push("Visit Date");
    _dd.push("Customer Last Name");
    _dd.push("Customer First Name");
    _dd.push("Event");
    _dd.push("Event Type");

    rows.push(_dd);

    for (let j in d) {
      let _dd = [];
      _dd.push(formatDateTime(d[j].visitDate));
      _dd.push(d[j].member?.lastName);
      _dd.push(d[j].member?.firstName);
      _dd.push(d[j].event.inventory.name);
      _dd.push(d[j].event.inventory.eventType.name);

      rows.push(_dd);
    }

    let data = rows.map(row =>
      row
        .map(String)  // convert every value to String
        .map(v => v.replaceAll('"', '""'))  // escape double quotes
        .map(v => `"${v}"`)  // quote it
        .join(',')  // comma-separated
    ).join('\r\n');

    var blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const handleSort = (column, sortDirection) => {

    setSortOrder(column.id);
    setSortDir(sortDirection);

    setCookie('VISITOR_REPORT_SORT', column.id);
    setCookie('VISITOR_REPORT_SORT_DIR', sortDirection);

    setRender(render + 1);

  };

  const updateEventDates = (date) => {
    let _d = new Date(date);
    let _period = (_d.getFullYear()) + '/' + formatDateMask(_d, 'MMM');
    setEventDate(_d);
    setReportPeriod(_period);
  }

  const updateEventEndDates = (date) => {
    let _d = new Date(date);
    let _period = (_d.getFullYear()) + '/' + formatDateMask(_d, 'MMM');
    setEventEndDate(_d);
    setReportEndPeriod(_period);
  }

  const getReport = () => {
    if (rangeReport) {
      getSalesReportByRangeFn(reportPeriod, reportEndPeriod);
      navigate(`/${type}/report/${reportPeriod}/${reportEndPeriod}`, {replace: true});
    } else {
      getSalesReportByYearMonthFn(reportPeriod);
      navigate(`/${type}/report/${reportPeriod}`, {replace: true});
    }
  }

  const {register, handleSubmit, setValue, control} = useForm({});

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <span className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <MDTypography variant="h5" color="secondary" fontWeight="light" sx={{
        display: 'inline-block'
      }}>
        <Icon sx={{
          marginTop: '2px',
        }}>event</Icon>Report Date - {formatDateMask(eventDate, 'MMMM yyyy')}
      </MDTypography>
    </span>
  ));

  const countVisits = (data) => {
    let members = [];
    for (let j in data.reportItems) {
      if (members.indexOf(data.reportItems[j].member.id) === -1) {
        members.push(data.reportItems[j].member.id);
      }
    }

    return members.length;
  }

  const countEvents = (data) => {
    let events = [];
    for (let j in data.reportItems) {
      if (events.indexOf(data.reportItems[j].event.inventory.id) === -1) {
        events.push(data.reportItems[j].event.inventory.id);
      }
    }

    return events.length;
  }

  const valueFormatter = (value) => (value);

  return (

    <DashboardLayout>

      <Meta pageTitle={'Visitor Report'}/>

      <ContextSearchNavbar title="Visitor Report"/>

      <MDBox py={3}>
        <MDBox mb={3}>

          {!reportData && <Loading/>}
          {reportData && <Grid container spacing={1} mb={2}>

            <Grid item xs={12}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-root' : {
                    borderRadius: '4px 0 0 4px'
                  }
                }}
                closeOnSelect={false}
                views={['month', 'year']}
                maxDate={dayjs()}
                slotProps={{
                  actionBar: {
                    actions: ['today', 'accept'],
                  },
                }}
                value={dayjs(eventDate)}
                onChange={(e) => {
                  updateEventDates(e?.$d || null);
                }}
              />
              {rangeReport &&
                <DatePicker
                  sx={{
                    '& .MuiInputBase-root' : {
                      borderRadius: '4px 0 0 4px'
                    }
                  }}
                  closeOnSelect={false}
                  views={['month', 'year']}
                  minDate={dayjs(eventDate)}
                  maxDate={dayjs()}
                  slotProps={{
                    actionBar: {
                      actions: ['today', 'accept'],
                    },
                  }}
                  value={dayjs(eventEndDate)}
                  onChange={(e) => {
                    updateEventEndDates(e?.$d || null);
                  }}
                />}
              <MDButton
                sx={{
                  borderRadius : '0 4px 4px 0',
                  height: '100%'
                }}
                color="info"
                onClick={()=> {
                  getReport();
                }}
              >Go</MDButton>
            </Grid>
            <Grid item xs={12}>
              <Col xs={12}>
                <label
                  htmlFor="rangeReport">
                  Date Range
                  <Switch
                    name={'rangeReport'}
                    id={'rangeReport'}
                    checked={rangeReport}
                    style={{marginRight: '5px'}}
                    onChange={toggleReportRange}
                  /> </label>
              </Col>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Card>
                <CardContent sx={{
                  height: '320px'
                }}>

                  {chartData && <BarChart
                    tooltip={{trigger: 'item'}}
                    grid={{vertical: true, horizontal: true}}
                    dataset={chartData}

                    xAxis={[
                      {
                        scaleType: 'band',
                        dataKey: 'label',
                        colorMap: {
                          type: 'ordinal',
                          colors: ['#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e']
                        }
                      }
                    ]}
                    series={[{dataKey: 'value', valueFormatter}]}
                    width={docWidth}
                    height={300}
                  />}
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
              <StatisticsCard
                title="Total Events"
                count={countEvents(reportData)}
                percentage={{
                  color: "success",
                  label: "Total Unique Events for period",
                }}
              />

              <div className="mt-2">

                <StatisticsCard
                  title="Total Visits"
                  count={countVisits(reportData)}
                  percentage={{
                    color: "success",
                    label: "Total Unique Visitors for period",
                  }}
                />
              </div>
            </Grid>

            <Card sx={{
              marginTop: '15px',
              width: '100%'
            }}>
              <CardContent>

                <MDButton
                  className="pull-right"
                  color="secondary"
                  size="small"
                  onClick={exportCsv}><span className="fa fa-file-excel"></span>&nbsp;Export</MDButton>
                <DataTable
                  striped
                  noDataComponent={<MDTypography variant={'p'} className={'cursor-default'}
                                                 fontWeight={'light'}>No
                    Visits Data Found</MDTypography>}
                  columns={columns}
                  keyField={'guid'}
                  data={reportData?.reportItems}
                  onSort={handleSort}
                  highlightOnHover
                  onRowClicked={(row) => {
                    // handleRowClick(row);
                  }}
                  paginationTotalRows={reportData?.reportItems?.length}
                  onChangePage={handlePageChange}
                  progressPending={!reportData}
                  defaultSortFieldId={getCookie('VISITOR_REPORT_SORT') || 2}
                  defaultSortAsc={getCookie('VISITOR_REPORT_SORT_DIR') === 'asc'}
                  paginationComponentOptions={PaginationOptions}
                />

              </CardContent>
            </Card>

          </Grid>
          }
        </MDBox>
      </MDBox>
      <Footer mt={5}/>
    </DashboardLayout>
  );
}