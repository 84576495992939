import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {Card} from "@mui/material";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import {useMaterialUIController} from "../../context";
import {getCompanyWaiverList,} from "../common/api/WebApi";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DataTable from "react-data-table-component";
import BootyPagination from "../common/bootyPagination";
import PageLoading from "../common/PageLoading";
import * as pagination from "../common/pagination";
import {formatDate} from "../common/Utils";
import {getCookie, setCookie} from "../common/appCookie";


export default function ListCustomerWaivers(props) {

  let {guid} = useParams();

  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(true);

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [waivers, setWaivers] = useState([]);
  const [render, setRender] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortDir, setSortDir] = useState('asc');
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);
  const [searchCriteria, setSearchCriteria] = useState();

  useEffect(() => {
    getCompanyWaivers();
  }, []);

  const getCompanyWaivers = async () => {
    setLoading(true);
    getCompanyWaiverList().then(result => {
      setLoading(false);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setWaivers(result);
      }
    });
  }


  const handleSort = (column, sortDirection) => {

    setSortOrder(column.id);
    setSortDir(sortDirection);
    setCookie('WAIVERS_SORT', column.id);
    setCookie('WAIVERS_SORT_DIR', sortDirection);

    setRender(render + 1);

  };
  const _columns = [
    {
      name: <span className='th'>Name</span>,
      id: 1,
      selector: row => row.waiverName,
      cell: row => {
        let link = '/communication/waivers/' + row.guid;
        return <Link to={link} className="link"> {row.waiverName} </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Create Date</span>,
      id: 2,
      selector: row => row.createDate,
      cell: row => {
        return <span>{formatDate(row.createDate)}</span>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Modify Date</span>,
      id: 3,
      right: true,
      selector: row => row.modifyDate,
      cell: row => {
        return <span>{formatDate(row.modifyDate)}</span>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>View</span>,
      id: 4,
      right: true,
      selector: row => row.guid,
      cell: row => {
        let link = '/communication/waivers/' + row.guid;
        return <div><MDButton onClick={() => {
          navigate(link)
        }} size="small" color="primary">View</MDButton></div>
      },
      sortable: false,
    },
  ];

  const [columns, setColumns] = useState(_columns);
  const handleSearchChange = (e) => {
    setSearchCriteria(e.target.value);
  }

  const filteredResults = (items) => {
    if (!searchCriteria || searchCriteria.length === 0) {
      return items;
    }
    let _items = [];
    for (let j in items) {
      let nameCrit = items[j]?.waiverName.toLowerCase();
      let match = nameCrit.match(searchCriteria.toLowerCase());
      if (match && match.length > 0) {
        _items.push(items[j]);
      }
    }
    return _items;
  }

  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  return (
    <DashboardLayout>
      <Meta pageTitle={'Company Waivers'}/>
      <ContextSearchNavbar title="Company Waivers"/>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox pb={3}>
              <MDBox mb={3}>
                <Card>
                  <MDBox p={3} lineHeight={1}>

                    <MDButton
                      onClick={() => {
                        navigate('/communication/waivers/new');
                      }}
                      color="info"
                      size="small"
                      className="pull-right"
                      variant="contained">New Waiver</MDButton>

                    <MDTypography variant="h5" fontWeight="regular">
                      Your Waivers
                    </MDTypography>
                    <Grid container justifyContent={'flex-end'} mt={2}>
                      <Grid item xs={12} sm={4}>
                        <MDInput type="text"
                                 variant="outlined"
                                 value={searchCriteria || ''}
                                 onChange={handleSearchChange}
                                 className="form-control mb-2" id="searchCriteria" aria-describedby="searchCriteria"
                                 label="Search"/>
                      </Grid>
                    </Grid>
                  </MDBox>
                  {!loading && <DataTable
                    striped
                    columns={columns}
                    keyField={'guid'}
                    data={filteredResults(waivers)}
                    onSort={handleSort}
                    highlightOnHover
                    pagination
                    paginationPerPage={20}
                    paginationComponent={BootyPagination}
                    onChangePage={handlePageChange}
                    progressPending={loading}
                    defaultSortFieldId={getCookie('WAIVERS_SORT') || 1}
                    defaultSortAsc={getCookie('WAIVERS_SORT_DIR') === 'asc'}
                    paginationComponentOptions={PaginationOptions}
                  />}
                </Card>
              </MDBox>

              {loading && <PageLoading/>}

            </MDBox>
          </Grid>
        </Grid>
      </MDBox>


      <Footer mt={5}/>

    </DashboardLayout>
  );
}