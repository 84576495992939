import {useContext, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import {login, resetPass} from "./api/WebApi";
import {useNavigate} from "react-router-dom";
import {Grid} from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Divider from "@mui/material/Divider";

export default function LoginForm(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [clientLogin, setClientLogin] = useState(props?.client || false);
  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [passwd, setPasswd] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [createAccount, setCreateAccount] = useState(props?.createAccount || false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showForgotPasswordMessage, setShowForgotPasswordMessage] = useState(false);

  const toggleShowForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  }

  const handleChange = (event) => {
    if (event.target.id === 'username') {
      setUsername(event.target.value);
    }
    if (event.target.id === 'passwd') {
      setPasswd(event.target.value);
    }
    if (event.target.id === 'email') {
      setEmail(event.target.value);
    }
  };

  const forgotPassFn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const auth = {
      email: email
    };

    setLoginError(null);
    setLoggingIn(true);
    let result = await resetPass(auth);

    if (result.error) {
      setLoginError(result.message);
    } else {
      setShowForgotPassword(false);
      setShowForgotPasswordMessage(true);

      setContext({sessionUser: result});
      setShowLogin(false);
      setUsername('');
      setPasswd('');
    }
    setLoggingIn(false);
  }

  const loginFn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const auth = {
      username: username,
      passwd: passwd,
      email: email,
    };

    setLoginError(null);
    setLoggingIn(true);
    let result = await login(auth);
    if (result.error) {
      setLoginError(result.message);
    } else {
      setContext({sessionUser: result});
      setShowLogin(false);
      setUsername('');
      setPasswd('');

      if (props.handleLogin) {
        props.handleLogin(result);
      } else {
        window.location.href = '/';
      }
    }
    setLoggingIn(false);
  }
  return (
    <>
      <div>
        {showForgotPassword && <form onSubmit={forgotPassFn}>
          <Grid container>
            <Grid item xs={12}>
              <MDBox>
                <Grid container p={1}>
                  <Grid item xs={12} sm={12}>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <MDInput type="email"
                               variant="outlined"
                               value={email || ''}
                               onChange={handleChange}
                               className="form-control" id="email" aria-describedby="emailHelp"
                               label="Enter email"/>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  <Grid item xs={12} sm={12}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {loginError && <p className={'text-center text-danger'}>{loginError}</p>}

                      <MDButton type="submit" className={'pull-right'} color="info" disabled={loggingIn}>
                        {loggingIn ? 'Resetting Password...' : 'Reset Password'}
                      </MDButton>

                      {props.handleCancel &&
                        <MDButton className={'pull-right mr-2'} color="info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.handleCancel()
                                  }}>
                          Cancel
                        </MDButton>
                      }
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MDTypography variant="p" fontWeight="light">
                      <Divider/>
                      <p className={'text-right'}><a className={'link-underline-info cursor-pointer'} onClick={() => {
                        toggleShowForgotPassword();
                      }}>Back</a>
                      </p>
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </form>
        }

        {!showForgotPassword && <form onSubmit={loginFn}>
          <Grid container>
            <Grid item xs={12}>
              <MDBox>
                <Grid container p={1}>
                  <Grid item xs={12} sm={12}>
                    {!clientLogin && <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <MDInput type="email"
                               required
                               variant="outlined"
                               value={email || ''}
                               onChange={handleChange}
                               className="form-control" id="email" aria-describedby="emailHelp"
                               label="Enter email"/>
                    </div>
                    }

                    {clientLogin &&
                      <div className="form-group">
                        <label htmlFor="email">Username</label>
                        <input type="text"
                                 autoCapitalize="none"
                                 autoCorrect="none"
                                 value={username || ''}
                                 onChange={handleChange}
                                 className="form-control"
                                 autoComplete="current-username"
                                 id="username"
                                 placeholder="Enter Username" />
                      </div>
                    }
                  </Grid>
                </Grid>
                <Grid container p={1}>
                  <Grid item xs={12} sm={12}>
                    <div className="form-group">
                      <label htmlFor="passwd">Password</label>
                      <input
                        value={passwd}
                        onChange={handleChange}
                        type="password"
                        className="form-control"
                        id="passwd"
                        autoComplete="current-password"
                        placeholder="Enter Password"/>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  <Grid item xs={12} sm={12}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {loginError && <p className={'text-center text-danger'}>{loginError}</p>}

                      <MDButton type="submit" className={'pull-right'} color="info" disabled={loggingIn}>
                        {loggingIn ? 'Logging in...' : 'Login'}
                      </MDButton>

                      {props.handleCancel &&
                        <MDButton className={'pull-right mr-2'} color="info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.handleCancel()
                                  }}>
                          Cancel
                        </MDButton>
                      }
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MDTypography variant="p" fontWeight="light">
                      <Divider/>
                      {showForgotPasswordMessage &&
                        <p className={'text-center'}>Password reset instructions have been emailed to you</p>
                      }
                      <p className={'text-right'}><a className={'link-underline-info cursor-pointer'} onClick={() => {
                        toggleShowForgotPassword();
                      }}>Forgot Password?</a>
                      </p>
                    </MDTypography>

                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </form>}
      </div>
    </>
  )
}