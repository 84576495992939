import {AppContext} from "../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {
  addDate,
  displayDay,
  displayDuration,
  formatCurrency,
  formatDate,
  formatDateTime,
  handleDefaultValues,
  inventoryAvailable
} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {
  getClientInventoryEvents,
  getInventoryEvent,
  getInventoryEventFull,
  getInventoryEvents
} from "../common/api/InventoryApi";
import {Card, Divider, Grid} from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import * as pagination from "../common/pagination";
import BootyPagination from "../common/bootyPagination";
import DataTable from "react-data-table-component";
import Icon from "@mui/material/Icon";
import Loading from "../common/Loading";
import {Col, Row} from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {getCookie, setCookie} from "../common/appCookie";

export default function DashboardEvents(props) {
  const navigate = useNavigate();

  const registerFn = props.register || null;
  const [context, setContext] = useContext(AppContext);

  const [client, setClient] = useState(null);

  const registerMembershipEvent = (event, memberAccount) => {
    let e = {...event};
    e.memberAccount = {...memberAccount};
    registerFn(e);
  }

  const [eventSelected, setEventSelected] = useState();
  const [eventSelectedLoading, setEventSelectedLoading] = useState();

  const [eventsLoading, setEventsLoading] = useState(true);
  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [inventoryRender, setInventoryRender] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);
  const [pageSize, setPageSize] = useState(searchParams.get('pageSize') || 10);
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);

  const [eventDate, setEventDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(new Date());

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    }
    setEventDate(getCookie('DASHBOARD_EVENT_DATE') || new Date());
  }, [props?.client]);

  const handleSort = (column, sortDirection) => {

    setSortOrder(column.id);
    setSortDir(sortDirection);

    setCookie('DASHBOARD_EVENTS_SORT', column.id);
    setCookie('DASHBOARD_EVENTS_SORT_DIR', sortDirection);

    setRender(render + 1);

  };

  const registerOptions = (row) => {
    if (row.memberAccountTypes?.length > 0) {
      return <div>
        {row.memberAccountTypes.map((_row, index) => {
          return (<MDButton onClick={() => registerMembershipEvent(row, _row)}
                            size={'small'}
                            key={index}
                            variant="gradient"
                            color="info">
            Register ({_row.name})
          </MDButton>)
        })
        }
      </div>

    } else {
      return <div>
        <MDButton onClick={() => registerFn(row)} variant="gradient"
                  size={'small'}
                  color="secondary">
          Register
        </MDButton>
      </div>
    }
  }

  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };


  const _columns = [
    {
      name: <span className='th'></span>,
      id: -1,
      width: '52px',
      selector: row => row.name,
      cell: row => {
        return <Avatar alt={row.name} sx={{width: 32, height: 32}}>
          {row.imageUrl &&
            <img src={row.imageThumbUrl || row.imageUrl} style={{width: '200%', height: 'auto'}} alt={row.name}/>}
          {!row.imageUrl && row.name.substring(0, 2)}
        </Avatar>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Name</span>,
      id: 1,
      selector: row => row.name,
      cell: row => {
        let link = '/event/view/' + row.guid;
        return <Link to={link} className="link"> {row.name} </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Start Time</span>,
      id: 2,
      selector: row => row.eventDate,
      cell: row => {
        return <>{displayDay(row.eventDate, 'hh:mm A')} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>End Time</span>,
      id: 3,
      selector: row => row.eventEndDate,
      cell: row => {
        return <>{row.allDay ? 'All Day' : displayDay(row.eventEndDate, 'hh:mm A')} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Duration</span>,
      id: 4,
      selector: row => row.duration,
      cell: row => {
        return <>{displayDuration(row)}</>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Total Available</span>,
      id: 5,
      selector: row => row.publicAvailableQuantity,
      right: true,
      cell: row => {
        return <span className={'text-right'}>{inventoryAvailable(row)}</span>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Registered</span>,
      id: 6,
      selector: row => row.totalReservations,
      right: true,
      cell: row => {
        return <>{row?.totalReservations || 0}</>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>View</span>,
      id: 7,
      right: true,
      selector: row => row.guid,
      cell: row => {
        if (registerFn) {
          return registerOptions(row);
        } else {
          let link = '/event/view/' + row.guid;
          return <div><MDButton href={link} size={'small'} color="primary">View</MDButton></div>
        }
      },
      sortable: false,
    }
  ];

  const [columns, setColumns] = useState(_columns);

  useEffect(() => {

    if (!eventDate) {
      return;
    }

    let startDate = new Date(eventDate);
    startDate.setHours(0, 0, 0, 0);

    let req = {
      start: startDate,
      end: startDate
    }
    props.handleDateChange(startDate);
    getInventoryEventsFn(req, props?.client);
  }, [eventDate]);

  const getInventoryEventFn = async (eventId) => {
    getInventoryEvent(eventId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventReady(true);
      }
    });
  }

  const [inventoryType, setInventoryType] = useState();
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const handleClose = () => {
    props.handleSave();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryEventsFn = async (req, client) => {
    setEventsLoading(true);
    let result = client ? await getClientInventoryEvents(req, client, inventoryRender) : await getInventoryEvents(req, inventoryRender);
    setEvents(result.events);
    setEventsLoading(false);
    setLoading(false);
  }

  const loadCartFn = () => {

    let _context = {...context};
    _context.reloadCart = true;
    _context.toast = {
      message: 'Added to cart!',
      type: 'success'
    }
    setContext({..._context});
  };

  const updateEventDates = (date) => {
    setEventDate(date);
    setEventEndDate(addDate(date, 1));
    setCookie('DASHBOARD_EVENT_DATE', date);
  }

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(eventDate)}
    </div>
  ));

  const handleRowClick = (row) => {
    events.map((_row) => {
      _row.selected = false;
    });
    row.selected = true;
    setEvents(events);
    setEventSelectedLoading(true);

    getInventoryEventFull(row.guid).then((response) => {
      if (response.error) {
        setContext({...context, error: {message: response.message}});
      } else {
        setEventSelected(response);
        setEventSelectedLoading(false);
      }
    });
  }

  const conditionalRowStyles = [
    {
      when: row => row.selected,
      style: {
        backgroundColor: "#d7d5d5",
        userSelect: "none"
      }
    }
  ];


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={eventSelected || eventSelectedLoading ? 8 : 12}>
          <Card>
            <MDBox p={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MDBox width="100%" lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="regular">
                      Calendar Events
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={6}>
                  <div className={'pull-right'}>
                    <MDBox width="100%" lineHeight={1}>
                      <MDTypography variant="h5" color="secondary" textAlign="right" fontWeight="light">
                        <DatePicker
                          slotProps={{
                            actionBar: {
                              actions: ['today'],
                            },
                          }}
                          value={dayjs(eventDate)}
                          onChange={(e) => {
                            updateEventDates(e?.$d || null);
                          }}
                        />
                      </MDTypography>
                    </MDBox>
                  </div>
                </Grid>
              </Grid>
              <Divider/>

              <Grid container spacing={2}>

                <Grid item xs={12}>
                  {eventsLoading && <Loading/>}

                  {!eventsLoading && <DataTable
                    striped
                    noDataComponent={<MDTypography variant={'p'} className={'cursor-default'} fontWeight={'light'}>No
                      events
                      for {formatDate(eventDate)}</MDTypography>}
                    columns={columns}
                    keyField={'guid'}
                    data={events}
                    onSort={handleSort}
                    highlightOnHover
                    onRowClicked={(row) => {
                      handleRowClick(row);
                    }}
                    conditionalRowStyles={conditionalRowStyles}
                    pagination
                    paginationPerPage={pageSize}
                    paginationComponent={BootyPagination}
                    paginationTotalRows={totalRecords}
                    onChangePage={handlePageChange}
                    progressPending={loading}
                    defaultSortFieldId={getCookie('DASHBOARD_EVENTS_SORT') || 2}
                    defaultSortAsc={getCookie('DASHBOARD_EVENTS_SORT_DIR') === 'asc'}
                    paginationComponentOptions={PaginationOptions}
                  />}
                </Grid>
              </Grid>

            </MDBox>
          </Card>
        </Grid>

        {eventSelected && <Grid item xs={12} sm={4}>
          {eventSelectedLoading && <Card><Loading/></Card>}
          {!eventSelectedLoading &&
            <Card>
              <Row>
                <Col sm={12}>
                  <IconButton onClick={() => {
                    setEventSelected(false);
                  }} className="pull-right" size="small" disableRipple>
                    <Icon fontSize="medium">
                      close
                    </Icon>
                  </IconButton>
                </Col>
              </Row>
              <MDBox p={2} pt={0}>
                <MDTypography fontWeight={'regular'} variant={'h5'}>
                  <Link to={`/event/view/${eventSelected?.guid}`}>
                    {eventSelected?.name}
                  </Link>
                </MDTypography>
                <p>
                  {formatDateTime(eventSelected?.eventDate)} - {displayDay(eventSelected?.eventEndDate, 'hh:mm a')}
                </p>
                <Divider/>
                <MDTypography fontWeight={'bold'} variant={'h6'}>Attendees</MDTypography>
                <p>
                  {eventSelected?.reservations?.sort((a, b) => b.id - a.id).map((reservation, index) => {
                    return (
                      <span index={index} key={index} className={'mb-2'}>
                      <Link to={`/client/${reservation?.memberGuid}`}>
                        {reservation?.member?.firstName} {reservation?.member?.lastName}
                        {reservation?.attendee &&
                          <> {' (' + reservation?.attendee.firstName + ' ' + reservation?.attendee.lastName + ')'}</>
                        }
                      </Link>
                      <span className={'d-block'}>
                              {formatDateTime(reservation?.eventDate)}
                            </span>
                    </span>
                    )
                  })
                  }
                </p>
                <Divider/>
                <Row>
                  <Col sm={12}>
                    {eventSelected?.inventoryType?.name && <>
                      <MDTypography fontWeight={'bold'} variant={'h6'}>Inventory Type</MDTypography>
                      <MDTypography fontWeight={'regular'}
                                    variant={'h6'}>{eventSelected?.inventoryType?.name}</MDTypography>
                    </>
                    }
                  </Col>
                  <Col sm={12}>
                    {eventSelected?.eventType?.name && <>
                      <MDTypography fontWeight={'bold'} variant={'h6'}>Category</MDTypography>
                      <MDTypography fontWeight={'regular'}
                                    variant={'h6'}>{eventSelected?.eventType?.name}</MDTypography>
                    </>
                    }
                  </Col>

                </Row>
                <MDTypography fontWeight={'bold'} variant={'h6'}>Description</MDTypography>
                <p>
                  {eventSelected?.desc}
                </p>

                <Row>
                  <Col sm={12}>
                    <MDTypography fontWeight={'bold'} variant={'h6'}>Age</MDTypography>
                    <p>
                      {eventSelected?.ageRange?.name || 'General'}
                    </p>

                    <MDTypography fontWeight={'bold'} variant={'h6'}>Length</MDTypography>
                    <p>
                      {eventSelected?.eventDuration}
                    </p>
                  </Col>
                  <Col sm={12}>

                    <MDTypography fontWeight={'bold'} variant={'h6'}>Price</MDTypography>
                    <p>
                      {formatCurrency(eventSelected?.price)}
                    </p>

                    <MDTypography fontWeight={'bold'} variant={'h6'}>Available</MDTypography>
                    <p>
                      {inventoryAvailable(eventSelected)}
                    </p>

                    <MDTypography fontWeight={'bold'} variant={'h6'}>Registered</MDTypography>
                    <p>
                      {eventSelected?.totalReservations || 0}
                    </p>
                  </Col>
                </Row>
              </MDBox>
            </Card>
          }
        </Grid>}
      </Grid>
    </>
  )
}