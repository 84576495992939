import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {forwardRef, useContext, useEffect, useState} from "react";

import Meta from "../common/Meta";
import {AppContext} from "../../lib/AppContext";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Footer from "../Footer";
import {getReportByTypeRequest} from "../common/api/ReportApi";
import {CardContent, Link} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Loading from "../common/Loading";
import {formatCurrency, formatDate, formatDateMask, formatDateTime} from "../common/Utils";
import BootyPagination from "../common/bootyPagination";
import DataTable from "react-data-table-component";
import * as pagination from "../common/pagination";
import {useForm} from "react-hook-form";
import Icon from "@mui/material/Icon";
import {BarChart} from '@mui/x-charts/BarChart';
import MDButton from "../../components/MDButton";
import StatisticsCard from "./card/StatisticsCard";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import {getCookie, setCookie} from "../common/appCookie";

export default function UpcomingReportDetails({type, range}) {
  const widthFn = () => {
    let w = parseInt(window.innerWidth, 10) * .5;
    return parseInt(window.innerWidth, 10) * .5;
  }
  const [docWidth, setDocWidth] = useState(100);
  const tomorrow = moment().add(1, 'days').toDate();

  useEffect(() => {
    function handleWidthFn() {
      setDocWidth(widthFn());
    }

    window.addEventListener("resize", handleWidthFn);
    handleWidthFn();
    return () => window.removeEventListener("resize", handleWidthFn);
  }, []);

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();
  const [reportPeriod, setReportPeriod] = useState();
  const [reportEndPeriod, setReportEndPeriod] = useState();
  const [reportData, setReportData] = useState();

  let {reportYear, reportMonth} = useParams();

  const handleCookieDate = (cookieName) => {
    let c = getCookie(cookieName);
    let _d = new Date();
    if (c) {
      let _d = new Date(c)
    }
    if (reportYear) {
      _d.setFullYear(reportYear);
    }
    if (reportMonth) {
      let date = moment(reportMonth, "MMM").toDate();
      _d.setMonth(date.getMonth());
    }
    if (c) {
      let _c = new Date(c);
      _d.setDate(_c.getDate());
    }
    return _d;
  }

  const _reportMonth = () => {
    if (reportMonth) {
      let _d = moment(reportMonth, "MMM").toDate()
      if (_d.getTime() > new Date().getTime()) {
        return _d;
      } else {
        return moment(_d).add(1, 'days')
      }
    } else {
      return new Date(moment().add(1, 'days').toDate());
    }
  }

  const [startDate, setStartDate] = useState(new Date(moment().add(1, 'days').toDate()));
  const [endDate, setEndDate] = useState(new Date(moment().add(7, 'days').toDate()));

  const _BootyPagination = {...BootyPagination};

  useEffect(() => {
    _BootyPagination.reportData = {...reportData};
  }, [reportData]);


  const [labels, setLabels] = useState();
  const [chartData, setChartData] = useState();

  const formatChartData = (report) => {
    setChartData(report?.pieChartData);
  }

  useEffect(() => {
    runReport();
  }, []);

  const getReportByTypeRequestFn = () => {

    setReportData(null);

    let _req = {
      startDate: startDate,
      endDate: endDate
    }

    getReportByTypeRequest(type, _req).then(r => {
      if (r.error) {
        setContext({...context, error: {message: r.message}});
        setReportData({});
      } else {
        setReportData(r);
        formatChartData(r);
      }
    });

  }

  const _columns = [
    {
      name: <span className='th'>Created On</span>,
      id: 1,
      selector: row => row.createDate,
      cell: row => {
        return formatDateTime(row.createDate);
      },
      sortType: (a, b) => {
        return new Date(b.values.createDate) - new Date(a.values.createDate);
      },
      sortable: true,
    },
    {
      name: <span className='th'>Next Billing Date</span>,
      id: 11,
      selector: row => row.nextBillingDate,
      cell: row => {
        return formatDate(row.nextBillingDate);
      },
      sortType: (a, b) => {
        return new Date(b.values.nextBillingDate) - new Date(a.values.nextBillingDate);
      },
      sortable: true,
    },
    {
      name: <span className='th'>Customer</span>,
      id: 2,
      selector: row => row.member?.lastName?.toUpperCase() + '-' + row.member?.firstName?.toUpperCase(),
      cell: row => {
        return <><Link className="link"
                     href={'/client/' + row.member?.guid}>
          {row.member?.lastName + (row.member?.firstName ? ', ' + row.member?.firstName : '')}
        </Link></>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Account</span>,
      id: 14,
      selector: row => row.accountType?.name?.toUpperCase(),
      cell: row => {
        return <>{row.accountType?.name}</>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Price</span>,
      id: 15,
      selector: row => row.accountPrice,
      cell: row => {
        return <>{formatCurrency(row.accountPrice)}</>;
      },
      sortable: true,
    },
  ];

  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);
  const [columns, setColumns] = useState(_columns);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);
  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);

  const exportCsv = () => {
    let _rows = {...reportData};
    let d = _rows?.reportItems;
    let filename = 'Member-Export-' + formatDateMask(new Date(), 'MM-DD-YYYY-hh-mm-ss') + '.csv';

    let rows = [];
    let _dd = [];
    _dd.push("Member Since");
    _dd.push("Customer Last Name");
    _dd.push("Customer First Name");
    _dd.push("Memberships");
    _dd.push("Created By");

    rows.push(_dd);

    for (let j in d) {
      let memberships = [];
      for (let k in d[j].accounts) {
        if (memberships.indexOf(d[j].accounts[k].accountType.name) == -1) {
          memberships.push(d[j].accounts[k].accountType.name);
        }
      }
      let _dd = [];
      _dd.push(formatDateTime(d[j].createDate));
      _dd.push(d[j].lastName);
      _dd.push(d[j].firstName);
      _dd.push(memberships.join(', '))
      _dd.push(d[j].createUser?.lastName + ', ' + d[j].createUser?.firstName);

      rows.push(_dd);
    }

    let data = rows.map(row =>
      row
        .map(String)  // convert every value to String
        .map(v => v.replaceAll('"', '""'))  // escape double quotes
        .map(v => `"${v}"`)  // quote it
        .join(',')  // comma-separated
    ).join('\r\n');

    var blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const handleSort = (column, sortDirection) => {

    setSortOrder(column.id);
    setSortDir(sortDirection);

    setCookie('RECUR_BILLING_REPORT_SORT', column.id);
    setCookie('RECUR_BILLING_REPORT_SORT_DIR', sortDirection);

    setRender(render + 1);

  };

  const updateEventEndDates = (date) => {
    let _d = new Date(date);
    setEndDate(_d);
    setCookie('FUTURE_RECUR_BILLING_REPORT_END_DATE', _d);
  }

  const updateEventDates = (date) => {
    let _d = new Date(date);
    setStartDate(_d);
    setCookie('FUTURE_RECUR_BILLING_REPORT_START_DATE', _d);
  }

  const runReport = () => {
    getReportByTypeRequestFn()
  }

  const {register, handleSubmit, setValue, control} = useForm({});

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <span className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <MDTypography variant="h5" color="secondary" fontWeight="light" sx={{
        display: 'inline-block'
      }}>
        <Icon sx={{
          marginTop: '2px',
        }}>event</Icon>Report Date - {formatDateMask(startDate, 'MMMM yyyy')}
      </MDTypography>
    </span>
  ));

  const countMemberships = (data) => {
    let _count = 0;
    let ids = [];
    for (let k in reportData?.reportItems) {
      if (ids.indexOf(reportData?.reportItems[k].member.id) == -1) {
        ids.push(reportData?.reportItems[k].member.id)
      }
    }

    return ids.length;
  }

  const valueFormatter = (value) => (formatCurrency(value));

  return (

    <DashboardLayout>

      <Meta pageTitle={'Recurring Membership Billing Report'}/>

      <ContextSearchNavbar title="Recurring Membership Billing Report"/>

      <MDBox py={3}>
        <MDBox mb={3}>
          {!reportData && <Loading/>}
          {reportData && <Grid container spacing={1} mb={2}>
            <Grid item xs={12}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '4px 0 0 4px'
                  }
                }}
                closeOnSelect={false}
                minDate={dayjs(tomorrow)}
                value={dayjs(startDate)}
                onChange={(e) => {
                  updateEventDates(e?.$d || null);
                }}
              />
              <DatePicker
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '4px 0 0 4px'
                  }
                }}
                closeOnSelect={false}
                minDate={dayjs(tomorrow)}
                value={dayjs(endDate)}
                onChange={(e) => {
                  updateEventEndDates(e?.$d || null);
                }}
              />
              <MDButton
                sx={{
                  borderRadius: '0 4px 4px 0',
                  height: '100%'
                }}
                color="info"
                onClick={() => {
                  runReport();
                }}
              >Go</MDButton>
            </Grid>

            <Grid item sm={8} xs={12}>
              <Card>
                <CardContent sx={{
                  height: '320px'
                }}>

                  {chartData && <BarChart
                    tooltip={{trigger: 'item'}}
                    grid={{vertical: true, horizontal: true}}
                    dataset={chartData}

                    xAxis={[
                      {
                        scaleType: 'band',
                        dataKey: 'label',
                        colorMap: {
                          type: 'ordinal',
                          colors: ['#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e']
                        }
                      }
                    ]}
                    series={[{dataKey: 'value', valueFormatter}]}
                    width={docWidth}
                    height={300}
                  />}
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
              <StatisticsCard
                title="Recurring Sales"
                count={formatCurrency(reportData?.reportTotal)}
                percentage={{
                  color: "success",
                  label: "Total Billing for period",
                }}
              />

              <div className="mt-2">

                <StatisticsCard
                  title="Total Members"
                  count={countMemberships(reportData)}
                  percentage={{
                    color: "success",
                    label: "Total Recurring Members for period",
                  }}
                />
              </div>
            </Grid>

            <Card sx={{
              marginTop: '15px',
              width: '100%'
            }}>
              <CardContent>

                <MDButton
                  className="pull-right"
                  color="secondary"
                  size="small"
                  onClick={exportCsv}><span className="fa fa-file-excel"></span>&nbsp;Export</MDButton>
                <DataTable
                  striped
                  noDataComponent={<MDTypography variant={'p'} className={'cursor-default'}
                                                 fontWeight={'light'}>No
                    Member Data Found for Period</MDTypography>}
                  columns={columns}
                  keyField={'guid'}
                  data={reportData?.reportItems}
                  onSort={handleSort}
                  highlightOnHover
                  onRowClicked={(row) => {
                    // handleRowClick(row);
                  }}
                  paginationTotalRows={reportData?.reportItems?.length}
                  onChangePage={handlePageChange}
                  progressPending={!reportData}
                  defaultSortFieldId={getCookie('RECUR_BILLING_REPORT_SORT') || 2}
                  defaultSortAsc={getCookie('RECUR_BILLING_REPORT_SORT_DIR') === 'asc'}
                  paginationComponentOptions={PaginationOptions}
                />

              </CardContent>
            </Card>

          </Grid>
          }
        </MDBox>
      </MDBox>
      <Footer mt={5}/>
    </DashboardLayout>
  );
}