import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency} from "../../common/Utils";
import {useNavigate} from "react-router-dom";

import {Card, CardContent, CardHeader, Chip, FormControl, Grid, Select} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import {getInventory} from "../../common/api/InventoryApi";
import MenuItem from "@mui/material/MenuItem";
import FileSelector from "../../calendarUI/FilterSelector";
import TextField from "@mui/material/TextField";

export default function InventoryCardTable(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [inventoryLoading, setInventoryLoading] = useState(true);
  const [client, setClient] = useState();
  const [inventory, setInventory] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState();

  const sendToCart = (item) => {
    props?.sendToCart(item);
  }

  const highlight = (haystack) => {
    const needle = searchCriteria;
    if (!needle) {
      return haystack;
    }
    var querystr = needle;
    var result = haystack;
    var reg = new RegExp(querystr.split(' ').join('|'), 'gi');
    var final_str = result.replace(reg, function (str) {
      return '<b>' + str + '</b>'
    });

    return final_str;

  }

  useEffect(() => {
    setClient(props?.client);
  }, [props?.client]);

  useEffect(() => {
    getInventoryFn();
  }, []);

  const filterFn = (item) => {
    if (!searchCriteria || !searchCriteria.length) {
      return true;
    }
    let crit = item.name.toLowerCase();
    let match = crit.match(searchCriteria.toLowerCase());
    if (match && match.length > 0) {
      return true;
    }

    crit = item.desc.toLowerCase();
    match = crit.match(searchCriteria.toLowerCase());
    if (match && match.length > 0) {
      return true;
    }
    if (match && match.length > 0) {
      return true;
    }
    if (item?.tags?.length > 0) {
      let hasTagMatch = false;
      for (let j in item?.tags) {
        crit = item?.tags[j].name.toLowerCase();
        match = crit.match(searchCriteria.toLowerCase());
        if (match && match.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  const [sortInventory, setSortInventory] = useState({field: 'name', direction: 'asc'});

  const getInventoryFn = async () => {
    setInventoryLoading(true);

    getInventory().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventory(result);
        setInventoryLoading(false);
        let _types = []
        let _inventoryTypes = []
        for (let j in result) {
          if (_types.indexOf(result[j].inventoryType.id) == -1) {
            _types.push(result[j].inventoryType.id);
            _inventoryTypes.push(result[j].inventoryType);
          }
        }
        setInventoryTypes(_inventoryTypes);
        setInventoryFiltered(result);
      }
    })

  }

  const getSortField = (sortItem) => {
    return sortItem.field;
  }

  const getSortDirection = (sortItem) => {
    return sortItem.direction;
  }

  const sortPrice = (a, b) => {
    if (a.price === b.price) {
      return a.name.toUpperCase() > b.name.toUpperCase();
    } else {
      return a.price > b.price;
    }
  }
  const sortInventoryFn = (a, b) => {
    const sortDir = sortInventory.direction === 'desc' ? -1 : 1;
    if (sortInventory.field === 'name') {
      return a.name.toUpperCase() > b.name.toUpperCase() ? sortDir : sortDir * -1;
    }
    if (sortInventory.field === 'price') {
      return sortPrice(a, b) ? sortDir : sortDir * -1;
    }
    if (sortInventory.field === 'id') {
      return a.id > b.id ? sortDir : sortDir * -1;
    }
    return a.id > b.id ? sortDir : sortDir * -1;
  }

  const highlightTag = (tag) => {
    return <span dangerouslySetInnerHTML={{__html: highlight(tag.name)}}/>
  }

  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [inventoryTypeFilter, setInventoryTypeFilter] = useState([]);
  const [inventoryFiltered, setInventoryFiltered] = useState([]);

  const showItem = (_item) => {
    let show = (inventoryTypeFilter.length === 0 || inventoryTypeFilter.indexOf(_item?.inventoryType?.id) > -1);
    return show;
  };

  const filteredInventory = (inventories) => {
    let _inventories = [];
    for (let j in inventories) {
      if (showItem(inventories[j])) {
        _inventories.push(inventories[j]);
      }
    }
    setInventoryFiltered(_inventories);
  }

  useEffect(() => {
    filteredInventory(inventory);
  }, [inventoryTypeFilter]);

  return (
    <div style={{
      clear: 'both',
      display: 'block'
    }}>
      <MDBox>
        <Grid container spacing={2}>
          <div className={'d-block w-100 mt-3'}>
            <MDButton
              className={'pull-left m-2'}
              size={'small'}
              color="info"
              onClick={(e) => {
                props?.handleCartView();
              }}>View Cart</MDButton>

            <button
              size="small"
              className={'pull-right btn-close m-1'}
              color="inherit"
              onClick={(e) => {
                props.handleClose(false);
              }}
            >
            </button>
          </div>
          <Grid item xs={12}>
            {inventoryLoading && <Loading/>}

            {!inventoryLoading &&
              <div style={{
                marginTop: '-20px'
              }}>
                <Grid container cols={2} spacing={0} direction="row">
                  <Grid item xs={6}>
                    <TextField type="text"
                               style={{
                                 marginTop: '8px'
                               }}
                               autoComplete={'off'}
                               variant="outlined"
                               value={searchCriteria || ''}
                               onChange={(e) => setSearchCriteria(e.target.value)}
                               className="form-control" id="code" aria-describedby="searchInventory"
                               label="Search Inventory"/>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <FileSelector
                        types={inventoryTypes}
                        typeFilter={inventoryTypeFilter}
                        filterLabel={'Inventory Types'}
                        setFilter={(filters) => {
                          setInventoryTypeFilter(filters)
                        }}
                      />
                    </span>
                  </Grid>
                </Grid>

                <div className={'d-block mb-2'}>
                  <span className={'d-inline p-1'}>Sort:</span>
                  <FormControl sx={{
                    width: 'auto'
                  }}>
                    <Select
                      autoWidth
                      sx={{
                        padding: '5px 10px',
                        marginRight: '5px'
                      }}
                      defaultValue={'name'}
                      onChange={
                        (e) => {
                          setSortInventory({...sortInventory, field: e.target.value});
                        }
                      }>
                      <MenuItem value={'name'}>Name</MenuItem>
                      <MenuItem value={'price'}>Price</MenuItem>
                      <MenuItem value={'id'}>Id</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{
                    width: 'auto'
                  }}>
                    <Select
                      autoWidth
                      sx={{
                        padding: '5px 10px',
                        marginRight: '5px'
                      }}
                      defaultValue={'asc'}
                      onChange={
                        (e) => {
                          setSortInventory({...sortInventory, direction: e.target.value});
                        }
                      }>
                      <MenuItem value={'asc'}>Asc</MenuItem>
                      <MenuItem value={'desc'}>Desc</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <Grid container spacing={1}>
                  {inventoryFiltered?.length > 0 && inventoryFiltered?.sort((a, b) => sortInventoryFn(a, b)) //
                    .filter((item) => filterFn(item))
                    .map((item, index) => {
                      return (
                        <Grid item sm={6} xs={12}
                              index={index} key={index}>
                          <Card className="inner-card" style={{
                            boxShadow: '0 0 2px #ccc'
                          }}>
                            <CardHeader
                              avatar={
                                <Avatar>
                                  {item.imageUrl &&
                                    <img src={item.imageThumbUrl || item.imageUrl}
                                         style={{width: '200%', height: 'auto'}}
                                         alt={item.name}/>}
                                  {!item.imageUrl && item.name.substring(0, 2)}
                                </Avatar>
                              }
                              title={item.name}
                              subheader={formatCurrency(item.price)}
                            >
                            </CardHeader>
                            <CardContent>
                              <div>
                                <MDTypography
                                  variant="p"
                                  fontWeight={'regular'}>
                          <span
                            dangerouslySetInnerHTML={{__html: highlight(item.name)}}/>

                                </MDTypography>
                                <span className="d-block"><b>{formatCurrency(item.price)}</b>
                            <br/>
                            <small style={{
                              whiteSpace: 'normal',
                              padding: '0',
                              margin: '0'
                            }} dangerouslySetInnerHTML={{__html: highlight(item.desc)}}/>
                          </span>
                                <span className="d-block">{item?.tags?.map((item, index) => {
                                  return (
                                    <span index={index} key={index}>
                              <Chip
                                className="mb-1"
                                variant="outlined"
                                label={highlightTag(item)}
                              />&nbsp;
                            </span>
                                  )
                                })}</span>
                                <span className="d-block">
                            <MDButton
                              color="secondary"
                              size="small"
                              className={'pull-right mr-2'}
                              onClick={() => {
                                sendToCart(item);
                              }}>
                              Add to cart
                            </MDButton>
                          </span>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </div>
            }
          </Grid>
        </Grid>

      </MDBox>
    </div>
  )
}