import Confirm from "./Confirm";
import {Col, Modal, Row} from "react-bootstrap";
import {Divider} from "@mui/material";
import {capitalize, formatCurrency, formatDateTime} from "./Utils";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import CheckoutPage from "../CheckoutPage";
import MDButton from "../../components/MDButton";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import {checkout, getCustomerCartFull} from "./api/CartApi";
import Avatar from "@mui/material/Avatar";
import {removeCartItem} from "./api/WebApi";
import Icon from "@mui/material/Icon";
import {CONSTANTS} from "../common/constants";

export default function ClientViewCustomerCartModal(props) {

  const [client, setClient] = useState(props?.client);
  const [context, setContext] = useContext(AppContext);
  const [cartItems, setCartItems] = useState([]);
  const [cartActive, setCartActive] = useState(props?.show);

  const [cart, setCart] = useState();
  const [cartReady, setCartReady] = useState(false);
  const [checkoutActive, setCheckoutActive] = useState(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [checkoutFinal, setCheckoutFinal] = useState(false);
  const [card, setCard] = useState(null);

  const [showCart, setShowCart] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [cashPayment, setCashPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setCartActive(props?.show);
    setCheckoutFinal(false);
  },[props?.show]);

  useEffect(() => {
    if (!cartActive) {
      return;
    }
    setCart(null);
    setCartReady(false);
    getCartFullFn();

  }, [cartActive]);

  const handleClose = () => {
    props.handleClose();
  }

  useEffect(() => {
    setCheckoutDisabled(!cart?.paymentMethod?.guid?.length > 0);
    if (cart?.paymentMethod?.cash) {
      setCheckoutDisabled(false);
    }
  }, [cart]);

  const checkoutFn = async () => {

    setCheckoutDisabled(true);
    setCheckoutActive(true);

    const resp = await checkout(cart,  props?.client);

    if (resp) {
      setCheckoutActive(false);
      setCheckoutDisabled(false);
      if (props.handleSave) {
        props.handleSave(resp);
      }
    }
  };
  const updatePaymentMethod = (item) => {
    setAddPaymentMethod(false);
    let _cart = {...cart};
    _cart.paymentMethod = item;
    setCart(_cart);
  };

  const handleCancelCartRemove = () => {
    setConfirmation(null);
    setShowConfirm(false);
  }

  const getCartFullFn = async () => {
    getCustomerCartFull(props?.client?.guid).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setCart(result);
        setCartReady(true);
      }
    });
  }


  const deleteInventoryItem = async (item) => {
    removeCartItem(item).then((result) => {

      let _context = {...context};
      _context.reloadCart = true;
      setContext({..._context});

      handleCancelCartRemove();
      getCartFullFn();

      props?.handleCartRefresh();

    });
  };

  const toggleCashPaymentMethod = () => {
    setCard(null);
    setAddPaymentMethod(false);

    let _item = {
      cash : true,
      chargeTypeId : CONSTANTS.CHARGE_TYPE.CASH
    }
    updatePaymentMethod(_item);
    setCashPayment(true);
  };

  const addPaymentMethodFn = () => {
    setCard(null);
    setAddPaymentMethod(true);
  };

  const preDeleteInventoryItem = async (item) => {
    let confirmation = {
      title: 'Delete?',
      message: 'Remove ' + item.inventoryItem.name + ' from cart?',
      entity: item
    }
    setConfirmation(confirmation);
    setShowConfirm(true);
  };

  const clientLink = () =>{
    if (!props?.client) {
      return <></>
    }
    return <Modal.Title>&nbsp;(<a className="link hand" href={`/client/${props?.client?.guid}`}>{props?.client?.firstName}
      &nbsp;{props?.client?.lastName}</a>)</Modal.Title>;
  }

  return (
    <>
      <Modal size={'xl'} onHide={handleClose}
             show={cartActive}>

        {!showCart && <>

          {
            confirmation && <Confirm confirmation={confirmation}
                                     show={showConfirm}
                                     saving={saving}
                                     handleSave={()=> {
                                       setShowConfirm(false);
                                     }}
                                     handleConfirm={deleteInventoryItem}
                                     handleClose={handleCancelCartRemove}/>
          }

          <Modal.Header closeButton>
            <Modal.Title>Customer Cart</Modal.Title>
            {clientLink()}
          </Modal.Header>
          <Modal.Body className={'modal-overflow'}>
            {cart && cart.items.length === 0 &&
              <Col xs={12}>
                <h5 onClick={() => {
                  handleClose();
                }}>Cart is empty. Continue shopping?</h5>
              </Col>
            }
            {!checkoutFinal && cart && cart?.items.length > 0 &&
              <Row p={2}>
                <Col xs={12} md={6} p={2}>
                  <h5 className={'text-right'}>
                    Cart {cart.items.length > 1 ? ' items ' : ' item '} ({cart.items.length})
                  </h5>
                  <Divider/>
                  {cart && cart?.items
                    .sort((a, b) => a.cartItemId > b.cartItemId ? 1 : -1)
                    .map((item, index) => {
                      return (
                        <dl key={index}
                            className={'row'}>
                          <dd className={'col col-sm col-sm-2'}>
                            <Avatar alt={item.inventoryItem?.name}
                                    className={'pull-left mr-3'}
                                    sx={{width: 64, height: 64}}>
                              {(item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl) ?
                                <img src={item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl}
                                   style={{width: '200%', height: 'auto'}}
                                   alt={item.inventoryItem?.name}/> : <Icon>event</Icon>
                              }
                            </Avatar>
                          </dd>
                          <dd className={'col col-sm col-sm-10'}>
                            {item.inventoryItem.name}
                            <p className={'my-0'}>
                              {item.inventoryItem?.eventType?.id && <>
                                <small>
                                  {formatDateTime(item.inventoryItem.eventDate, 'hh:mm a')} - {formatDateTime(item.inventoryItem.eventEndDate, 'hh:mm a')}
                                  <br/>
                                </small>
                              </>
                              }
                            </p>
                            <p className={'my-0'}>
                               <span
                                 className={'text-primary ' + (item.discountItem ? 'text-decoration-line-through' : '')}>
                                {formatCurrency(item.inventoryItem.price)}
                              </span>
                              {item.discountItem && <>
                                <br/>
                                <span className={'text-primary'}>
                                {formatCurrency(item.inventoryItem.price - item.discountItem.discountAmount)}
                                  <br/>
                                  {item.discountItem.discountName} - {item.discountItem.discountReason}
                              </span>
                              </>
                              }

                              <a className={'p-1 m-0 pull-right cursor-pointer'}
                                 onClick={(e) => {
                                   preDeleteInventoryItem(item)
                                 }}>
                                <small><span className={'fa fa-times'}></span> Remove</small>
                              </a>
                            </p>
                            <small>
                              Qty: {item.quantity}
                            </small>
                            {/*{formatCurrency(cart.total)}*/}
                          </dd>
                          <Divider/>
                        </dl>
                      )
                    })
                  }

                </Col>

                <Col xs={12} md={6}>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Items Total:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.itemsTotal)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Sales Tax:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.totalTax)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Order Total:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.cartTotal)}
                    </dd>
                  </dl>
                </Col>
              </Row>
            }

            {checkoutFinal &&
              <Row>
                <Col className={'col'} xs={12} md={6}>
                  {client?.guid && <>
                    <MDBox mt={2}>
                      <MDTypography variant="h4" color="secondary" fontWeight="light">
                        Choose Payment Method
                      </MDTypography>

                      {cart && cart?.billings?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                        .map((item, index) => {
                          return (
                            <dl className={'row'} key={index}>
                              <dt className={'col col-sm col-sm-6'}>
                                <label>
                                  <input className={'mx-2'} type={'radio'}
                                         defaultChecked={item.primaryCard}
                                         name={'paymentMethod'}
                                         onChange={(e) => {
                                           updatePaymentMethod(item);
                                         }}
                                  />
                                  {capitalize(item.cardType)} ending in **** {item.cardNumber}
                                </label>
                              </dt>
                              <dd className={'col col-sm col-sm-6 text-right'}>
                                ({item.ccExpy})
                              </dd>
                            </dl>
                          )
                        })}

                      <dl className={'row'}>
                        <dt className={'col col-sm col-12'}>
                          <label>
                            <input className={'mx-2'} type={'radio'}
                                   name={'paymentMethod'}
                                   onChange={(e) => {
                                     addPaymentMethodFn(true);
                                   }}
                            />New Payment Method</label>
                        </dt>
                      </dl>
                      <dl className={'row'}>
                        <dt className={'col col-sm col-12'}>
                          <label>
                            <input className={'mx-2'} type={'radio'}
                                   name={'paymentMethod'}
                                   onChange={(e) => {
                                     toggleCashPaymentMethod();
                                   }}
                            />Cash Payment</label>
                        </dt>
                      </dl>
                    </MDBox>

                  </>
                  }
                  {!(context?.sessionUser && context?.sessionUser?.guid) && <>

                    <h5>
          <span
            onClick={() => {
              setShowLogin(true);
            }}
            className={'mx-2 cursor-pointer ' + (showLogin ? 'text-decoration-underline' : '')}>Login</span>
                      or
                      <span
                        onClick={() => {
                          setShowLogin(false);
                        }}
                        className={'mx-2 cursor-pointer ' + (!showLogin ? 'text-decoration-underline' : '')}>Create Account</span>
                    </h5>

                    <Divider/>

                  </>}

                </Col>
                <Col xs={12} md={6}>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Items Total:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart?.itemsTotal)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Sales Tax:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart?.totalTax)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Order Total:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart?.cartTotal)}
                    </dd>
                  </dl>
                </Col>
              </Row>
            }
          </Modal.Body>


          {addPaymentMethod &&
            <CheckoutPage
              client={client}
              props={props}/>
          }

          {!addPaymentMethod &&
            <Modal.Footer>
              {checkoutFinal && <>
                <MDButton variant="gradient"
                          color="info"
                          disabled={checkoutDisabled}
                          onClick={() => {
                            checkoutFn();
                          }}>
                  {checkoutActive ? 'Checking out...' : 'Complete Purchase'}
                </MDButton>
              </>}
              {cart?.quickCheckout && <>
                <MDButton variant="gradient"
                          color="info"
                          onClick={() => {
                            checkoutFn();
                          }}>
                  {checkoutActive ? 'Checking out...' : 'Complete Purchase'}
                </MDButton>
              </>}

              {!checkoutFinal && !cart?.quickCheckout && <>
                <MDButton variant="gradient"
                          color="info"
                          onClick={() => {
                            setCheckoutFinal(true);
                          }}>
                  Continue to Checkout
                </MDButton>
              </>}
            </Modal.Footer>
          }

        </>
        }
      </Modal>
    </>
  )
}