import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Card, Chip} from "@mui/material";
import Meta from "./common/Meta";
import ContextSearchNavbar from "./navbars/ContextSearchNavbar";
import DashboardLayout from "./LayoutContainers/DashboardLayout";
import MDBox from "../components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../components/MDTypography";
import Footer from "./Footer";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "../components/MDButton";
import {
  getReportDataLastMonth,
  getReportDataThisMonth,
  getReportDataUpcomingSpecialEventsByQuery,
  getReportDataUpcomingVisits
} from "./common/api/ReportApi";
import {formatCurrency, formatDate, formatDateMask, formatDateTime} from "./common/Utils";
import DashboardEvents from "./dashboard/DashboardEvents";
import Avatar from "@mui/material/Avatar";
import {AppContext} from "../lib/AppContext";
import {completeTask, getCompanyTasks, getCustomerNotifications} from "./common/api/WebApi";

export default function Dashboard(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);

  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  const [reportDataSales, setReportDataSales] = useState();
  const [reportDataVisits, setReportDataVisits] = useState();
  const [lastMonthSalesData, setLastMonthSalesData] = useState();
  const [lastMonthVisitsData, setLastMonthVisitsData] = useState();
  const [reservations, setReservations] = useState();
  const [upcomingReservations, setUpcomingReservations] = useState();
  const [notifications, setNotifications] = useState();
  const [specialEvents, setSpecialEvents] = useState();
  const [tasks, setTasks] = useState();
  const [eventDate, setEventDate] = useState();

  const getNotificationsFn = () => {
    let taskDate = formatDateMask(eventDate, 'yyyy-MM-DD');
    getCustomerNotifications(taskDate).then((response) => {
      setNotifications(response);
    });
  }

  const getSalesData = () => {
    getReportDataThisMonth().then((response) => {
      setReportDataSales(formatCurrency(response?.totalSales));
      setReportDataVisits(response?.totalVisits || 0);
    });
    getReportDataLastMonth().then((response) => {
      setLastMonthSalesData(formatCurrency(response?.totalSales));
      setLastMonthVisitsData(response?.totalVisits || 0);
    });
  }

  const getUpcomingReservations = async () => {
    let taskDate = formatDateMask(eventDate, 'yyyy-MM-DD');
    getReportDataUpcomingVisits(taskDate).then((response) => {
      if (response.error) {
        setContext({...context, error: {message: response.message}});
      } else {
        setReservations(response);
      }
    });
  }

  const getUpcomingSpecialEvents = async () => {

    let taskDate = formatDateMask(eventDate, 'yyyy-MM-DD');

    getReportDataUpcomingSpecialEventsByQuery(taskDate).then((response) => {
      if (response.error) {
        setContext({...context, error: {message: response.message}});
      } else {
        setSpecialEvents(response);
      }
    });
  }

  const getTasks = async () => {

    let taskDate = formatDateMask(eventDate, 'yyyy-MM-DD');
    setTasks(null);
    getCompanyTasks(taskDate).then((response) => {
      if (response.error) {
        setContext({...context, error: {message: response.message}});
      } else {
        setTasks(response);
      }
    });
  }

  useEffect(() => {
    if (!eventDate) {
      return;
    }

    // getSalesData();
    getUpcomingReservations();
    getTasks();
    getUpcomingSpecialEvents();
    // getUpcomingReservationsTomorrow();
    getNotificationsFn();
  }, [eventDate]);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({currentTarget}) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({currentTarget}) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({currentTarget}) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({currentTarget}) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({currentTarget}) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({currentTarget}) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{vertical: "top", horizontal: "center"}}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Month to Date</MenuItem>
    </Menu>
  );

  const completeTaskFn = async (task) => {
    completeTask(task).then((response) => {
      if (response.error) {
        setContext({...context, error: {message: response.message}});
      } else {
        getTasks();
      }
    });
  }

  const renderSalesMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{vertical: "top", horizontal: "center"}}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Month to Date</MenuItem>
    </Menu>
  );
  const NoteItem = ({note, index}) => {
    return (<li
      className={'list-group-item hover-bg-soft'}
      key={index}>

      <MDTypography variant="h5" color="secondary"
                    fontWeight="light">
        <Link to={`/client/${note.clientGuid}`}>
          {note.clientFirstName} {note.clientLastName}
        </Link>
      </MDTypography>
      <p>
        <span
          dangerouslySetInnerHTML={{__html: note.note}}/>
      </p>
      <Chip
        label={<>
          {!note.pinned ?
            <span
              style={{
                transform: 'rotate(15deg)'
              }}
              className="font-color-grey fa fa-thumbtack"></span>
            :
            <span
              className="font-color-gold fa fa-thumbtack"></span>
          }</>}
        variant="outlined"
      />
      &nbsp;
      <Chip
        label={<>By: {note.createUser.firstName + ' ' + note.createUser.lastName} On: {formatDateTime(note.createDate)}</>}
        variant="outlined"
      />&nbsp;
      {note.reminderNote && <>
        <Chip
          label={<>Reminder Date: {formatDate(note.reminderDate)}</>}
          variant="outlined"
        />&nbsp;
      </>
      }
    </li>);
  }
  return (
    <DashboardLayout>

      <Meta pageTitle={'Dashboard'}/>

      <ContextSearchNavbar title="Dashboard"/>

      <MDBox py={3}>

        <MDBox mb={3}>
          <DashboardEvents
            handleDateChange={(date) => {
              setEventDate(date)
            }}
          />
        </MDBox>

        <MDBox mb={3}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="regular" mb={2}>
                    Reservations
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="light" mb={2}>
                    You have <b>{reservations?.reservations?.length || 0}</b> reservations for {formatDate(eventDate)}
                  </MDTypography>
                </MDBox>
              </Card>

              <Card className={'mt-3'}>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="regular" mb={2}>
                    Reminders for {formatDate(eventDate)}
                  </MDTypography>
                  <MDBox>
                    <ul className={'list-group'}>
                      {notifications?.length === 0 && <p>No notifications for {formatDate(eventDate)}</p>}
                      {notifications?.length > 0 && notifications?.map((note, index) => {
                        return (
                          <NoteItem note={note} key={index} index={index}/>
                        )
                      })}
                    </ul>

                  </MDBox>
                </MDBox>
              </Card>

            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="regular" mb={2}>
                    Parties
                  </MDTypography>
                  <MDBox>
                    <ul className={'list-group'}>
                      {specialEvents?.length === 0 && <p>No Parties scheduled for {formatDate(eventDate)}</p>}
                      {specialEvents?.length > 0 && specialEvents?.sort((a, b) => b.id - a.id).map((inventoryEvent, index) => {
                        return (
                          <li index={index} key={index}
                              className={'list-group-item'}>
                            <Avatar alt={inventoryEvent.name}
                                    className={'pull-left mr-2'}
                                    sx={{width: 32, height: 32}}>
                              <img src={inventoryEvent.imageThumbnailUrl}
                                   style={{width: '200%', height: 'auto'}}
                                   alt={inventoryEvent.name}/>
                            </Avatar> &nbsp;<Link
                            to={`/event/view/${inventoryEvent?.guid}`}>{inventoryEvent.name}</Link>
                            <span className={'d-block'}>
                              {formatDateTime(inventoryEvent?.eventDate)}
                            </span>
                          </li>
                        )
                      })
                      }
                    </ul>

                  </MDBox>
                </MDBox>
              </Card>


              {tasks?.length > 0 &&
                <Card className={'mt-2'}>
                  <MDBox p={3}>
                    <MDTypography variant="h5" fontWeight="regular" mb={2}>
                      Today's Tasks
                      <MDButton
                        onClick={() => {
                          navigate(`/tasks/history`)
                        }}
                        className={'pull-right'}
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={'pull-right'}
                        color="primary"
                        variant="outlined"
                        size="small">
                        <Icon>history</Icon> &nbsp; View History
                      </MDButton>
                    </MDTypography>
                    <ul className={'list-group'}>
                      {tasks.sort((a, b) => a.sortOrder - b.sortOrder).map((task, index) => {
                        return (
                          <li index={index} key={index}
                              className={'list-group-item'}>
                            {task.name}
                            <span className={'text-small d-block'}>
                              {task.description}
                            </span>
                            {task.completed &&
                              <Chip
                                className={'pull-right'}
                                icon={<Icon>check</Icon>}
                                variant="outlined"
                                label={'Completed ' + formatDateTime(task.completedDate)}/>
                            }
                            {!task.completed &&
                              <Chip
                                disabled={task.completing}
                                onClick={() => {
                                  completeTaskFn(task);
                                }}
                                icon={<Icon>check_circle</Icon>}
                                className={'pull-right'}
                                color="primary"
                                label={'Complete Task'}/>
                            }
                          </li>
                        )
                      })
                      }
                    </ul>
                  </MDBox>
                </Card>
              }
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}