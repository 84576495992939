import {getAppSessionCookieHeader} from "../appCookie";
import api from "../../Api";

export const getReportDataThisMonth = async () => {
  const resp = await api.get('report/data/month/this' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportByTypeRequest = async (type, request) => {
  const resp = await api.post('report/request/' + type + getAppSessionCookieHeader(), request).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportByTypeRange = async (type, startPeriod, endPeriod) => {
  const resp = await api.get('report/range/' + type + '/' + startPeriod + '/' + endPeriod + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportByTypeYearMonth = async (type, period) => {
  const resp = await api.get('report/data/' + type + '/' + period + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportDataUpcomingVisits = async (dateQuery) => {
  const resp = await api.get('report/data/visits/' + dateQuery + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportDataUpcomingSpecialEventsByQuery = async (dateQuery) => {
  const resp = await api.get('report/data/special/q/' + dateQuery + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportDataUpcomingSpecialEvents = async () => {
  const resp = await api.get('report/data/special/today' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportDataToday = async () => {
  const resp = await api.get('report/data/today' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReportDataLastMonth = async () => {
  const resp = await api.get('report/data/month/last' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getActiveMembers = async () => {
  const resp = await api.get('report/memberships/month/this' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

function handleError(error) {
  let err = {
    error: true,
    message: error?.response?.data?.message || JSON.stringify(error)
  }
  return err;
}
