import {AppContext} from "../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {getCompanyTasks} from "../common/api/WebApi";
import {Card, Chip, Grid} from "@mui/material";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";

import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import {useNavigate} from "react-router-dom";
import {formatDate, formatDateMask, formatDateTime, handleDefaultValues} from "../common/Utils";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

export default function TaskHistory(props) {
  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);

  const [saving, setSaving] = useState(false);
  const [manageEmployee, setManageEmployee] = useState(false);
  const [tasks, setTasks] = useState();
 const [taskHistoryDate, setTaskHistoryDate] = useState(new Date());

  useEffect(() => {
    getCompanyTasksByDate();
  }, [taskHistoryDate]);

  const updateEventDates = (date) => {
    setTaskHistoryDate(date);
  }

  const getCompanyTasksByDate = async () => {
    let taskDate = formatDateMask(taskHistoryDate, 'yyyy-MM-DD');
    getCompanyTasks(taskDate).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTasks(result);
      }
    });
  }

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(taskHistoryDate)}
    </div>
  ));

  const [inventoryType, setInventoryType] = useState();


  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  return (
    <DashboardLayout>

      <Meta pageTitle={'Task History'}/>
      <ContextSearchNavbar title={'Task History'}/>
      <MDBox py={3}>
        <Card>
          <MDBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MDBox width="100%" lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
                    &nbsp;
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={6}>
                <MDBox width="100%" lineHeight={1}>
                  <div className={'pull-right'}>
                    <DatePicker
                      slotProps={{
                        actionBar: {
                          actions: ['today'],
                        },
                      }}
                      value={dayjs(taskHistoryDate)}
                      maxDate={dayjs()}
                      onChange={(e) => {
                        setTaskHistoryDate(e?.$d || null);
                      }}
                    />
                  </div>
                </MDBox>
              </Grid>
            </Grid>

            <ul className={'list-group mt-3'}>
              {tasks?.length > 0 && tasks.sort((a, b) => a.sortOrder - b.sortOrder).map((task, index) => {
                return (
                  <li index={index} key={index}
                      className={'list-group-item'}>
                    {task.name}
                    <span className={'text-small d-block'}>
                              {task.description}
                            </span>
                    {task.completed &&
                      <Chip
                        className={'pull-right'}
                        icon={<Icon>check</Icon>}
                        variant="outlined"
                        label={'Completed ' + formatDateTime(task.completedDate)}/>
                    }

                  </li>
                )
              })
              }
            </ul>
          </MDBox>
        </Card>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}