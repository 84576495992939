import {AppContext} from "../../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {addDate, formatCurrency, formatDate} from "../../common/Utils";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

import {Divider, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import * as pagination from "../../common/pagination";
import BootyPagination from "../../common/bootyPagination";
import DataTable from "react-data-table-component";
import Icon from "@mui/material/Icon";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import Tooltip from '@mui/material/Tooltip';
import Menu from "@mui/material/Menu";
import NotificationItem from "../../Items/NotificationItem";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {getInventory} from "../../common/api/InventoryApi";
import MDInput from "../../../components/MDInput";
import {getCookie, setCookie} from "../../common/appCookie";

export default function InventoryDataTable(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [inventoryLoading, setInventoryLoading] = useState(true);
  const [eventReady, setEventReady] = useState(false);
  const [client, setClient] = useState();
  const [event, setEvent] = useState();
  const [inventory, setInventory] = useState([]);
  const [inventoryRender, setInventoryRender] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);
  const [pageSize, setPageSize] = useState(searchParams.get('pageSize') || 20);
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);

  const [eventDate, setEventDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(new Date());

  useEffect(() => {
    setClient(props?.client);
  }, [props?.client]);

  useEffect(() => {
    if (props.reloadInventory) {
      getInventoryFn();
      props.reloadInventoryCallback();
    }
  }, [props?.reloadInventory]);

  const Actions = ({item}) => {
    if (props?.client) {
      return <MDButton
        color="secondary"
        size="small"
        onClick={() => {
          props?.sendToCart(item);
        }}>
        Add to cart
      </MDButton>
    }

    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <span variant="contained" {...bindTrigger(popupState)}>
            <MDButton
              variant="outlined"
              color="secondary"
              size="small"
              circular
              iconOnly
            >
              <Icon>more_horiz</Icon>
            </MDButton>
          </span>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                navigate('/inventory/view/' + item.guid);
              }} icon={<Icon>shopping_bag</Icon>} title="View"/>
            {props.manageInventoryFn && <NotificationItem
              onClick={(e) => {
                popupState.close();
                props.manageInventoryFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>}
            {props.confirmDeleteInventory &&
              <NotificationItem onClick={(e) => {
                popupState.close();
                props.confirmDeleteInventory(item)
              }} icon={<Icon>delete</Icon>} title="Archive"/>}
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  const _columns = [
    {
      name: <span className='th'>&nbsp;</span>,
      id: -1,
      width: '52px',
      selector: row => row.name,
      cell: row => {
        return <Tooltip title={row.desc || row.name}><Avatar alt={row.name} sx={{width: 32, height: 32}}>
          <img src={row.imageUrl} style={{width: '200%', height: 'auto'}} alt={row.name}/></Avatar></Tooltip>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Name</span>,
      id: 1,
      selector: row => row.name,
      cell: row => {
        let link = '/inventory/view/' + row.guid;
        return <Link to={link} className="link"> {row.name} </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Type</span>,
      id: 2,
      selector: row => row.inventoryType?.name,
      cell: row => {
        return <>{row.inventoryType?.name} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Price</span>,
      id: 3,
      selector: row => row.price,
      right: true,
      cell: row => {
        return <>{formatCurrency(row.price)} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Wholesale Price</span>,
      id: 4,
      selector: row => row.wholesalePrice || 0,
      right: true,
      cell: row => {
        return <>{formatCurrency(row.wholesalePrice)}</>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Quantity</span>,
      id: 5,
      right: true,
      selector: row => row.quantity,
      cell: row => {
        return <>{row.quantity || 0}</>
      },
      sortable: true,
    },
    {
      name: <span className='th'>Actions</span>,
      id: 6,
      right: true,
      selector: row => row.guid,
      cell: row => {
        return <Actions item={row}/>
      },
      sortable: false,
    }
  ];

  const [columns, setColumns] = useState(_columns);

  useEffect(() => {
    getInventoryFn();
  }, []);

  const handleClose = () => {
    props.handleSave();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryFn = async () => {
    setInventoryLoading(true);

    getInventory().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventory(result);
        setInventoryLoading(false);
        setLoading(false);
      }
    })

  }

  const updateEventDates = (date) => {
    setEventDate(date);
    setEventEndDate(addDate(date, 1));
  }

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(eventDate)}
    </div>
  ));

  const handleRowClick = (row) => {
    inventory.map((_row) => {
      _row.selected = false;
    });
    row.selected = true;
    setInventory(inventory);
  }

  const conditionalRowStyles = [
    {
      when: row => row.selected,
      style: {
        backgroundColor: "#d7d5d5",
        userSelect: "none"
      }
    }
  ];

  const handleSort = (sorted, sortDir) => {
    setCookie('INVENTORY_SORT', sorted.id);
    setCookie('INVENTORY_SORT_DIR', sortDir);
  }

  const [searchCriteria, setSearchCriteria] = useState();

  const handleSearchChange = (e) => {
    setSearchCriteria(e.target.value);
  }
  const filteredResults = (items) => {
    if (!searchCriteria || searchCriteria.length === 0) {
      return items;
    }
    let _items = [];
    for (let j in items) {
      let nameCrit = items[j]?.name.toLowerCase();
      let descCrit = items[j]?.desc.toLowerCase();
      let match = nameCrit.match(searchCriteria.toLowerCase());
      let match2 = descCrit.match(searchCriteria.toLowerCase());
      if (match && match.length > 0 || match2 && match2.length > 0) {
        _items.push(items[j]);
      }
    }
    return _items;
  }

  return (
    <>
      <MDBox>

        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDInput type="text"
                       variant="outlined"
                       value={searchCriteria || ''}
                       onChange={handleSearchChange}
                       className="form-control mb-2" id="searchCriteria" aria-describedby="searchCriteria"
                       label="Search Inventory"/>
            </Grid>
            <Grid item xs={12} sm={6}>
              &nbsp;
              {!client && <MDButton
                onClick={() => {
                  props.addInventoryFn();
                }}
                color="info"
                size="small"
                className="pull-right"
                variant="contained">Add Inventory</MDButton>}
            </Grid>
          </Grid>
        </MDBox>

        <Divider/>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            {inventoryLoading && <Loading/>}

            {!inventoryLoading && <DataTable
              striped
              noDataComponent={<MDTypography variant={'p'} className={'cursor-default'}
                                             fontWeight={'light'}>No
                Inventory Found</MDTypography>}
              columns={columns}
              keyField={'guid'}
              data={filteredResults(inventory)}
              onSort={handleSort}
              highlightOnHover
              onRowClicked={(row) => {
                handleRowClick(row);
              }}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationPerPage={50}
              paginationComponent={BootyPagination}
              paginationTotalRows={totalRecords}
              onChangePage={handlePageChange}
              progressPending={loading}
              defaultSortFieldId={getCookie('INVENTORY_SORT') || 1}
              defaultSortAsc={getCookie('INVENTORY_SORT_DIR') === 'asc'}
              paginationComponentOptions={PaginationOptions}
            />}
          </Grid>
        </Grid>

      </MDBox>
    </>
  )
}