import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {createRef, useContext, useEffect, useState} from "react";
import {ButtonGroup, Card, CardContent} from "@mui/material";
import Meta from "../common/Meta";
import moment from "moment";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import CalendarPane from "../dashboard/CalendarPane";
import EventsMiniDataTable from "../client/pane/EventsMiniDataTable";
import {getCookie, setCookie} from "../common/appCookie";
import MDButton from "../../components/MDButton";

export default function Events(props) {

  const location = useLocation();

  const navigate = useNavigate();

  const [specialEvents, setSpecialEvents] = useState(props?.specialEvents);

  useEffect(() => {
    setSpecialEvents(props?.specialEvents);
  }, [props?.specialEvents]);

  const calendarRef = createRef();

  const [context, setContext] = useContext(AppContext);
  const [initialLoad, setInitialLoad] = useState(true);

  let startDate = new Date();
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);
  let endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 1);

  const DEFAULT_INVENTORY_REQUEST = {
    start: startDate,
    end: endDate
  }

  const [eventRequest, setEventRequest] = useState(DEFAULT_INVENTORY_REQUEST);

  const [calendarView, setCalendarView] = useState(getCookie('calendarView') || false);
  const toggleMonthView = () => {
    setCookie('calendarView', !calendarView, 1);
    setCalendarView(!calendarView);
  }

  const addInventoryEventFn = (eventDate) => {
    let formattedDate = '';
    if (eventDate) {
      formattedDate = '?initDate=' + moment(eventDate || new Date()).format('YYYY-MM-DD HH:mm Z');
    }
    navigate('/sales/create/' + (specialEvents ? 'party' : 'event') + formattedDate);
  }

  return (

    <DashboardLayout>
      <Meta pageTitle={specialEvents ? 'Parties' : 'Events'}/>
      <ContextSearchNavbar title={specialEvents ? 'Parties' : 'Events'}/>
      <MDBox py={3}>
        <MDBox mb={3}>
          <Card>
            <CardContent>

              <ButtonGroup
                           color="info"
                           variant="contained"
                           size="small"
                           aria-label="Basic button group" className={'pull-right'}>

                <MDButton
                  size="small"
                  key={'addinventory'}
                  onClick={(e) => {
                    addInventoryEventFn(new Date());
                  }}>
                  <span className={'fa fa-plus'}></span>&nbsp;Add New {specialEvents ? 'Party ' : 'Event'}
                </MDButton>

                <MDButton
                  size="small"
                  key={'toggleview'}
                  onClick={(e) => {
                    toggleMonthView();
                  }}>
                  <span className={'fa fa-calendar'}></span>&nbsp;{calendarView ? 'Grid' : 'Calendar'} View
                </MDButton>
              </ButtonGroup>
              {specialEvents && <>
                {!calendarView && <EventsMiniDataTable specialEvents={true}/>}
                {calendarView && <CalendarPane specialEvents={true}/>}
              </>}
              {!specialEvents && <>
                {!calendarView && <EventsMiniDataTable specialEvents={false}/>}
                {calendarView && <CalendarPane specialEvents={false}/>}
              </>}


            </CardContent>
          </Card>
        </MDBox>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}