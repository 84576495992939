import {useContext, useEffect, useState} from "react";

// react-router components
import {Link, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";

// Custom styles for ContextSearchNavbar
import {navbar, navbarContainer, navbarDesktopMenu, navbarRow,} from "./styles";

// Material Dashboard 2 PRO React context
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController,} from "../../context";
import {AppContext} from "../../lib/AppContext";
import MDTypography from "../../components/MDTypography";
import Drawer from "@mui/material/Drawer";
import {Box, InputAdornment} from "@mui/material";
import {searchContext} from "../../app/common/api/WebApi";
import useDebounce from "../../app/common/useDebounce";
import Divider from "@mui/material/Divider";

function ContextSearchNavbar({absolute, light, isMini, title}) {

  const [navbarType, setNavbarType] = useState();
  const [context, setContext] = useContext(AppContext);

  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [quickSearch, setQuickSearch] = useState('');
  const [quickSearchCancelled, setQuickSearchCancelled] = useState(false);
  const [quickSearchResults, setQuickSearchResults] = useState({});

  const [quickSearchOpen, setQuickSearchOpen] = useState(false);
  const [searchIndex, setSearchIndex] = useState(1);
  const [tmpResults, setTmpResults] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);


  const escapePressListener = (event) => {
    // if event key code is enter key
    if (event.keyCode === 27) {
      event.preventDefault();
      event.stopPropagation();
      setQuickSearch('');
      setQuickSearchResults({});
      setQuickSearchCancelled(true);
      setQuickSearchOpen(false);
    } else if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      if (!quickSearch) {
        setQuickSearch("*");
      }
      setQuickSearchOpen(true);
    }
  }

  const getSearchIndex = () => {
    return searchIndex;
  }

  useEffect(() => {
    let result = tmpResults;

    if ((result?.searchIndex + 1) === (getSearchIndex() + 1)) {
      if (result.error) {
        // todo: handle error
      } else {
        setSearchResults(result?.items);
        setLoading(false);
        setFirstLoad(false);
        setQuickSearchOpen(true);
      }
    }
  }, [tmpResults]);

  const [searchParams, setSearchParams] = useState({
    sortDir: 'asc',
    searchCriteria: quickSearch,
    sortOrder: '',
    pageSize: 10,
    startPage: 0,
    searchDeleted: false
  });

  const searchContextFn = async (searchCriteria) => {

    let idx = searchIndex + 1;
    setSearchIndex(idx);

    let _search = searchParams;
    _search.searchCriteria = searchCriteria;
    let result = await searchContext(_search, idx);

    setTmpResults(result);

  };

  const debounceChanges = useDebounce(function (newChanges) {
    let result = searchContextFn(newChanges);
  }, 500, []); // every .5 seconds max

  useEffect(() => {
    if (!quickSearch) {
      let _c = {...context};
      _c.contextSearchActive = false;
      setContext(_c);
      return;
    }
    debounceChanges(quickSearch);
  }, [quickSearch]);

  const keyPressListener = (event) => {
    if (event.keyCode === 27) {
      if (quickSearchOpen) {
        setQuickSearchOpen(false);
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener('keydown', keyPressListener);
    return () => {
      document.body.removeEventListener('keydown', keyPressListener);
    };
  }, [keyPressListener]);

  useEffect(() => {
    let _c = {...context};
    _c.contextSearchActive = quickSearchOpen;
    setContext(_c);
  }, [quickSearchOpen]);

  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const [hoverNavItem, setHoverNavItem] = useState(false);

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
            <IconButton sx={navbarDesktopMenu}
                        onMouseOver={()=>{
                          setHoverNavItem(true)
                        }}
                        onMouseOut={()=> {
                          setHoverNavItem(false);
                        }}
                        onClick={handleMiniSidenav} size="small" disableRipple>
              <Icon fontSize="medium" sx={iconsStyle}>
                {hoverNavItem ? "menu_open" : (miniSidenav ? "menu_open" : "menu")}
              </Icon>
            </IconButton>
            <MDBox>
              <MDTypography variant="h5" fontWeight="light" color="inherit">
                {title || ''}
              </MDTypography>
            </MDBox>

          </MDBox>
          {isMini ? null : (
            <MDBox
              sx={(theme) => navbarRow(theme, {isMini})}>

              <MDBox pl={1} ml={2}>
                <MDInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"
                                      className={'cursor-pointer'}
                                      onClick={(e) => {
                                        let _c = {...context};
                                        _c.contextSearchActive = true;
                                        setContext(_c);
                                        setQuickSearch('*');
                                        setQuickSearchOpen(true);
                                      }}>
                        <Icon>
                          search
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                  label="Search here"
                  value={quickSearch || ''}
                  autoComplete={'off'}
                  onKeyDown={escapePressListener}
                  onFocus={(e) => {
                    let _c = {...context};
                    _c.contextSearchActive = true;
                    setContext(_c);
                  }}
                  onChange={(e) => {
                    setQuickSearch(e.target.value);
                  }}/>
              </MDBox>

            </MDBox>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: 360,
          padding: '20px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {width: 360, padding: '20px', boxSizing: 'border-box'},
        }}
        onClose={(e) => {
          setQuickSearchOpen(false);
        }}
        open={quickSearchOpen}
        anchor={'right'}
        variant={'temporary'}>
        <Box>
          <IconButton
            size="small"
            className={'pull-right'}
            disableRipple
            color="inherit"
            onClick={(e) => {
              setQuickSearchOpen(false);
            }}
          >
            <Icon>close</Icon>
          </IconButton>
          <MDBox>
            <MDInput
              label="Search here"
              value={quickSearch || ''}
              onKeyDown={escapePressListener}
              onFocus={(e) => {
                let _c = {...context};
                _c.contextSearchActive = true;
                setContext(_c);
              }}
              onChange={(e) => {
                setQuickSearch(e.target.value);
              }}/>
          </MDBox>
          <MDBox>
            <Divider/>
            <MDTypography variant="h5" fontWeight="light" color="inherit">
              Customers
            </MDTypography>
            <ul className={'list-group'}>

              {searchResults?.members?.map((member, index) => {
                return (
                  <li
                    className={'list-group-item'}
                    key={index}>
                    <Link to={'/client/' + member.guid}
                          className={'link'}
                          onClick={(e) => {
                            setQuickSearchOpen(false);
                          }}>{member.firstName} {member.lastName}</Link>
                  </li>
                );
              })}
              <li className={'list-group-item '}>
                <Link
                  onClick={()=> {
                    setQuickSearchOpen(false);
                  }}
                  to={'/customers/list-customers/?q=' + quickSearch}
                  className={'link'}>
                  View All...
                </Link>
              </li>
            </ul>
          </MDBox>
        </Box>
      </Drawer>

    </>
  );
}

// Typechecking props for the ContextSearchNavbar
ContextSearchNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default ContextSearchNavbar;
