import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {addDate, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {parseISO} from "date-fns";
import MDButton from "../../components/MDButton";
import {DateCalendar, TimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function ManageTimeEntryModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [timeEntry, setTimeEntry] = useState(props.timeEntry);
  const [timeEntryId, setTimeEntryId] = useState(props.timeEntry?.id);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [eventDate, setEventDate] = useState(new Date());

  useEffect(() => {
    if (props.timeEntry) {
      setTimeEntryId(props.timeEntry?.id);
      if (typeof props.timeEntry?.startDate === 'string') {
        setStartDate(parseISO(props?.timeEntry?.startDate));
        setEventDate(parseISO(props?.timeEntry?.startDate));
      } else {
        setStartDate(props?.timeEntry?.startDate);
        setEventDate(props?.timeEntry?.startDate);
      }
      if (typeof props.timeEntry?.endDate === 'string') {
        setEndDate(parseISO(props?.timeEntry?.endDate));
      } else {
        setEndDate(props?.timeEntry?.endDate);
      }
    }
  }, [props?.timeEntry]);

  const [isNew, setIsNew] = useState(!!!props.timeEntry?.id);
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(timeEntry)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    let _timeEntry = {
      startDate: startDate,
      endDate: endDate,
      timeEntryId: timeEntryId
    }
    props.handleSave(_timeEntry);
  };

  const updateEventDates = (date) => {
    setEventDate(date);
    setStartDate(date);
    setEndDate(date);
  }

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{timeEntryId ? 'Manage ' : 'New '}Time Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={6}>
                    <DateCalendar
                      views={['day', 'month']}
                      maxDate={dayjs()}
                      minDate={dayjs(addDate(new Date(), -7))}
                      value={dayjs(startDate)}
                      onChange={(e, v) => {
                        updateEventDates(e.$d)
                      }}
                    />
                  </Col>

                  <Col className={'col'} sm={6} xs={6}>
                    <div className="form-group">

                      <div className={'input-group d-block mt-4'}>
                        <TimePicker
                          label="Clock In"
                          value={dayjs(startDate)}
                          onChange={(d) => setStartDate(d?.$d)}
                        />
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <div className={'input-group d-block'}>
                        <TimePicker
                          label="Clock Out"
                          value={dayjs(endDate)}
                          onChange={(d) => setEndDate(d?.$d)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}