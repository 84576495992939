import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import './app/App.scss';
import {MaterialUIControllerProvider} from "./context";
import {Provider} from "./lib/AppContext";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider>
          <App/>
        </Provider>
      </LocalizationProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
