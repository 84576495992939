import {Col, Container, Modal, Row} from "react-bootstrap";
import React from "react";
import MDButton from "../../components/MDButton";
import {Chip} from "@mui/material";
import {formatDate, formatDateTime} from "../common/Utils";
import Divider from "@mui/material/Divider";

export default function ClientNoteFlagsModal({show, client, notes, handleClose}) {


  return (
    <Modal onHide={handleClose} size={'lg'} show={show}>

      <Modal.Header closeButton>
        <Modal.Title>Client Flags</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className={'col col-main'} xs={12}>
            <Container>
              {notes?.filter(note => note.noteFlag).map((note, idx) => {
                return (
                  <Row index={idx} key={idx}>
                    <Col className={'col'} sm={12} xs={12}>
                      <p>
                        {note?.note}
                      </p>
                      <div>
                        <Chip
                          label={<>
                            {!note.pinned ?
                              <span
                                style={{
                                  transform: 'rotate(15deg)'
                                }}
                                className="font-color-grey fa fa-thumbtack"></span>
                              :
                              <span
                                className="font-color-gold fa fa-thumbtack"></span>
                            }</>}
                          variant="outlined"
                        />
                        &nbsp;
                        <Chip
                          label={<>By: {note.createUser.firstName + ' ' + note.createUser.lastName} On: {formatDateTime(note.createDate)}</>}
                          variant="outlined"
                        />&nbsp;
                        {note.reminderNote && <>
                          <Chip
                            label={<>Reminder Date: {formatDate(note.reminderDate)}</>}
                            variant="outlined"
                          />&nbsp;
                        </>
                        }
                        <Divider />
                      </div>

                    </Col>
                  </Row>
                )
              })}
            </Container>
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <MDButton type="submit" color="info" onClick={handleClose}>
          Close
        </MDButton>
      </Modal.Footer>
    </Modal>
  );
}