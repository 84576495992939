import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe,} from '@stripe/react-stripe-js';
import MDButton from "../../../components/MDButton";
import {Modal} from "react-bootstrap";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const clientSecret = props.clientSecret;
  const isClientCart = props?.client?.guid?.length > 0;

  const clientSuccessPart = isClientCart ? '/client/' + props.client.guid : '';
  const successUrl = window.location.protocol + '//' + window.location.host + clientSuccessPart;

  const [errorMessage, setErrorMessage] = useState(null);
  const [checkoutActive, setCheckoutActive] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    setCheckoutActive(true);

    const resp = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: successUrl,
      },
    });

    if (resp) {
      if (resp?.error) {
        props.handleError(resp);
      } else {
        console.dir(resp);
        props.handleSuccess(resp);
      }
      setCheckoutActive(false);
    }
  };

  return (
    <>

      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <PaymentElement/>
          {errorMessage && <div>{errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>

          <MDButton variant="gradient"
                  disabled={!stripe || !elements || checkoutActive}
                  type="submit"
                  color="info">
            Complete Purchase
          </MDButton>
        </Modal.Footer>
      </form>
    </>
  );
}

