import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {displayPhone, formDate, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {getFamilyMemberTypes} from "../common/api/WebApi";
import MDInput from "../../components/MDInput";
import {NativeSelect} from "@mui/material";
import MDButton from "../../components/MDButton";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

export default function ManageFamilyMemberModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [member, setMember] = useState(props.familyMember);
  const [emergencyContact, setEmergencyContact] = useState(props.familyMember?.emergencyContact);
  const [familyMemberTypes, setFamilyMemberTypes] = useState([]);
  const [typesReady, setTypesReady] = useState(false);
  const [dob, setDob] = useState();

  useEffect(()=> {
   if (props?.familyMember?.dob) {
     setDob(formDate(props?.familyMember?.dob));
   }
  },[props?.familyMember?.dob]);

  useEffect(()=> {
    let _dob = {...dob};
    try {
      let dd = new Date(dob);
      _dob = dd;
      let _member = {...member};
      _member.dob = dd;
      setMember(_member);
    } catch (err) {
      console.error(err);
    }
  },[dob]);

  useEffect(() => {
    setMember(props?.familyMember);
  }, [props?.familyMember]);

  const [isNew, setIsNew] = useState(!!!props.familyMember?.guid);
  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(() => {
    getFamilyMemberTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setFamilyMemberTypes(result);
        setTypesReady(true);
      }
    });
  }, [props.show]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(member)
  });

  const handleCloseFamilyMember = () => {
    props.handleCloseFamilyMember();
    setMember(null);
  };

  const handleSaveFamilyMember = (data) => {
    props.handleSaveFamilyMember(data);
    setMember(null);
  };

  const toggleEmergencyContact = (e) => {
    setEmergencyContact(e.target.checked);
  };

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <MDInput
        autoComplete={'off'}
        variant="outlined" type="text"
        className="form-control" id="dob"
        value={value}
        label="Enter Birth Date" />
    </div>
  ));

  return (
    <Modal onHide={handleCloseFamilyMember} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSaveFamilyMember)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Family Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <MDInput
                        variant="outlined" type="text" required
                             {...register('firstName')}
                             className="form-control" id="firstName"
                        label="Enter First name"/>
                    </div>
                  </Col>

                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <MDInput
                        variant="outlined" type="text" required
                             {...register('lastName')}
                             className="form-control" id="lastName"
                             label="Enter Last name"/>
                    </div>
                  </Col>
                </Row>


                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <label htmlFor="state">Family Member Type</label>
                      {typesReady && <NativeSelect
                        id={'familyMemberType'}
                        variant="outlined"
                        name={'familyMemberType'} className={'form-control'}
                        {...register('familyMemberType.id')} >
                        {
                          familyMemberTypes.map((c) =>
                            <option value={c.id} key={c.id}>
                              {c.name}
                            </option>)
                        }
                      </NativeSelect>}
                    </div>
                  </Col>

                  <Col className={'col'} sm={4} xs={8}>

                    <div className="form-group">
                      <label htmlFor="dob">Birth Date</label>
                      <div className={'input-group d-block'}>
                        <DatePicker
                          maxDate={dayjs()}
                          value={dob ? dayjs(dob) : null}
                          onChange={(e) => {
                            setDob(e?.$d || null);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={6}>

                    <label htmlFor="emergencyContact" className={'mt-4'}>
                      <input type="checkbox"
                             {...register('emergencyContact',
                             {
                               onChange: (e) => {
                               toggleEmergencyContact(e);
                             }
                             })}
                             id="emergencyContact"  />
                      &nbsp;Emergency Contact
                    </label>
                  </Col>
                </Row>
                {emergencyContact &&
                <Row className={'my-2'}>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Emergency Phone</label>
                      <MDInput
                        variant="outlined"  type="text"
                              {...register('phoneNumber', {
                                onChange: (e) => {
                                  e.target.value = displayPhone(e.target.value);
                                }
                              })}
                              className="form-control" id="phoneNumber"
                              label="Enter Emergency Phone"/>
                    </div>
                  </Col>
                </Row>
                }

              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}