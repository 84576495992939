import {useEffect, useState} from "react";
import CheckoutForm from "./common/stripe/CheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {lookupClientSecret} from "./common/api/CartApi";

export default function CheckoutPage({client, handleSuccess, handleError}) {

  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    lookupClientSecret(client).then((result) => {
      setClientSecret(result['clientSecret']);
      setStripePromise(loadStripe(result['publishableKey']));
    });
  }, []);

  return (
    <div>
      {clientSecret &&
        <Elements
          options={{
            clientSecret: clientSecret
          }}
          stripe={stripePromise}>
          <CheckoutForm
            handleSuccess={handleSuccess}
            handleError={handleError}
            client={client}
            clientSecret={clientSecret}/>
        </Elements>
      }
    </div>
  );
}