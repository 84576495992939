import React, {useEffect, useState} from "react";
import MDInput from "../../../components/MDInput";
import {Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

export default function CartFamilyMembers({cart, callback}) {

  const [formValid, setFormValid] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [dob, setDob] = useState();

  const checkFormValid = () => {
    let isValid = firstName && lastName && dob;
    setFormValid(isValid);
    let form = {
      firstName: firstName,
      lastName: lastName,
      dob: dob,
      familyMemberType: {id: 1},
      valid: isValid
    }
    callback(form);
  }

  useEffect(() => {
    checkFormValid();
  }, [firstName, lastName, dob]);

  return (
    <>
      <div>
        <MDBox>
          <Grid container p={1}>
            <Grid item xs={12} sm={6} p={1}>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <MDInput
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                  variant="outlined" type="text" required
                  name="firstName"
                  autoComplete="off"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First name"/>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} p={1}>
              <div className="form-group">
                <label htmlFor="lastName">Last Name </label>
                <MDInput
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                  autoComplete="off"
                  variant="outlined" type="text" required
                  name="lastName"
                  className="form-control" id="lastName"
                  placeholder="Enter Last name"/>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} p={1}>
              <div className="form-group">
                <label htmlFor="dob">Birthday</label>
                <div>
                  <DatePicker
                    value={dayjs(dob)}
                    maxDate={dayjs()}
                    onChange={(e) => {
                      setDob(e?.$d || null);
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </MDBox>
      </div>
    </>
  )
}