import {useEffect, useState} from "react";
import {getCustomerIntentSuccess} from "../../common/api/WebApi";
import ModalLoading from "../ModalLoading";
import MDTypography from "../../../components/MDTypography";


export default function CartSuccess({clientGuid, paymentId, handleSuccess}) {

  const [searchTimer, setSearchTimer] = useState(1);
  const [salesReady, setSalesReady] = useState(false);
  const [salesResp, setSalesResp] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentId) {
        if (searchTimer > 1) {
          if (searchTimer < 30) {
            getSalesFn(clientGuid, paymentId);
          } else {
            // navigate('#/cx/my-purchases');
          }
        }
      }
    }, 1000);
    return () => clearTimeout(searchTimer);
  }, [searchTimer]);

  const getSalesFn = async (clientGuid, paymentId) => {
    let result = await getCustomerIntentSuccess(clientGuid, paymentId);
    if (result) {
      if (result?.salesItems?.length > 0) {
        setSalesResp(result);
        setSalesReady(true);
        handleSuccess('CHECKOUT_SUCCESS_CONFIRMATION')
      } else {
        setSearchTimer(searchTimer + 1);
      }
    }
  }

  useEffect(() => {
    if (clientGuid && paymentId) {
      getSalesFn(clientGuid, paymentId);
    }
  }, [clientGuid, paymentId]);

  return (
    <div>
      {!salesReady && <><MDTypography variant="h5" color="secondary" fontWeight="light"
                                      className={'text-center'}>
        Hang tight, finalizing Purchase
      </MDTypography><ModalLoading/></>}
      {salesReady && <><MDTypography variant="h5" color="secondary" fontWeight="light"
                                     className={'text-center'}>
        Payment complete!
      </MDTypography>

      </>}
    </div>

  );
}