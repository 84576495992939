import {Col, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import {getMyCompanyEmployees, saveEmployee} from "../common/api/WebApi";
import {Card, Link} from "@mui/material";
import ManageEmployeeModal from "../employee/ManageEmployee";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import Icon from "@mui/material/Icon";
import {useNavigate} from "react-router-dom";

export default function Employees(props) {
  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);

  const [saving, setSaving] = useState(false);
  const [manageEmployee, setManageEmployee] = useState(false);
  const [employee, setEmployee] = useState({});
  const [employees, setEmployees] = useState([]);


  useEffect(() => {

    getCompanyEmployeesFn().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEmployees(result);
      }
    });
  }, []);

  const getCompanyEmployeesFn = async () => {
    return await getMyCompanyEmployees();
  }

  const handleCloseEmployee = () => {
    setEmployee({});
    setManageEmployee(false);
  };

  const handleSaveEmployee = async (employee) => {
    setSaving(true);

    let result = await saveEmployee(employee);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setManageEmployee(false);
      getCompanyEmployeesFn().then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          setEmployees(result);
        }
      });
    }
  };

  const startManageEmployeeFn = (e) => {
    if (!e.guid) {
      e.newEmployee = true;
    }
    setEmployee(e);
    setManageEmployee(true);
  };

  const myRefs = useRef([]);
  const [renderIndex, setRenderIndex] = useState(1);

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };
  return (
    <DashboardLayout>

      <Meta pageTitle={'Employees'}/>

      <ContextSearchNavbar title={'Employees'}/>
      <MDBox py={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
              &nbsp;
              <MDButton
                onClick={(e) => {
                  setManageEmployee(true);
                  startManageEmployeeFn({});
                }}
                color="secondary"
                size="small"
                className={'pull-right'}>
                <span className={'fa fa-plus'}></span>&nbsp;
                Add Employee</MDButton>
            </MDTypography>
            <ul className={'list-group'}>
              {employees?.length > 0 && employees?.map((employee, index) => {
                  return (
                    <li
                      className={'list-group-item hover-shadow'}
                      key={index}>
                      <Row>
                        <Col sm={12}>
                          <Link href={'/company/employees/view/' + employee.guid}>
                            {employee.firstName} {employee.lastName}
                          </Link>

                          <div className={'pull-right px-2'}
                               onClick={(e) => {
                                 refHandler('employees' + index)
                               }}
                               ref={el => (myRefs.current['employees' + index] = el)}>

                            <MDButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              circular
                              iconOnly
                            >
                              <Icon>more_horiz</Icon>
                            </MDButton>

                            {renderIndex && myRefs.current['employees' + index]?.visible &&
                              <Menu open={true} anchorReference={null}
                                    anchorEl={myRefs.current['employees' + index]}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}>
                                <NotificationItem
                                  onClick={(e) => {
                                    navigate('/company/employees/view/' + employee.guid);
                                  }} icon={<Icon>person</Icon>} title="View"/>
                                <NotificationItem
                                  onClick={(e) => {
                                    startManageEmployeeFn(employee)
                                  }} icon={<Icon>edit</Icon>} title="Edit"/>
                                <NotificationItem onClick={(e) => {
                                  // preDeleteFamilyMemberFn(account)
                                }} icon={<Icon>delete</Icon>} title="Delete"/>
                              </Menu>
                            }
                          </div>
                        </Col>

                      </Row>
                    </li>
                  )
                }
              )}
            </ul>
          </MDBox>
        </Card>
      </MDBox>
      {
        <>

          {employee && <ManageEmployeeModal
            employee={employee}
            show={manageEmployee}
            saving={saving}
            handleSaveEmployee={handleSaveEmployee}
            handleClose={handleCloseEmployee}/>
          }

        </>
      }

      <Footer mt={5}/>

    </DashboardLayout>
  );
}