import {useContext, useEffect, useState} from "react";
import logo from '../assets/images/psb-logo-2024-full.png';

// react-router-dom components
import {NavLink, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setMiniWindow,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "../../context";
import {AppContext} from "../../lib/AppContext";
import {getCookie} from "../common/appCookie";
import {CONSTANTS} from "../common/constants";

function Sidenav({color, brand, brandName, routes, ...rest}) {

  const [context, setContext] = useContext(AppContext);
  const [collapse, setCollapse] = useState(false);

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();

  useEffect(() => {
    setCollapse(controller.miniSidenav);
  }, [controller]);

  const {miniSidenav, miniWindow, transparentSidenav, whiteSidenav, darkMode} = controller;
  const location = useLocation();
  const {pathname} = location;
  const collapseName = pathname.split("/").slice(1).join('-');
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniWindow(dispatch, window.innerWidth < 1200);
      setMiniSidenav(dispatch, window.innerWidth < 1200 ? true : (getCookie(CONSTANTS.MINI_SIDE_NAV) || false));
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
     */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoute = (route) => {
    if (!route?.userTypes?.length) {
      return true;
    }
    if (route.userTypes.indexOf(context?.sessionUser?.userTypeId) > -1) {
      return true;
    }
    const adminRoutes = ['admin-item', 'drop-identity']
    // Super Admin Menu Items
    if (context?.sessionUser?.superAdmin) {
      if (adminRoutes.indexOf(route.key) > -1) {
        return true;
      }
    }

    return false;
  }
  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({name, route, key, href}) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{textDecoration: "none"}}
        >
          <SidenavItem name={name} nested/>
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
          <SidenavItem name={name} active={route === pathname} nested/>
        </NavLink>
      )
    );

    return template;
  };


  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({name, collapse, route, userTypes, href, key, clickHandler}) => {
      let returnValue;

      let _r = {
        route: route,
        key: key,
        userTypes: userTypes || []
      }
      if (!renderRoute(_r)) {
        return null;
      }

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({currentTarget}) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            onClick={clickHandler}
            target="_blank"
            rel="noreferrer"
            sx={{textDecoration: "none"}}
          >
            <SidenavItem color={color} name={name} active={key === itemName}/>
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
            <SidenavItem color={color} name={name} active={key === itemName}/>
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({type, name, icon, title, collapse, noCollapse, key, href, route, userTypes, clickHandler}) => {
      let returnValue;
      let _r = {
        route: route,
        key: key,
        userTypes: userTypes || []
      }
      if (!renderRoute(_r)) {
        return null;
      }

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              onClick={clickHandler}
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{textDecoration: "none"}}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                clickHandler={clickHandler}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                onClick={clickHandler}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              clickHandler={clickHandler}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <>

      <SidenavRoot
        {...rest}
        style={{
          overflow: collapse ? 'hidden' : 'auto'
        }}
        variant="permanent"
        ownerState={{transparentSidenav, whiteSidenav, miniSidenav, darkMode}}
      >
        {!context?.sessionUser?.guid && <>
          <MDBox
            component={NavLink} to="/" display="flex" alignItems="center">
            {brand && <img
              style={{
                width: '75%',
                height: 'auto',
                margin: '20px auto'
              }}
              src={logo} className={'logo'}/>}
          </MDBox>
        </>}
        <MDBox
          display={{xs: "block", xl: "none"}}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{cursor: "pointer"}}
        >
          <MDTypography variant="h6" color="white">
            <span className="fa fa-times"></span>
          </MDTypography>
        </MDBox>

        {context?.sessionUser?.guid && <>
          <MDBox pt={1} pb={1} px={4} textAlign="center">
            <MDBox
              display={{xs: "block", xl: "none"}}
              position="absolute"
              top={0}
              right={0}
              p={1.625}
              onClick={closeSidenav}
              sx={{cursor: "pointer"}}
            >

            </MDBox>
            <MDBox component={NavLink} to="/" display="flex" alignItems="center">
              <MDBox
                width={"100%"}
                sx={(theme) => sidenavLogoLabel(theme, {miniSidenav})}
              >
              </MDBox>
            </MDBox>
          </MDBox>
        </>}

        <List>{renderRoutes}</List>

      </SidenavRoot>
    </>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
