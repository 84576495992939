import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import {getCompanyTimecard, getMyCompanyEmployees} from "../common/api/WebApi";
import {Card, Divider, Grid} from "@mui/material";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import MDTypography from "../../components/MDTypography";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {CONSTANTS} from "../common/constants";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";
import Loading from "../common/Loading";
import DataTable from "react-data-table-component";
import BootyPagination from "../common/bootyPagination";
import {getCookie, setCookie} from "../common/appCookie";
import * as pagination from "../common/pagination";
import {formatDateTime} from "../common/Utils";

export default function Timesheets(props) {
  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);

  const [saving, setSaving] = useState(false);
  const [manageEmployee, setManageEmployee] = useState(false);
  const [employee, setEmployee] = useState({});
  const [employees, setEmployees] = useState([]);
  const [timecards, setTimecards] = useState([]);
  const [timecardsLoading, setTimecardsLoading] = useState(true);

  const [timeRequest, setTimeRequest] = useState();

  const [timeEntryOptions, setTimeEntryOptions] = useState([]);
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  useEffect(() => {
    let _options = [];
    let day = new Date();
    day.setHours(0, 0, 0, 0);

    for (let i = 0; i < 4; i++) {
      let currentDate = moment(day);
      let weekStart = currentDate.clone().startOf('week');
      weekStart.add(-7 * i, 'days');
      _options.push(
        {
          label: 'Week of ' + weekStart.format(CONSTANTS.DISPLAY_DATE_FORMAT),
          value: weekStart.format('YYYY-MM-DD'),
          type: 'week'
        }
      )
    }

    for (let i = 0; i < 12; i++) {
      let currentDate = moment(day);
      let weekStart = currentDate.clone().startOf('month');
      weekStart.add(-1 * i, 'months');
      _options.push(
        {
          label: 'Month of ' + weekStart.format(CONSTANTS.DISPLAY_DATE_FORMAT),
          value: weekStart.format('YYYY-MM-DD'),
          type: 'month'
        }
      )
    }

    setTimeEntryOptions(_options);
    setTimeRequest(_options[0]);

  }, []);

  const totalTime = (input) => {
    let start = moment(input.startDate);
    let end = moment(input.endDate);
    let duration = moment.duration(end.diff(start));
    return duration.asMinutes();
  }

  const displayTime = (input) => {
    let start = moment(input.startDate);
    let end = moment(input.endDate);
    let duration = moment.duration(end.diff(start));
    let minutes = duration.asMinutes();

    let hours = Math.floor(minutes / 60);
    let hourMins = minutes % 60;
    return hours + ' Hours' + (hourMins > 0 ? ' ' + hourMins + ' mins' : '');
  }

  const getCompanyTimecardFn = () => {
    let _req = {};

    if (!timeRequest?.type) {
      return;
    }

    if (timeRequest.type === 'week') {
      let startOfWeek = moment(timeRequest.value).startOf('week');
      let endOfWeek = moment(timeRequest.value).endOf('week');
      _req.startDate = startOfWeek.toDate();
      _req.endDate = endOfWeek.toDate();
    } else if (timeRequest.type === 'month') {
      let startOfMonth = moment(timeRequest.value).startOf('month');
      let endOfMonth = moment(timeRequest.value).endOf('month');
      _req.startDate = startOfMonth.toDate();
      _req.endDate = endOfMonth.toDate();
    }

    setTimecardsLoading(true);
    getCompanyTimecard(_req).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTimecards(result?.timecardEntryList);
        setTimecardsLoading(false);
      }
    });
  }

  useEffect(() => {
    getCompanyTimecardFn();
  }, [timeRequest]);

  const getCompanyEmployeesFn = async () => {
    return await getMyCompanyEmployees();
  }

  const myRefs = useRef([]);
  const [renderIndex, setRenderIndex] = useState(1);

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };


  const _columns = [
    {
      name: <span className='th'>Employee</span>,
      id: 1,
      selector: row => row.employee?.lastName?.toUpperCase(),
      cell: row => {
        let link = '/company/employees/view/' + row.employee?.guid;
        return <Link to={link} className="link">{row.employee?.lastName + ', ' + row.employee?.firstName}</Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Start</span>,
      id: 2,
      selector: row => row.startDate,
      cell: row => {
        return <>{formatDateTime(row.startDate)} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>End</span>,
      id: 3,
      selector: row => row.endDate,
      cell: row => {
        return <>{formatDateTime(row.endDate)} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Total</span>,
      id: 4,
      selector: row => totalTime(row),
      cell: row => {
        return <>{displayTime(row)} </>;
      },
      sortable: true,
    },
  ];

  const [columns, setColumns] = useState(_columns);

  const handleSort = (sorted, sortDir) => {
    setCookie('TIMECARD_SORT', sorted.id);
    setCookie('TIMECARD_SORT_DIR', sortDir);
  }

  const exportCsv = () => {
    let d = [...timecards];
    let filename = 'Timesheet-Export-' + timeRequest?.label?.replaceAll(' ', '-') + '.csv';

    let rows = [];
    let _dd = [];
    _dd.push("Employee Last Name");
    _dd.push("Employee First Name");
    _dd.push("Reference Id");
    _dd.push("Start");
    _dd.push("End");
    _dd.push("Total");

    rows.push(_dd);

    for (let j in d) {
      let _dd = [];
      _dd.push(d[j].employee?.lastName);
      _dd.push(d[j].employee?.firstName);
      _dd.push(d[j].employee?.guid);
      _dd.push(formatDateTime(d[j].startDate));
      _dd.push(formatDateTime(d[j].endDate));
      _dd.push(displayTime(d[j]));

      rows.push(_dd);
    }

    let data = rows.map(row =>
      row
        .map(String)  // convert every value to String
        .map(v => v.replaceAll('"', '""'))  // escape double quotes
        .map(v => `"${v}"`)  // quote it
        .join(',')  // comma-separated
    ).join('\r\n');

    var blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }


  return (
    <DashboardLayout>

      <Meta pageTitle={'Time Sheets'}/>

      <ContextSearchNavbar title={'Time Sheets'}/>
      <MDBox py={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
              <MDButton
                variant="outlined"
                color="secondary"
                size="small"
                ref={el => (myRefs.current['timeEntryOptions'] = el)}
                onClick={(e) => {
                  refHandler('timeEntryOptions')
                }}>

                {timeRequest?.label}

                &nbsp;<span className={'fa fa-caret-down ml-2 mt-n1'}/>

                {renderIndex && myRefs.current['timeEntryOptions']?.visible &&
                  <Menu open={true} anchorReference={null} anchorEl={myRefs.current['timeEntryOptions']}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}>
                    {timeEntryOptions.map((option, index) => {
                      return (
                        <NotificationItem key={index}
                                          onClick={(e) => {
                                            setTimeRequest(option)
                                          }} icon={
                          <Icon>{option.type === 'month' ? 'calendar_month' : 'access_time'}</Icon>}
                                          title={option.label}
                        />
                      )
                    })}
                  </Menu>
                }

              </MDButton>
            </MDTypography>

            <MDButton
              className="pull-right"
              color="secondary"
              size="small"
              onClick={exportCsv}><span className="fa fa-file-excel"></span>&nbsp;Export</MDButton>

            <Divider/>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                {timecardsLoading && <Loading/>}

                {!timecardsLoading && <DataTable
                  striped
                  noDataComponent={<MDTypography variant={'p'} className={'cursor-default'}
                                                 fontWeight={'light'}>No
                    Time Entries Found</MDTypography>}
                  columns={columns}
                  keyField={'guid'}
                  data={timecards}
                  onSort={handleSort}
                  highlightOnHover
                  onRowClicked={(row) => {
                    // handleRowClick(row);
                  }}
                  // pagination
                  // paginationPerPage={50}
                  paginationComponent={BootyPagination}
                  paginationTotalRows={timecards.length}
                  // onChangePage={handlePageChange}
                  progressPending={timecardsLoading}
                  defaultSortFieldId={getCookie('TIMECARD_SORT') || 2}
                  defaultSortAsc={getCookie('TIMECARD_SORT_DIR') === 'asc'}
                  paginationComponentOptions={PaginationOptions}
                />}
              </Grid>
            </Grid>

          </MDBox>
        </Card>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}