import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {getStates, searchUsername} from "../common/api/WebApi";
import {displayPhone, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDInput from "../../components/MDInput";
import {NativeSelect} from "@mui/material";
import MDButton from "../../components/MDButton";
import useDebounce from "../common/useDebounce";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

export default function ManageEmployeeModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [employee, setEmployee] = useState(props?.employee);

  const [newEmployee, setNewEmployee] = useState(false);
  const [states, setStates] = useState([]);
  const [formReady, setFormReady] = useState(false);
  const [employeeStartDate, setEmployeeStartDate] = useState(null);
  const [searchIndex, setSearchIndex] = useState(1);
  const [usernameRequest, setUsernameRequest] = useState();
  const [usernameResponse, setUsernameResponse] = useState();
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwd, setPasswd] = useState(null);
  const [passwdConfirm, setPasswdConfirm] = useState(null);
  const [passMatch, setPassMatch] = useState(true);
  const [passValid, setPassValid] = useState(true);

  useEffect(()=>{
    setPasswd(null);
    setPasswdConfirm(null);
    setPassMatch(true);
    setPassValid(true);
  },[props]);

  useEffect(() => {
    setPassMatch(passwd === passwdConfirm);
    setPassValid((passwd?.length === 0 && passwdConfirm?.length === 0) || passwd === passwdConfirm);
  }, [passwd, passwdConfirm])

  useEffect(() => {
    setUsernameValid(false);
    setUsernameRequest(null);
  }, []);

  useEffect(() => {
    setEmployee(props?.employee);
    setUsernameValid(!props?.employee?.newEmployee);
    setNewEmployee(props?.employee?.newEmployee);
  }, [props?.employee]);

  const {
    register,
    handleSubmit,
    setValue, control, reset
  } = useForm({
    values: handleDefaultValues(employee)
  });

  const searchUsernameFn = async (searchCriteria) => {

    let idx = searchIndex + 1;
    setSearchIndex(idx);

    let _search = {...usernameRequest};
    _search.username = searchCriteria;

    let result = await searchUsername(_search, idx);

    setUsernameResponse(result?.resp);
  };

  useEffect(() => {
    if (usernameResponse) {
      setUsernameValid(usernameResponse?.valid);
      setUsernameRequest(usernameResponse?.username);
    }
  }, [usernameResponse]);

  const debounceChanges = useDebounce(function (newChanges) {
    let result = searchUsernameFn(newChanges);
  }, 500, []); // every .5 seconds max

  // use this for reference when editing a modal using form reset() 12/08/2023 - JP
  useEffect(() => {
    reset();
    if (props?.employee) {
      setEmployee(props?.employee);
      if (props?.employee?.employeeStartDate) {
        setEmployeeStartDate(new Date(props?.employee?.employeeStartDate));
      } else {
        setEmployeeStartDate(null);
      }
    }
  }, [props?.employee]);

  useEffect(() => {
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setStates(result);
        setFormReady(true);
      }
    });
  }, []);

  const handleClose = () => {
    props.handleClose();
  };

  const handleSaveEmployee = (data) => {
    let _e = {...data};
    _e.employeeStartDate = employee.employeeStartDate;
    _e.newEmployee = employee.newEmployee;
    props.handleSaveEmployee(_e);
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSaveEmployee)} id={'employeeForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{newEmployee ? 'Create New' : 'Manage'} Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">

                      <MDInput
                        variant="outlined" type="text"
                        {...register('firstName')}
                        autoComplete="off" required
                        className="form-control" id="firstName"
                        label="Enter First name"/>

                    </div>
                  </Col>

                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">

                      <MDInput
                        variant="outlined" type="text"
                        {...register('lastName')}
                        autoComplete="off" required
                        className="form-control" id="lastName"
                        label="Enter Last name"/>

                    </div>
                  </Col>
                </Row>
                <Row className={'my-4'}>
                  <Col className={'col'} md={6} sm={12} xs={12}>
                    <div className="form-group">

                      <MDInput
                        variant="outlined" type="email"
                        {...register('email')}
                        autoComplete="off" required
                        className="form-control" id="email"
                        label="Enter Email"/>

                    </div>
                  </Col>

                  <Col className={'col'} md={6} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        autoComplete="off"
                        {...register('primaryPhone', {
                          onChange: (e) => {
                            e.target.value = displayPhone(e.target.value);
                          }
                        })}
                        className="form-control" id="primaryPhone"
                        label="Enter Phone"/>
                    </div>
                  </Col>
                </Row>
                <Row className={'my-4'}>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        autoComplete="off"
                        {...register('address1')}
                        autoComplete="off"
                        className="form-control" id="address1"
                        label="Enter Address"/>
                    </div>
                  </Col>

                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        autoComplete="off"
                        {...register('address2')}
                        className="form-control" id="address2"
                        label="Enter Address 2"/>
                    </div>
                  </Col>
                </Row>

                <Row className={'my-4'}>
                  <Col className={'col'} sm={4} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        autoComplete="off"
                        {...register('city')}
                        className="form-control" id="city"
                        label="Enter City"/>
                    </div>
                  </Col>

                  <Col className={'col'} sm={4} xs={12}>
                    <div className="form-group">
                      {states?.length && <NativeSelect
                        variant="outlined"
                        id={'state'} name={'state'} className={'form-control'}
                        {...register('state.stateId')}
                        defaultValue={states[0].stateId}>
                        {
                          states.map((c) =>
                            <option value={c.stateId} key={c.stateId}>
                              {c.stateName}
                            </option>)
                        }
                      </NativeSelect>}
                    </div>
                  </Col>

                  <Col className={'col'} sm={4} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        autoComplete="off"
                        {...register('zipCode')}
                        className="form-control" id="zipCode"
                        label="Enter Zip"/>
                    </div>
                  </Col>
                </Row>
                <Row className={'my-4'}>
                  <Col className={'col'} sm={4} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="text"
                        {...register('username')}
                        autoComplete="off" required
                        disabled={!newEmployee}
                        className="form-control" id="username"
                        label="Enter Username" onChange={(e) => {
                        debounceChanges(e.target.value)
                      }}/>
                      {!usernameValid && <span className={'text-danger'}>
                        {usernameRequest?.length && <span>Username in use</span>}
                        {!usernameRequest?.length && <span>Username required</span>}
                      </span>
                      }
                    </div>
                  </Col>

                  <Col className={'col'} sm={4} xs={12}>
                    <div className="form-group">

                      <MDInput
                        variant="outlined" type="password"
                        {...register('passwd', {
                          onChange: (e) => {
                            setPasswd(e.target.value)
                          }
                        })}
                        autoComplete="off"
                        className="form-control" id="passwd"
                        label={newEmployee ? 'Enter Password' : 'Enter New Password'}/>
                    </div>
                    {(passwd?.length > 0 || passwdConfirm?.length > 0) && !passMatch &&
                      <span className={'text-danger'}>Password does not match</span>
                    }
                  </Col>
                  <Col className={'col'} sm={4} xs={12}>
                    <div className="form-group">
                      <MDInput
                        variant="outlined" type="password"
                        {...register('passwdConfirm', {
                          onChange: (e) => {
                            setPasswdConfirm(e.target.value)
                          }
                        })}
                        autoComplete="off"
                        className="form-control" id="passwdConfirm"
                        label={newEmployee ? 'Confirm Password' : 'Confirm New Password'}/>
                    </div>
                  </Col>

                </Row>
                <Row className={'my-4'}>
                  <Col className={'col'} sm={4} xs={12}>
                    <div>
                      <DatePicker
                        slotProps={{
                          actionBar: {
                            actions: ['today'],
                          },
                        }}
                        label="Employee Start Date"
                        value={employee?.employeeStartDate ? dayjs(employee?.employeeStartDate) : null}
                        onChange={(e) => {
                          setEmployeeStartDate(e?.$d || null);
                          let _emp = {...employee}
                          _emp.employeeStartDate = e?.$d;
                          setEmployee(_emp);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={props.saving || !usernameValid || !passValid}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}