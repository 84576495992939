import Badge from "@mui/material/Badge";
import logoSm from "../assets/images/psb-logo-2025-round.png";
import {useNavigate} from "react-router-dom";

export default function LogoBadge(props) {
  const navigate = useNavigate();
  const handleClick = (e) => {
    navigate('/');
  };
  return (
    <Badge>
      <img
        style={{
          height: 'auto',
          width: '42px'
        }}
        onClick={handleClick}
        src={logoSm} className={'logo-sm'}/>
    </Badge>
  )
}