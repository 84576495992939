import {AppContext} from "../../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {addDate, displayDay, displayDuration, formatDate} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {getClientInventoryEvents, getInventoryEvents} from "../../common/api/InventoryApi";
import {Divider, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import * as pagination from "../../common/pagination";
import BootyPagination from "../../common/bootyPagination";
import DataTable from "react-data-table-component";
import Icon from "@mui/material/Icon";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import {getCookie, setCookie} from "../../common/appCookie";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

export default function EventsMiniDataTable(props) {
  const navigate = useNavigate();

  const registerFn = props.register || null;
  const [context, setContext] = useContext(AppContext);
  const [client, setClient] = useState(null);

  const [specialEvents, setSpecialEvents] = useState(props?.specialEvents);

  const registerMembershipEvent = (event, memberAccount) => {
    let e = {...event};
    e.memberAccount = {...memberAccount};
    registerFn(e);
  }

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    }


    let eventCookieDate = getCookie('eventDate');
    let initDate = new Date();
    if (eventCookieDate) {
      let _dt = parseInt(eventCookieDate, 10);
      if (_dt > -1) {
        initDate = new Date(_dt);
      }
    }
    setEventDate(initDate);
  }, [props?.client]);

  const [eventsLoading, setEventsLoading] = useState(true);
  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [inventoryRender, setInventoryRender] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);
  const [pageSize, setPageSize] = useState(searchParams.get('pageSize') || 20);
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);

  const [eventDate, setEventDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(new Date());

  const handleSort = (column, sortDirection) => {
    setSortOrder(column.id);
    setSortDir(sortDirection);
    setCookie('EVENTS_SORT', column.id);
    setCookie('EVENTS_SORT_DIR', sortDirection);
    setRender(render + 1);

  };

  const registerOptions = (row) => {
    if (row.memberAccountTypes?.length > 0) {
      return <div>
        {row.memberAccountTypes.map((_row, index) => {
          return (<MDButton onClick={() => registerMembershipEvent(row, _row)}
                            size={'small'}
                            key={index}
                            variant="gradient"
                            color="info">
            Register ({_row.name})
          </MDButton>)
        })
        }
      </div>

    } else {
      return <div>
        <MDButton onClick={() => registerFn(row)} variant="gradient"
                  size={'small'}
                  color="secondary">
          Register
        </MDButton>
      </div>
    }
  }

  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  const _columns = [
    {
      name: <span className='th'></span>,
      id: -1,
      width: '52px',
      selector: row => row.name,
      cell: row => {
        return <Avatar alt={row.name} sx={{width: 32, height: 32}}>
          {row.imageUrl &&
            <img src={row.imageThumbUrl ? row.imageThumbUrl : row.imageUrl} style={{width: '200%', height: 'auto'}}
                 alt={row.name}/>}
          {!row.imageUrl && row.name.substring(0, 2)}
        </Avatar>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Name</span>,
      id: 'name',
      selector: row => row.name,
      cell: row => {
        let link = '/event/view/' + row.guid;
        return <Link to={link} className="link"> {row.name} </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Location</span>,
      id: 'location',
      selector: row => row.name,
      cell: row => {
        let link = '/event/view/' + row.guid;
        return <>{row?.eventLocation?.name ? <><Icon color={'success'}>place</Icon> {row?.eventLocation?.name}</>
          : ''}</>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Session</span>,
      id: 'sessionEvent',
      selector: row => row.sessionEvent,
      cell: row => {
        return <>{row.sessionEvent ? <Icon>check</Icon> : ''} </>
      },
      sortable: true,
    },

    {
      name: <span className='th'>Recurring</span>,
      id: 'recurringEvent',
      selector: row => row.recurringEvent,
      cell: row => {
        return <>{row.recurringEvent ? <Icon>check</Icon> : ''} </>
      },
      sortable: true,
    },

    {
      name: <span className='th'>Start Time</span>,
      id: 'eventDate',
      selector: row => row.eventDate,
      cell: row => {
        return <>{displayDay(row.eventDate, 'hh:mm A')} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>End Time</span>,
      id: 'eventEndDate',
      selector: row => row.eventEndDate,
      cell: row => {
        return <>{displayDay(row.eventEndDate, 'hh:mm A')} </>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Duration</span>,
      id: 4,
      selector: row => row.duration,
      cell: row => {
        return <>{displayDuration(row)}</>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>View</span>,
      id: 5,
      right: true,
      selector: row => row.guid,
      cell: row => {
        if (registerFn) {
          return registerOptions(row);
        } else {
          let link = '/event/view/' + row.guid;
          return <div><MDButton href={link} size={'small'} color="primary">View</MDButton></div>
        }
      },
      sortable: false,
    }
  ];

  const [columns, setColumns] = useState(_columns);

  useEffect(() => {

    if (!eventDate) {
      return;
    }

    let startDate = new Date(eventDate);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 1);

    let req = {
      start: startDate,
      end: endDate,
      specialEvents: specialEvents
    }
    getInventoryEventsFn(req, props?.client);
  }, [eventDate]);


  const getInventoryEventsFn = async (req, client) => {
    setEventsLoading(true);
    let result = client ? await getClientInventoryEvents(req, client, inventoryRender) : await getInventoryEvents(req, inventoryRender);
    setEvents(result.events);
    setEventsLoading(false);
    setLoading(false);
  }

  const updateEventDates = (date) => {
    setEventDate(date);
    setEventEndDate(addDate(date, 1));
  }

  useEffect(() => {
    setCookie('eventDate', new Date(eventDate).getTime(), 1);
  }, [eventDate]);

  const {register, handleSubmit, setValue, control} = useForm({});

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(eventDate)}
    </div>
  ));

  const handleRowClick = (row) => {
    events.map((_row) => {
      _row.selected = false;
    });
    row.selected = true;
    setEvents(events);
  }

  const conditionalRowStyles = [
    {
      when: row => row.selected,
      style: {
        backgroundColor: "#d7d5d5",
        userSelect: "none"
      }
    }
  ];


  return (
    <>
      <MDBox>
        <Grid container spacing={2} direction={'row-reverse'}>
          <Grid item xs={3}>
            <MDBox lineHeight={1}>
              <DatePicker
                className={'pull-right'}
                value={dayjs(eventDate)}
                slotProps={{
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                onChange={(e) => {
                  updateEventDates(e?.$d || null);
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Divider/>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            {eventsLoading && <Loading/>}

            {!eventsLoading && <DataTable
              striped
              noDataComponent={<MDTypography variant={'p'} className={'cursor-default'}
                                             fontWeight={'light'}>No {specialEvents ? 'Parties ' : 'Events '}
                for {formatDate(eventDate)}</MDTypography>}
              columns={columns}
              keyField={'guid'}
              data={events}
              onSort={handleSort}
              highlightOnHover
              onRowClicked={(row) => {
                handleRowClick(row);
              }}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationPerPage={20}
              paginationComponent={BootyPagination}
              paginationTotalRows={totalRecords}
              onChangePage={handlePageChange}
              progressPending={loading}
              defaultSortFieldId={getCookie('EVENTS_SORT') || 1}
              defaultSortAsc={getCookie('EVENTS_SORT_DIR') === 'asc'}
              paginationComponentOptions={PaginationOptions}
            />}
          </Grid>
        </Grid>

      </MDBox>
    </>
  )
}