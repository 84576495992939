import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {forwardRef, useContext, useEffect, useState} from "react";

import Meta from "../common/Meta";
import {AppContext} from "../../lib/AppContext";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Footer from "../Footer";
import {getReportByTypeRange, getReportByTypeYearMonth} from "../common/api/ReportApi";
import {CardContent, Chip, Link} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Loading from "../common/Loading";
import {formatDateMask, formatDateTime} from "../common/Utils";
import BootyPagination from "../common/bootyPagination";
import DataTable from "react-data-table-component";
import * as pagination from "../common/pagination";
import {useForm} from "react-hook-form";
import Icon from "@mui/material/Icon";
import {BarChart} from '@mui/x-charts/BarChart';
import MDButton from "../../components/MDButton";
import StatisticsCard from "./card/StatisticsCard";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import {getCookie, setCookie} from "../common/appCookie";
import {Col} from "react-bootstrap";
import Switch from "@mui/material/Switch";

export default function CustomerReportDetails({type, range}) {
  const widthFn = () => {
    let w = parseInt(window.innerWidth, 10) * .5;
    return parseInt(window.innerWidth, 10) * .5;
  }
  const [docWidth, setDocWidth] = useState(100);

  useEffect(() => {
    function handleWidthFn() {
      setDocWidth(widthFn());
    }

    window.addEventListener("resize", handleWidthFn);
    handleWidthFn();
    return () => window.removeEventListener("resize", handleWidthFn);
  }, []);

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();
  const [reportPeriod, setReportPeriod] = useState();
  const [reportEndPeriod, setReportEndPeriod] = useState();
  const [reportData, setReportData] = useState();

  const [rangeReport, setRangeReport] = useState(range);

  const [eventDate, setEventDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();

  const _BootyPagination = {...BootyPagination};

  let {reportYear, reportMonth, reportEndYear, reportEndMonth} = useParams();


  useEffect(() => {

    let _period = reportYear + '/' + reportMonth;
    setReportPeriod(_period);
    let _d = new Date();
    _d.setFullYear(reportYear);
    let m = moment(reportMonth, 'MMM')
    _d.setMonth(m.month());
    setEventDate(_d);

    if (!range) {
      setReportEndPeriod(_period);
      setEventEndDate(_d);

      getSalesReportByYearMonthFn(_period);

      return;
    }

    let _endPeriod = reportEndYear + '/' + reportEndMonth;
    setReportEndPeriod(_endPeriod);
    getSalesReportByYearMonthFn(_endPeriod);
    let _dd = new Date();
    _dd.setFullYear(reportEndYear);
    let mm = moment(reportEndMonth, 'MMM')
    _dd.setMonth(mm.month());

    setReportEndPeriod(_endPeriod);
    setEventEndDate(_dd);

    getSalesReportByRangeFn(_period, _endPeriod);

  }, []);

  useEffect(() => {
    _BootyPagination.reportData = {...reportData};
  }, [reportData]);


  const [labels, setLabels] = useState();
  const [chartData, setChartData] = useState();

  const formatChartData = (report) => {
    setChartData(report?.pieChartData);
  }

  const displayMemberships = (member) => {

    let comps = [];
    let _recurringCount = 0;
    let _nonRecurringCount = 0;
    let hasRecurring = false;
    member.accounts.map(account => {
      if (account?.accountType?.recurring) {
        _recurringCount++;
      } else {
        _nonRecurringCount++;
      }
    });
    if (_recurringCount > 0) {
      comps.push(<Chip label={'Recurring ' + _recurringCount}
                       variant="outlined"
                       color="secondary"/>)
    }
    if (_nonRecurringCount > 0) {
      comps.push(<Chip label={'Non-Recurring ' + _nonRecurringCount}
                       variant="outlined"
                       color="secondary"/>)
    }
    return (comps.map((comp, idx) => {
      return (<span style={{marginRight: '4px'}} key={idx}>{comp}</span>)
    }));
  }

  const getSalesReportByYearMonthFn = (period) => {

    setReportData(null);

    getReportByTypeYearMonth(type, period).then(r => {
      if (r.error) {
        setContext({...context, error: {message: r.message}});
        setReportData({});
      } else {
        setReportData(r);
        formatChartData(r);
      }
    });

  }


  const getSalesReportByRangeFn = (startPeriod, endPeriod) => {

    setReportData(null);

    getReportByTypeRange(type, startPeriod, endPeriod).then(r => {
      if (r.error) {
        setContext({...context, error: {message: r.message}});
        setReportData({});
      } else {
        setReportData(r);
        formatChartData(r);
      }
    });

  }


  const DisplayReferenceId = ({tableRow}) => {

    const [row, setRow] = useState(tableRow);
    let id = row.referenceId;
    let descPart = id.substring(0, 3);
    let finalPart = id.substring(id.length - 4, id.length);
    return (
      <span
        className="cursor-pointer"
        onClick={() => {
          let _r = {...row};
          _r.fullId = !!!_r.fullId;
          setRow(_r);
        }}>
        {row.fullId ? <>{id}</> :
          <Chip
            label={descPart + '...' + finalPart}
            variant="outlined"
            color="secondary"

          />
        }
      </span>
    )
  }
  const handleViewCustomer = (row) => {
    navigate('/client/' + row.guid);
  }

  const _columns = [
    {
      name: <span className='th'>Member Since</span>,
      id: 1,
      selector: row => row.createDate,
      cell: row => {
        return formatDateTime(row.createDate);
      },
      sortType: (a, b) => {
        return new Date(b.values.createDate) - new Date(a.values.createDate);
      },
      sortable: true,
    },
    {
      name: <span className='th'>Customer</span>,
      id: 2,
      selector: row => row.lastName.toUpperCase() + '-' + row.firstName?.toUpperCase(),
      cell: row => {
        return <Link className="link"
                     href={'/client/' + row.guid}>
          {row.lastName + (row.firstName ? ', ' + row.firstName : '')}
        </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Memberships</span>,
      id: 14,
      selector: row => row.guid,
      cell: row => {
        return <>{displayMemberships(row)}</>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Created By</span>,
      id: 15,
      selector: row => row.createUser?.lastName,
      cell: row => {
        return <>{row.createUser?.lastName + (row.createUser?.firstName ? ', ' + row.createUser?.firstName : '')}</>;
      },
      sortable: true,
    },
  ];

  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);
  const [columns, setColumns] = useState(_columns);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);
  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);

  const exportCsv = () => {
    let _rows = {...reportData};
    let d = _rows?.reportItems;
    let filename = 'Member-Export-' + formatDateMask(new Date(), 'MM-DD-YYYY-hh-mm-ss') + '.csv';

    let rows = [];
    let _dd = [];
    _dd.push("Member Since");
    _dd.push("Customer Last Name");
    _dd.push("Customer First Name");
    _dd.push("Memberships");
    _dd.push("Created By");

    rows.push(_dd);

    for (let j in d) {
      let memberships = [];
      for (let k in d[j].accounts) {
        if (memberships.indexOf(d[j].accounts[k].accountType.name) == -1) {
          memberships.push(d[j].accounts[k].accountType.name);
        }
      }
      let _dd = [];
      _dd.push(formatDateTime(d[j].createDate));
      _dd.push(d[j].lastName);
      _dd.push(d[j].firstName);
      _dd.push(memberships.join(', '))
      _dd.push(d[j].createUser?.lastName + ', ' + d[j].createUser?.firstName);

      rows.push(_dd);
    }

    let data = rows.map(row =>
      row
        .map(String)  // convert every value to String
        .map(v => v.replaceAll('"', '""'))  // escape double quotes
        .map(v => `"${v}"`)  // quote it
        .join(',')  // comma-separated
    ).join('\r\n');

    var blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const handleSort = (column, sortDirection) => {

    setSortOrder(column.id);
    setSortDir(sortDirection);

    setCookie('CUSTOMER_REPORT_SORT', column.id);
    setCookie('CUSTOMER_REPORT_SORT_DIR', sortDirection);

    setRender(render + 1);

  };

  const updateEventDates = (date) => {
    let _d = new Date(date);
    let _period = (_d.getFullYear()) + '/' + formatDateMask(_d, 'MMM');
    setEventDate(_d);
    setReportPeriod(_period);
  }

  const toggleReportRange = () => {
    setRangeReport(!rangeReport);
  }

  const getReport = () => {
    if (rangeReport) {
      getSalesReportByRangeFn(reportPeriod, reportEndPeriod);
      navigate(`/${type}/report/${reportPeriod}/${reportEndPeriod}`, {replace: true});
    } else {
      getSalesReportByYearMonthFn(reportPeriod);
      navigate(`/${type}/report/${reportPeriod}`, {replace: true});
    }
  }

  const {register, handleSubmit, setValue, control} = useForm({});

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <span className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <MDTypography variant="h5" color="secondary" fontWeight="light" sx={{
        display: 'inline-block'
      }}>
        <Icon sx={{
          marginTop: '2px',
        }}>event</Icon>Report Date - {formatDateMask(eventDate, 'MMMM yyyy')}
      </MDTypography>
    </span>
  ));

  const countMemberships = (data, condition) => {
    let _count = 0;
    reportData?.reportItems?.map(row => {
      row.accounts.map(account => {
        if (account.accountType.recurring === condition) {
          _count++;
        }
      })
    });
    return _count;
  }
  const getNonRecurringMemberships = (reportData) => {
    return countMemberships(reportData, false);
  }

  const getRecurringMemberships = (reportData) => {
    return countMemberships(reportData, true);
  }

  const updateEventEndDates = (date) => {
    let _d = new Date(date);
    let _period = (_d.getFullYear()) + '/' + formatDateMask(_d, 'MMM');
    setEventEndDate(_d);
    setReportEndPeriod(_period);
  }


  const valueFormatter = (value) => (value);

  return (

    <DashboardLayout>

      <Meta pageTitle={'Member Report'}/>

      <ContextSearchNavbar title="Member Report"/>

      <MDBox py={3}>
        <MDBox mb={3}>
          {!reportData && <Loading/>}
          {reportData && <Grid container spacing={1} mb={2}>
            <Grid item xs={12}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '4px 0 0 4px'
                  }
                }}
                closeOnSelect={false}
                views={['month', 'year']}
                maxDate={dayjs()}
                slotProps={{
                  actionBar: {
                    actions: ['today', 'accept'],
                  },
                }}
                value={dayjs(eventDate)}
                onChange={(e) => {
                  updateEventDates(e?.$d || null);
                }}
              />
              {rangeReport &&
                <DatePicker
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '4px 0 0 4px'
                    }
                  }}
                  closeOnSelect={false}
                  views={['month', 'year']}
                  minDate={dayjs(eventDate)}
                  maxDate={dayjs()}
                  slotProps={{
                    actionBar: {
                      actions: ['today', 'accept'],
                    },
                  }}
                  value={dayjs(eventEndDate)}
                  onChange={(e) => {
                    updateEventEndDates(e?.$d || null);
                  }}
                />}
              <MDButton
                sx={{
                  borderRadius: '0 4px 4px 0',
                  height: '100%'
                }}
                color="info"
                onClick={() => {
                  getReport();
                }}
              >Go</MDButton>
            </Grid>
            <Grid item xs={12}>
              <Col xs={12}>
                <label
                  htmlFor="rangeReport">
                  Date Range
                  <Switch
                    name={'rangeReport'}
                    id={'rangeReport'}
                    checked={rangeReport}
                    style={{marginRight: '5px'}}
                    onChange={toggleReportRange}
                  /> </label>
              </Col>
            </Grid>

            <Grid item sm={8} xs={12}>
              <Card>
                <CardContent sx={{
                  height: '320px'
                }}>

                  {chartData && <BarChart
                    tooltip={{trigger: 'item'}}
                    grid={{vertical: true, horizontal: true}}
                    dataset={chartData}

                    xAxis={[
                      {
                        scaleType: 'band',
                        dataKey: 'label',
                        colorMap: {
                          type: 'ordinal',
                          colors: ['#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e']
                        }
                      }
                    ]}
                    series={[{dataKey: 'value', valueFormatter}]}
                    width={docWidth}
                    height={300}
                  />}
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
              <StatisticsCard
                title="Recurring Memberships"
                count={getRecurringMemberships(reportData)}
                percentage={{
                  color: "success",
                  label: "Total Recurring Memberships for period",
                }}
              />

              <div className="mt-2">

                <StatisticsCard
                  title="Non-Recurring Memberships"
                  count={getNonRecurringMemberships(reportData)}
                  percentage={{
                    color: "success",
                    label: "Total Non-Recurring Memberships for period",
                  }}
                />
              </div>
            </Grid>

            <Card sx={{
              marginTop: '15px',
              width: '100%'
            }}>
              <CardContent>

                <MDButton
                  className="pull-right"
                  color="secondary"
                  size="small"
                  onClick={exportCsv}><span className="fa fa-file-excel"></span>&nbsp;Export</MDButton>
                <DataTable
                  striped
                  noDataComponent={<MDTypography variant={'p'} className={'cursor-default'}
                                                 fontWeight={'light'}>No
                    Member Data Found for Period</MDTypography>}
                  columns={columns}
                  keyField={'guid'}
                  data={reportData?.reportItems}
                  onSort={handleSort}
                  highlightOnHover
                  onRowClicked={(row) => {
                    // handleRowClick(row);
                  }}
                  paginationTotalRows={reportData?.reportItems?.length}
                  onChangePage={handlePageChange}
                  progressPending={!reportData}
                  defaultSortFieldId={getCookie('CUSTOMER_REPORT_SORT') || 2}
                  defaultSortAsc={getCookie('CUSTOMER_REPORT_SORT_DIR') === 'asc'}
                  paginationComponentOptions={PaginationOptions}
                />

              </CardContent>
            </Card>

          </Grid>
          }
        </MDBox>
      </MDBox>
      <Footer mt={5}/>
    </DashboardLayout>
  );
}