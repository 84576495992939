import {getAppSessionCookieHeader} from "../appCookie";
import api from "../../Api";

export const getCustomerCartFull = async (guid, referrer) => {
  let _refferer = referrer ? '?referrer=' + referrer : '';
  return await api.get('cart/full/' + guid + _refferer + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}
export const saveCustomerFamily = async (customer) => {
  const resp = await api.post('profile/family/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}
export const updateAttendees = async (cart) => {
  return await api.post('cart/attendees' + getAppSessionCookieHeader(), cart).then((response) => {
    return response.data;
  });
}

export const getCustomerCartFamilyMembers = async (client) => {
  return await api.get('cart/familyMembers/' + client.guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const checkout = async (cart, client) => {
  return await api.post('cart/checkout/' + client.guid + getAppSessionCookieHeader(), cart).then((response) => {
    return response.data;
  });
}

export const lookupClientSecret = async (client) => {
  let clientEndpoint = client && client.guid ? ('billing/secret/' + client.guid) : 'cx/billing/secret';
  return await api.post(clientEndpoint + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const addtoWaitlist = async (inventory) => {
  return await api.post('cart/waitlist' + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

export const addClientToWaitlist = async (inventory, guid) => {
  return await api.post('cart/waitlist/' + guid + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

export const clientAddToCart = async (inventory, guid) => {
  if (!guid) {
    let err = {
      error: true,
      message : 'Customer not found!'
    }
    return err;
  }
  return await api.post('cart/add/' + guid + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

function handleError(error) {
  let err = {
    error: true,
    message: error.response.data.message
  }
  return err;
};
