/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "../../assets/theme/base/typography";
import {CONSTANTS} from "../../app/common/constants";
import packageJson from '../../../package.json';


function Footer({ company, links }) {
  const { size } = typography;


// Setting default values for the props of Footer
  const defaultProps = {
    company: { href: "https://playspacebrain.com/", name: "Play Space Brain" },
    links: [
      { href: "/company/terms-of-service", name: "Terms" },
      { href: "https://playspacebrain.com/", name: "playspacebrain.com" },
    ],
  };

  const renderLinks = () =>
    defaultProps.links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      sx={{
        position: "absolute",
        bottom: "0",
        width: "95%",
        padding: "10px",
      }}
      flexDirection={{xs: "column", lg: "row"}}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()},
        Play Space Brain {CONSTANTS.ENVIRONMENT} {packageJson.version}
      </MDBox>
      <MDBox
        component="ul"
        sx={({breakpoints}) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}


// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
