import {AppContext} from "../../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {addDate, displayDay, formatCurrency, formatDate, inventoryAvailable} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {useSearchParams} from "react-router-dom";
import {getClientInventoryEvents, getInventoryEvents} from "../../common/api/InventoryApi";
import {Divider, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import * as pagination from "../../common/pagination";
import Icon from "@mui/material/Icon";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import {Col, Row} from "react-bootstrap";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

export default function EventsMiniCard(props) {

  const registerFn = props.register || null;
  const [context, setContext] = useContext(AppContext);
  const [client, setClient] = useState(null);
  const [specialEvents, setSpecialEvents] = useState(props?.specialEvents);

  const registerMembershipEvent = (event, memberAccount) => {
    let e = {...event};
    e.memberAccount = {...memberAccount};
    registerFn(e);
  }

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    }
    setEventDate(new Date());
  }, [props?.client]);

  const [eventsLoading, setEventsLoading] = useState(true);
  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [inventoryRender, setInventoryRender] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);
  const [pageSize, setPageSize] = useState(searchParams.get('pageSize') || 20);
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);

  const [eventDate, setEventDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(new Date());

  const registerOptions = (row) => {
    if (row.memberAccountTypes?.length > 0) {
      return <div>
        {row.memberAccountTypes.map((_row, index) => {
          return (<MDButton onClick={() => registerMembershipEvent(row, _row)}
                            size={'small'}
                            className="m-1"
                            key={index}
                            variant="gradient"
                            color="info">
            Register ({_row.name})
          </MDButton>)
        })
        }
      </div>

    } else {
      return <div>
        <MDButton onClick={() => registerFn(row)} variant="gradient"
                  size={'small'}
                  color="secondary">
          Register
        </MDButton>
      </div>
    }
  }

  useEffect(() => {

    if (!eventDate) {
      return;
    }

    let startDate = new Date(eventDate);
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(startDate);
    let req = {
      start: startDate,
      end: endDate,
      specialEvents: specialEvents
    }
    getInventoryEventsFn(req, props?.client);
  }, [eventDate]);


  const getInventoryEventsFn = async (req, client) => {
    setEventsLoading(true);
    let result = client ? await getClientInventoryEvents(req, client, inventoryRender) : await getInventoryEvents(req, inventoryRender);
    setEvents(result.events);
    setEventsLoading(false);
    setLoading(false);
  }

  const updateEventDates = (date) => {
    setEventDate(date);
    setEventEndDate(addDate(date, 1));
  }

  const {register, handleSubmit, setValue, control} = useForm({});

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(eventDate)}
    </div>
  ));

  const handleRowClick = (row) => {
    events.map((_row) => {
      _row.selected = false;
    });
    row.selected = true;
    setEvents(events);
  }

  const conditionalRowStyles = [
    {
      when: row => row.selected,
      style: {
        backgroundColor: "#d7d5d5",
        userSelect: "none"
      }
    }
  ];


  return (
    <>
      <MDBox>
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <MDBox width="100%" lineHeight={1}>
              <DatePicker
                slotProps={{
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                value={dayjs(eventDate)}
                onChange={(e) => {
                  updateEventDates(e?.$d || null);
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Divider/>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            {eventsLoading && <Loading/>}
            {!eventsLoading && events?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
              .map((event, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <Col xs={12} sm={6}>
                        {event?.imageUrl && <Avatar alt={event?.name}
                                                    sx={{
                                                      width: 86, height: 86,
                                                      float: 'left', margin: '0 10px 10px 10px'
                                                    }}
                                                    src={event?.imageThumbUrl || event?.imageUrl}
                        />
                        }
                        <MDTypography variant="h5" fontWeight="bold" color="secondary">
                          {event.name}
                        </MDTypography>
                        {event?.sessionEvent && <MDTypography variant="h6" fontWeight="light" color="secondary">
                          Session
                        </MDTypography>
                        }

                        <blockquote>{event.desc}</blockquote>
                      </Col>
                      <Col xs={12} sm={5}>
                        <p>
                          <small>Type: <b>{event.eventType?.name}</b></small><br/>
                          <small>
                            Price:&nbsp;
                            {event?.eventType?.memberOnly ? <b>Membership Required</b> :
                              <b>{formatCurrency(event.price)}</b>}
                            <br/>
                          </small>
                          <small>
                            Age(s): <b>{event.ageRange?.name}</b>
                            <br/>
                          </small>
                          <small>
                            Available: <b>{inventoryAvailable(event)}</b>
                            <br/>
                          </small>
                          {event?.sessionEvent && <small>
                            Session Event: <b>Yes</b>
                            <br/>
                          </small>}
                          <small>
                            Event
                            Date: <b>{displayDay(event.eventDate, 'MM/DD/YYYY hh:mm a')} - {displayDay(event.eventEndDate, 'hh:mm a')}</b>
                          </small>
                        </p>
                        {registerOptions(event)}
                      </Col>
                      <Divider/>
                    </Row>
                  </div>
                )
              })
            }
          </Grid>
        </Grid>

      </MDBox>
    </>
  )
}