import {FormControl, InputLabel, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {useTheme} from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

export default function FileSelector({filterLabel, types, typeFilter, setFilter}) {
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const displayEventTypeFilter = () => {
    let _types = [];
    types?.sort((a, b) => a.sortOrder - b.sortOrder).map((type, idx) => {
      if (typeFilter.indexOf(parseInt(type.id, 10)) > -1) {
        _types.push(type.name);
      }
    });
    return _types;
  }
  function getStyles(item, items) {
    let inArray = items.indexOf(item.id) > -1;
    return {
      fontWeight: inArray ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
    }
  }

  const toggleFilterById = (id) => {
    let _eventTypeFilter = [...typeFilter];
    if (_eventTypeFilter.indexOf(parseInt(id, 10)) > -1) {
      _eventTypeFilter.splice(typeFilter.indexOf(parseInt(id, 10)), 1);
    } else {
      _eventTypeFilter = [...typeFilter, parseInt(id, 10)];
    }
    setFilter(_eventTypeFilter);
  }

  return (
    <>
      {types?.length > 0 && <FormControl sx={{m: 1, width: 300}}>
        <InputLabel
          style={{
            backgroundColor: '#fff',
            paddingRight: '10px',
            paddingBottom: '6px'
          }}
          id="event-category-selector">Filter {filterLabel}</InputLabel>
        <Select
          sx={{
            paddingRight: '25px',
            paddingTop: '1rem',
            paddingBottom: '9px'
          }}
          className={'d-inline'}
          labelId="file-selector-label"
          id="file-selector"
          multiple
          value={displayEventTypeFilter()}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>View All</em>;
            }
            return selected.join(', ');
          }}
          input={<OutlinedInput label="Name"/>}
          MenuProps={MenuProps}
        >
          {types.map((type) => (
            <MenuItem
              key={type.id}
              value={type.id}
              onClick={(e) => {
                let _e = {...e};
                toggleFilterById(type.id);
              }}
            >
              <Checkbox checked={typeFilter.includes(type.id)} />
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      }
    </>
  )
}