import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Card, CardContent, Chip} from "@mui/material";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import {
  cancelInventoryEvent,
  getInventoryEventFull,
  saveCustomerInventoryEventRegistration
} from "../common/api/InventoryApi";
import {Link, useNavigate, useParams} from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../components/MDButton";
import Divider from "@mui/material/Divider";
import {Col, Row} from "react-bootstrap";
import {displayDay, formatCurrency, formatDateTime, inventoryAvailable} from "../common/Utils";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import ListCustomersModal from "../client/ListCustomersModal";
import {
  cancelClientReservation,
  cancelClientWaitlist,
  getCustomerIntentSuccess,
  getInventoryNotes,
  recordCustomerVisit,
  saveClientReservation,
  saveEventNote
} from "../common/api/WebApi";
import ClientViewCustomerCartModal from "../common/ClientViewCustomerCartModal";
import ManageInventoryEventModal from "./ManageInventoryEventModal";
import Confirm from "../common/Confirm";
import {CONSTANTS} from "../common/constants";
import {addClientToWaitlist} from "../common/api/CartApi";
import Avatar from "@mui/material/Avatar";
import ClientViewEventRegistrationModal from "./modal/ClientViewEventRegistrationModal";
import EventNoteModal from "./EventNoteModal";
import PageLoading from "../common/PageLoading";
import CartFinal from "../common/cart/CartFinal";

export default function ViewInventoryEvent(props) {

  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  let {guid} = useParams();

  const [event, setEvent] = useState();
  const [refreshEvent, setRefreshEvent] = useState(false);
  const [inventory, setInventory] = useState();
  const [modalInventory, setModalInventory] = useState();
  const [sessionEventInventory, setSessionEventInventory] = useState();

  const [inventoryReady, setInventoryReady] = useState(false);
  const [renderIndex, setRenderIndex] = useState(1);

  const [refreshCart, setRefreshCart] = useState(false);
  const [reservation, setReservation] = useState();

  const [viewingCart, setViewingCart] = useState(false);
  const [saving, setSaving] = useState(false);
  const [manageInventoryEvent, setManageInventoryEvent] = useState(false);
  const [manageInventorySessionEvent, setManageInventorySessionEvent] = useState(false);

  const [showRegistrationInventory, setShowRegistrationInventory] = useState(false);

  const [client, setClient] = useState(null);
  const [startCancelEvent, setStartCancelEvent] = useState(false);
  const [showCancelEvent, setShowCancelEvent] = useState(false);
  const [confirmation, setConfirmation] = useState({
    title: 'Cancel Event',
    message: 'Are you sure you want to cancel this event?'
  });
  const [savingCancelEvent, setSavingCancelEvent] = useState(false);

  const [modalActive, setModalActive] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [searchNotes, setSearchNotes] = useState(null);
  const [note, setNote] = useState({});
  const [manageNote, setManageNote] = useState(null);
  const [noteCount, setNoteCount] = useState(0);
  const [clientDisabled, setClientDisabled] = useState(false);
  const [inventoryNotes, setInventoryNotes] = useState(null);

  const highlight = (haystack) => {
    const needle = searchNotes;
    if (!needle) {
      return haystack;
    }
    var querystr = needle;
    var result = haystack;
    var reg = new RegExp(querystr.split(' ').join('|'), 'gi');
    var final_str = result.replace(reg, function (str) {
      return '<b>' + str + '</b>'
    });

    return final_str;

  }

  const getInventoryNotesFn = async () => {
    return await getInventoryNotes(guid);
  }

  useEffect(() => {
    if (!inventory?.guid) {
      return;
    }
    getInventoryNotesFn().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryNotes(result);
      }
    });
  }, [inventory?.guid, noteCount]);

  const matchNotes = (note) => {
    let notes = searchNotes.split(' ');
    let match = true;
    notes.forEach(n => {
      if (!note.note.toLowerCase().includes(n.toLowerCase())) {
        match = false;
      }
    });
    return match;
  }

  const addNoteFn = () => {
    setAddNote(true);
    setNote(null);
  };

  const manageNoteFn = (note) => {
    setNote(note);
    setManageNote(true);
  };

  const handleSaveNote = async (note) => {

    setSaving(true);
    note.inventoryGuid = inventory.guid;

    let result = await saveEventNote(note);
    setSaving(false);
    setAddNote(false);
    setManageNote(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setNoteCount(noteCount + 1);
    }
  }

  const myRefs = useRef([]);
  const cancelInventoryEventFn = () => {
    setStartCancelEvent(true);
    setShowCancelEvent(true);
    setSavingCancelEvent(false);
  };

  const [listCustomersModalOpen, setListCustomersModalOpen] = useState(false);
  const [listCustomersWaitlistModalOpen, setListCustomersWaitlistModalOpen] = useState(false);


  const handleCloseNote = () => {
    setManageNote(false);
    setAddNote(false);
  }

  const handleEventCancel = () => {
    setSavingCancelEvent(true);
    cancelInventoryEvent(inventory).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setRefreshEvent(true);
        setShowCancelEvent(false);
        setSavingCancelEvent(false);
        navigate('/sales/events');
      }
    });
  }

  const handleCloseConfirm = () => {
    setShowCancelEvent(false);
    setSavingCancelEvent(false);
  }

  const handleCloseListCustomersModal = () => {
    setListCustomersModalOpen(false);
  }

  const handleCloseListWaitlistCustomersModal = () => {
    setListCustomersWaitlistModalOpen(false);
  }

  const handleCloseInventoryEventModal = () => {
    setManageInventoryEvent(false);
  }

  const closeInventoryFn = () => {
    setManageInventoryEvent(false);
    setModalInventory(null);
  }

  const handleSaveInventory = (inventory) => {
    closeInventoryFn();
    setRefreshEvent(true);
  }

  const startAddCustomerReservation = () => {
    setListCustomersModalOpen(true);
  }

  const startAddCustomerWaitlist = () => {
    setListCustomersWaitlistModalOpen(true);
  }

  const getEventFn = async (guid) => {
    return await getInventoryEventFull(guid);
  }
  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };

  const toggleCartFn = () => {
    setViewingCart(!viewingCart);
  }

  const [cartActive, setCartActive] = useState(false);

  const cartCallback = (callback) => {
    console.dir(callback);
    if (callback.action === 'close') {
      setCartActive(false);
    }
    if (callback.action === 'reload') {
      setRefreshCart(true);
      setRefreshEvent(true);
    }
    if (callback.action === 'success') {
      setCartActive(false);
      setRefreshCart(true);
      setRefreshEvent(true);
      let _context = {...context};
      _context.toast = {
        message: 'Registration Successful',
      }
      setContext({..._context});
    }
  }

  const toggleCheckout = (_client) => {
    setClient(_client?.member);
    setViewingCart(true);
    setRefreshCart(true);
    toggleReservationsCartFn();
  }

  const toggleReservationsCartFn = () => {
    setShowRegistrationInventory(!showRegistrationInventory);
  }

  const handleAssignInventory = (client) => {

    setClient(client);

    let _reservation = {};
    _reservation.eventName = inventory.name;
    _reservation.eventGuid = inventory.guid;

    saveClientReservation(client, _reservation).then(result => {
      setRefreshCart(true);
      setListCustomersModalOpen(false);
      setCartActive(true);
    });
  }

  const handleAssignWaitlist = (client) => {

    addClientToWaitlist(inventory, client.guid).then(result => {
      setRefreshEvent(true);
      setListCustomersWaitlistModalOpen(false);
      let c = {...context}
      c.toast = {message: 'Added to wait list', type: 'success'};
      setContext(c);
    });

  }

  const [salesReady, setSalesReady] = useState(false);
  const [salesResp, setSalesResp] = useState();
  const [searchTimer, setSearchTimer] = useState(1);
  const [paymentIntent, setPaymentIntent] = useState();

  // sales/intent/{memberGuid}/{paymentIntent}

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentIntent) {
        if (searchTimer > 1) {
          if (searchTimer < 30) {
            getSalesFn(paymentIntent);
          } else {
            navigate('/cx/my-purchases');
          }
        }
      }
    }, 1000);
    return () => clearTimeout(searchTimer);
  }, [searchTimer]);

  const getSalesFn = async (paymentIntent) => {
    let result = await getCustomerIntentSuccess(client?.guid, paymentIntent);
    if (result) {
      if (result?.salesItems?.length > 0) {
        setSalesResp(result);
        setSalesReady(true);
        setRefreshCart(true);
        setRefreshEvent(true);
        toggleCartFn();
      } else {
        setSearchTimer(searchTimer + 1);
      }
    }
  }

  const handleCheckout = (response) => {

    // todo get cart response success from tx, then refresh event
    console.dir(response);

    if (response && response?.paymentId) {
      setPaymentIntent(response?.paymentId);
      getSalesFn(response?.paymentId);
    } else {
      setRefreshCart(true);
      setRefreshEvent(true);
      toggleCartFn();
    }
  };

  const handleEditInventory = () => {
    let _inventory = {...inventory};
    _inventory.event = event;
    setModalInventory(_inventory);
    setManageInventoryEvent(true);
  }

  const handleEditRecurringInventory = () => {
    let _inventory = {...inventory};
    _inventory.event = event;
    _inventory.manageRecurring = true;
    setModalInventory(_inventory);
    setManageInventoryEvent(true);
  }

  const createSessionEvent = () => {
    let parentSessionGuid = inventory?.parentEventGuid || inventory?.guid;
    let _inventory = {...inventory};
    _inventory.parentEventGuid = parentSessionGuid;
    _inventory.guid = null;
    _inventory.id = null;
    _inventory.createSessionEventInSeries = true;

    setSessionEventInventory(_inventory);
    setManageInventorySessionEvent(true);

  }


  useEffect(() => {
    if (!guid) {
      return;
    }
    setInventoryReady(false);
    getEventFn(guid).then(result => {
      setInventory(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let e = {...result};
        e.eventDate = new Date(e.eventDate);
        e.eventEndDate = new Date(e.eventEndDate);

        setInventory(e);
        setInventoryReady(true);
      }
    });
  }, [guid]);

  useEffect(() => {
    if (!guid) {
      return;
    }
    if (!refreshEvent) {
      return;
    }
    setRefreshEvent(false);

    getEventFn(guid).then(result => {
      setInventory(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let e = {...result};
        e.eventDate = new Date(e.eventDate);
        e.eventEndDate = new Date(e.eventEndDate);

        setInventory(e);
        setInventoryReady(true);
      }
    });
  }, [refreshEvent]);

  const recordVisitFn = async (event, reservation) => {
    reservation.saving = true;
    let result = await recordCustomerVisit(reservation.memberGuid, reservation);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshEvent(true);
    }
  }

  const startSearchInventory = (reservation) => {
    let _reservation = {...reservation};
    _reservation.event = inventory;
    _reservation.parentEventGuid = inventory.guid;
    setReservation(_reservation);
    setShowRegistrationInventory(true);
  }

  const cancelReservationFn = async (reservation) => {
    reservation.cancelling = true;
    cancelClientReservation(reservation).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        reservation.cancelling = false;
        setRefreshEvent(true);
      }
    });
  }

  const cancelWaitlistFn = async (reservation) => {
    reservation.cancelling = true;
    cancelClientWaitlist(reservation).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        reservation.cancelling = false;
        setRefreshEvent(true);
      }
    });
  }

  const confirmEventWaitlistRegistration = async (reservation) => {
    reservation.saving = true;

    saveCustomerInventoryEventRegistration(reservation).then(result => {
      let client = {
        guid: reservation.memberGuid
      };
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        reservation.cancelling = false;
        setClient(client);
        setRefreshCart(true);
        setCartActive(true);
      }
    });
  }

  const reservationDisabled = (reservation) => {
    let id = reservation?.reservationStatus?.id;
    return id === CONSTANTS.RESERVATION_STATUS.CHECKED_IN
      || id === CONSTANTS.RESERVATION_STATUS.CANCELLED
      || id === CONSTANTS.RESERVATION_STATUS.EVENT_CANCELLED;
  }

  return (
    <DashboardLayout>

      <ContextSearchNavbar title={'View Event'}/>
      {!inventoryReady && <PageLoading/>}
      {inventoryReady &&
        <MDBox py={3}>
          <MDBox mb={3}>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <div className={'pull-right'} ref={el => (myRefs.current['inventoryActions'] = el)}
                         onClick={(e) => {
                           refHandler('inventoryActions')
                         }}>

                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        circular
                        iconOnly
                      >
                        <Icon>more_horiz</Icon>
                      </MDButton>
                      {renderIndex && myRefs.current['inventoryActions']?.visible &&
                        <Menu open={true} anchorReference={null} anchorEl={myRefs.current['inventoryActions']}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}>
                          <NotificationItem
                            disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE}
                            onClick={(e) => {
                              handleEditInventory()
                            }} icon={<Icon>edit</Icon>} title="Edit"/>

                          {inventory?.sessionEvent &&
                            <NotificationItem
                              disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE}
                              onClick={(e) => {
                                createSessionEvent();
                              }} icon={<Icon>event_repeat</Icon>} title="Create Event in Session"/>}

                          {inventory?.parentEventGuid &&
                            <NotificationItem
                              disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE}
                              onClick={(e) => {
                                handleEditRecurringInventory()
                              }} icon={<Icon>edit_calendar</Icon>}
                              title={inventory?.sessionEvent ? 'Edit Session Series' : 'Edit Recurring Series'}/>}


                          <NotificationItem
                            disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE}
                            onClick={(e) => {
                              cancelInventoryEventFn()
                            }} icon={<Icon>cancel</Icon>} title="Cancel Event"/>
                        </Menu>
                      }
                    </div>
                    {inventory?.name &&
                      <>
                        <MDTypography fontWeight={'regular'} variant={'h5'}>
                          {inventory?.name}
                        </MDTypography>
                        {inventory?.sessionEvent &&
                          <MDTypography fontWeight={'light'} variant={'h6'}>
                            <Icon
                              className={'ml-n2'} color={'info'}>event_repeat</Icon>Session Event
                          </MDTypography>}
                        {inventory?.eventLocation?.id > -1 && <>
                          <MDTypography fontWeight={'regular'} variant={'h6'}>
                            <Icon className={'ml-n2'} color={'success'}>place</Icon>
                            {inventory?.eventLocation?.name}
                            {inventory?.eventLocation?.address ? ' - ' : ''}
                            {inventory?.eventLocation?.address}
                          </MDTypography>
                        </>
                        }
                      </>
                    }

                    <Row>
                      <Col sm={6}>
                        {inventory?.inventoryType?.name && <>
                          <MDTypography fontWeight={'bold'} variant={'h6'}>Inventory Type</MDTypography>
                          <MDTypography fontWeight={'regular'}
                                        variant={'h6'}>{inventory?.inventoryType?.name}</MDTypography>
                        </>
                        }
                      </Col>
                      <Col sm={6}>
                        {inventory?.eventType?.name && <>
                          <MDTypography fontWeight={'bold'} variant={'h6'}>Category</MDTypography>
                          <MDTypography fontWeight={'regular'}
                                        variant={'h6'}>{inventory?.eventType?.name}</MDTypography>
                        </>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        {inventory?.eventType?.privateAccess &&
                          <Chip
                            size="small"
                            className={'m-1 mb-n1'}
                            label="Private"
                            color="info"/>
                        }

                        {inventory?.eventType?.specialEvent &&
                          <Chip
                            size="small"
                            className={'m-1 mb-n1'}
                            label="Party Event"
                            color="success"/>
                        }
                      </Col>

                    </Row>
                    <Divider/>
                    <MDTypography fontWeight={'bold'} variant={'h6'}>Date</MDTypography>
                    <p>
                      {inventory?.allDay && 'All Day'}
                      {!inventory?.allDay && <>
                        {formatDateTime(inventory?.eventDate)} - {displayDay(inventory?.eventEndDate, 'hh:mm a')}
                      </>
                      }
                    </p>

                    {inventory?.sessionEvents?.length > 0 && <>
                      <MDTypography fontWeight={'bold'} variant={'h6'}>Session Events</MDTypography>
                      {inventory?.sessionEvents?.length > 0 && inventory?.sessionEvents?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                        .map((event, key) => {
                          return (<li className={'list-group-item'} key={key}>
                            <Link
                              to={'/event/view/' + event.guid}>{event.name} - {formatDateTime(event.eventDate)}</Link>
                          </li>)
                        })
                      }
                    </>}


                    <MDTypography fontWeight={'bold'} variant={'h6'}>Description</MDTypography>
                    <MDTypography style={{
                      whiteSpace: 'pre',
                    }} variant="p" fontWeight="light" color="primary">{inventory?.desc}</MDTypography>
                    <Row>
                      <Col sm={4}>
                        <MDTypography fontWeight={'bold'} variant={'h6'}>Age</MDTypography>
                        <p>
                          {inventory?.ageRange?.name || 'General'}
                        </p>

                        <MDTypography fontWeight={'bold'} variant={'h6'}>Length</MDTypography>
                        <p>
                          {inventory?.eventDuration}
                        </p>
                      </Col>
                      <Col sm={4}>

                        <MDTypography fontWeight={'bold'}
                                      variant={'h6'}>{inventory?.sessionEvent ? 'Session ' : ''}Price</MDTypography>
                        <p>
                          {formatCurrency(inventory?.price)}
                        </p>

                        <MDTypography fontWeight={'bold'} variant={'h6'}>Available</MDTypography>
                        <p>
                          {inventoryAvailable(inventory)}
                        </p>
                      </Col>

                    </Row>
                    <Divider/>
                    <Row>
                      <Col sm={12}>
                        <MDTypography fontWeight={'bold'} variant={'h6'}>Public Image</MDTypography>
                        {inventory?.imageUrl && <img
                          className={'mt-2 img-thumbnail'} src={inventory?.imageUrl} alt="img"/>}
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <MDButton
                      color="secondary"
                      size="small"
                      className={'pull-right'}
                      disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE || inventoryAvailable(inventory) <= 0}
                      onClick={(e) => {
                        e.preventDefault();
                        startAddCustomerReservation();
                      }}>
                      <span className={'fa fa-user-plus'}></span>&nbsp;Add
                    </MDButton>

                    <MDTypography variant="h5" fontWeight="regular">
                      Reservations
                    </MDTypography>
                    <Divider/>
                    <Row>
                      <Col sm={12}>
                        <ul className={'list-group'}>
                          {inventory?.reservations && inventory?.reservations.sort((a, b) => a.id > b.id ? 1 : -1).map((reservation, index) => {
                            return (<li key={index} className={'list-group-item'}>
                                <MDTypography fontWeight={'regular'}
                                              variant={'h6'}>
                                  <Link to={'/client/' + reservation?.member?.guid}>
                                    {reservation?.member?.firstName}&nbsp;{reservation?.member?.lastName}
                                    {reservation?.attendee &&
                                      <> {' (' + reservation?.attendee.firstName + ' ' + reservation?.attendee.lastName + ')'}</>
                                    }
                                  </Link>

                                  <div className={'pull-right'}
                                       ref={el => (myRefs.current['reservationsActions-' + index] = el)}
                                       onClick={(e) => {
                                         refHandler('reservationsActions-' + index)
                                       }}>
                                    <MDButton
                                      variant="outlined"
                                      color="secondary"
                                      size="small"
                                      circular
                                      iconOnly
                                    >
                                      <Icon>more_horiz</Icon>
                                    </MDButton>
                                    {renderIndex && myRefs.current['reservationsActions-' + index]?.visible &&
                                      <Menu open={true} anchorReference={null}
                                            anchorEl={myRefs.current['reservationsActions-' + index]}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}>
                                        <NotificationItem
                                          onClick={(e) => {
                                            startSearchInventory(reservation)
                                          }} icon={<Icon>shopping_cart</Icon>} title="Search Add-Ons"/>

                                        <NotificationItem
                                          disabled={reservation.cancelling}
                                          onClick={(e) => {
                                            cancelReservationFn(reservation)
                                          }} icon={<Icon>cancel</Icon>} title="Cancel Reservation"/>
                                      </Menu>
                                    }
                                  </div>
                                </MDTypography>

                                <span style={{
                                  'clear': 'both',
                                  'display': 'block',
                                  'marginTop': '10px',
                                  'paddingBottom': '10px'
                                }}>
                                <MDButton
                                  color={reservationDisabled(reservation) ? 'error' : 'info'}
                                  size="small"
                                  className={'pull-right'}
                                  disabled={reservationDisabled(reservation)}
                                  onClick={(e) => {
                                    recordVisitFn(e, reservation);
                                  }}>
                                  {reservationDisabled(reservation) && reservation?.reservationStatus?.name}
                                  {!reservationDisabled(reservation) && <>
                                    {!reservation.saving ? <><span
                                        className={'fa fa-check'}></span>&nbsp;Check{reservation?.reservationStatus?.id === CONSTANTS.RESERVATION_STATUS.CHECKED_IN ? 'ed' : ''}</>
                                      : <><span
                                        className={'fa fa-spinner fa-spin'}></span>&nbsp;Checking</>}&nbsp;In </>}
                                </MDButton>
                                <Chip variant="outlined"
                                      avatar={<Avatar><span
                                        className={reservation?.reservationStatus?.id === CONSTANTS.RESERVATION_STATUS.CANCELLED ? 'fa fa-info-circle' : 'fa fa-bookmark'}></span></Avatar>}
                                      label={reservation?.reservationStatus?.name}/>
                                  {reservation?.specialRequestInput && <>
                                    <Divider/>
                                    <b>Party Request:</b>
                                    <br/>
                                    {reservation.specialRequestInput}
                                  </>}
                                  {reservation?.reservationPurchaseItems.length > 0 && <>
                                    <h5>&nbsp;</h5>
                                    <Chip
                                      size="small"
                                      className={'m-1 mb-n1'}
                                      avatar={<Avatar><Icon>info</Icon></Avatar>}
                                      label={<MDTypography variant="p" fontWeight="bold"
                                                           className="mt-1">Add-ons</MDTypography>}
                                      variant="outlined"
                                      color="secondary"/>

                                    {reservation?.reservationPurchaseItems.map((item, index) => {
                                      return (
                                        <Chip
                                          key={index}
                                          size="small"
                                          className={'m-1 mb-n1'}
                                          index={index}
                                          avatar={<Avatar
                                            src={item?.imageThumbUrl || item?.imageUrl}/>}
                                          label={item?.name}
                                          variant="outlined"
                                          color="secondary"/>
                                      )
                                    })
                                    }
                                  </>
                                  }
                              </span>
                              </li>
                            )
                          })}
                        </ul>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>


                <Card style={{
                  'marginTop': '20px'
                }}>
                  <CardContent>
                    <MDButton
                      color="secondary"
                      size="small"
                      className={'pull-right'}
                      disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE || inventoryAvailable(inventory) > 0}
                      onClick={(e) => {
                        e.preventDefault();
                        startAddCustomerWaitlist();
                      }}>
                      <span className={'fa fa-user-plus'}></span>&nbsp;Add
                    </MDButton>

                    <MDTypography variant="h5" fontWeight="regular">
                      Wait List
                    </MDTypography>
                    <Divider/>
                    <Row>
                      <Col sm={12}>
                        <ul className={'list-group'}>
                          {inventory?.waitlist && inventory?.waitlist.sort((a, b) => a.id > b.id ? 1 : -1).map((waitlist, index) => {
                            return (<li key={index} className={'list-group-item'}>
                                <MDTypography fontWeight={'regular'}
                                              variant={'h6'}>
                                  <Link to={'/client/' + waitlist?.member?.guid}>
                                    {waitlist?.member?.firstName}&nbsp;{waitlist?.member?.lastName}
                                  </Link>

                                  <div className={'pull-right'}
                                       ref={el => (myRefs.current['waitlistActions-' + index] = el)}
                                       onClick={(e) => {
                                         refHandler('waitlistActions-' + index)
                                       }}>
                                    <MDButton
                                      variant="outlined"
                                      color="secondary"
                                      size="small"
                                      circular
                                      iconOnly
                                    >
                                      <Icon>more_horiz</Icon>
                                    </MDButton>
                                    {renderIndex && myRefs.current['waitlistActions-' + index]?.visible &&
                                      <Menu open={true} anchorReference={null}
                                            anchorEl={myRefs.current['waitlistActions-' + index]}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}>

                                        <NotificationItem
                                          disabled={waitlist.saving}
                                          onClick={(e) => {
                                            confirmEventWaitlistRegistration(waitlist)
                                          }} icon={<Icon>person_outline</Icon>} title="Confirm Event Registration"/>

                                        <NotificationItem
                                          disabled={waitlist.cancelling}
                                          onClick={(e) => {
                                            cancelWaitlistFn(waitlist)
                                          }} icon={<Icon>cancel</Icon>} title="Cancel Wait List"/>

                                      </Menu>
                                    }
                                  </div>
                                </MDTypography>

                                <span style={{
                                  'clear': 'both',
                                  'display': 'block',
                                  'marginTop': '10px',
                                  'paddingBottom': '10px'
                                }}>
                                <MDButton
                                  color={'info'}
                                  size="small"
                                  className={'pull-right'}
                                  disabled={waitlist?.saving}
                                  onClick={(e) => {
                                    confirmEventWaitlistRegistration(waitlist)
                                  }}>
                                  {!waitlist.saving ? <><span
                                      className={'fa fa-check'}></span>&nbsp;Join</>
                                    : <><span
                                      className={'fa fa-spinner fa-spin'}></span>&nbsp;Joining</>}
                                </MDButton>
                                <Chip variant="outlined"
                                      avatar={<Avatar><span
                                        className={waitlist?.waitlistStatus?.id === CONSTANTS.RESERVATION_STATUS.PENDING ? 'fa fa-bookmark-O' : 'fa fa-bookmark'}></span></Avatar>}
                                      label={waitlist?.waitlistStatus?.name}/>
                              </span>
                              </li>
                            )
                          })}
                        </ul>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Card className={'mt-4'}>
              <CardContent>
                <MDTypography variant="h5" fontWeight="regular">
                  Event Notes
                  <MDButton
                    color="secondary"
                    size="small"
                    disabled={clientDisabled}
                    className={'pull-right'}
                    onClick={addNoteFn}>
                    <span className={'fa fa-plus'}></span>&nbsp;Add note
                  </MDButton>
                  <input type={'text'} className={'form-control form-control-sm my-n1 mx-2 pull-right w-25'}
                         placeholder={'Search Notes'}
                         onFocus={(e) => {
                           setModalActive(true);
                         }}
                         onBlur={(e) => {
                           setModalActive(false);
                         }}
                         onChange={(e) => {
                           setSearchNotes(e.target.value);
                         }}/>
                </MDTypography>
                <Divider/>
                <Row style={{
                  'clear': 'both',
                  'maxHeight': '500px',
                  'overflow': 'auto'
                }}>
                  <Col sm={12}>

                    <ul className={'list-group'}>
                      {inventoryNotes?.length === 0 && <li className={'list-group-item'}>
                        <center>No event notes</center>
                      </li>
                      }
                      {inventoryNotes?.map((note, index) => {
                          return (
                            <li
                              className={'list-group-item hover-bg-soft ' + (searchNotes && !matchNotes(note) ? 'd-none' : '')}
                              key={index}>
                              <p>
                                    <span
                                      dangerouslySetInnerHTML={{__html: highlight(note.note)}}/>
                              </p>

                              <small>By: {note.createUser.firstName + ' ' + note.createUser.lastName} On: {formatDateTime(note.createDate)}</small>

                              <Chip
                                className={'pull-right'}
                                label="Edit"
                                variant="outlined"
                                onClick={(e) => {
                                  manageNoteFn(note);
                                }}/>
                            </li>
                          )
                        }
                      )}
                    </ul>

                  </Col>
                </Row>
              </CardContent>
            </Card>

          </MDBox>
        </MDBox>
      }
      <ListCustomersModal show={listCustomersModalOpen}
                          embedded={true}
                          action={handleAssignInventory}
                          actionLabel={'Register'}
                          handleCloseListCustomersModal={handleCloseListCustomersModal}/>

      <ListCustomersModal show={listCustomersWaitlistModalOpen}
                          embedded={true}
                          action={handleAssignWaitlist}
                          actionLabel={'Join Wait List'}
                          handleCloseListCustomersModal={handleCloseListWaitlistCustomersModal}/>

      {
        (addNote || manageNote) && <EventNoteModal
          note={note}
          show={manageNote || addNote}
          saving={saving}
          handleCloseNote={handleCloseNote}
          handleSaveNote={handleSaveNote}/>
      }

      {showCancelEvent && <Confirm confirmation={confirmation}
                                   show={showCancelEvent}
                                   saving={savingCancelEvent}
                                   handleSave={handleCloseConfirm}
                                   handleConfirm={handleEventCancel}/>}

      {showRegistrationInventory && reservation && <ClientViewEventRegistrationModal
        show={showRegistrationInventory}
        saving={saving}
        reservation={reservation}
        handleClose={toggleReservationsCartFn}
        toggleCheckout={toggleCheckout}
        handleSave={handleCheckout}/>}

      {
        cartActive && <CartFinal
          client={client}
          cartActive={cartActive}
          callback={cartCallback}
          client={client}
        />
      }

      {
        client && <ClientViewCustomerCartModal
          show={viewingCart}
          client={client}
          saving={saving}
          handleCartRefresh={() => {
            setRefreshCart(true);
          }}
          reservation={reservation}
          handleClose={toggleCartFn}
          handleSave={handleCheckout}/>
      }

      {modalInventory && <ManageInventoryEventModal
        refresh={true}
        inventory={modalInventory}
        show={manageInventoryEvent}
        saving={saving}
        handleSave={handleSaveInventory}
        handleClose={handleCloseInventoryEventModal}/>}

      {sessionEventInventory && <ManageInventoryEventModal
        refresh={true}
        inventory={sessionEventInventory}
        show={manageInventorySessionEvent}
        saving={saving}
        handleClose={() => {
          setManageInventorySessionEvent(false)
        }}/>}

      <Footer mt={5}/>
    </DashboardLayout>
  );
}