import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {Card} from "@mui/material";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {useMaterialUIController} from "../../context";
import {formatDate, handleDefaultValues} from "../common/Utils";
import {useForm} from "react-hook-form";
import {getCompanyWaiver, saveCompanyWaiver, uploadTemplateFile} from "../common/api/WebApi";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import {ClassicEditor} from "ckeditor5";
import Divider from "@mui/material/Divider";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import Confirm from "../common/Confirm";
import {CONFIG} from "../common/EditorConfig";
import Switch from "@mui/material/Switch";


export default function WaiverManager(props) {

  let {guid} = useParams();

  const [isNew, setIsNew] = useState(true);

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [saving, setSaving] = useState(false);
  const [waiverReady, setWaiverReady] = useState(false);
  const [waiver, setWaiver] = useState();
  const [waiverBody, setWaiverBody] = useState();
  const [eventWaiver, setEventWaiver] = useState(false);

  const [waiverPublish, setWaiverPublish] = useState(false);
  const [formattedWaiverBody, setFormattedWaiverBody] = useState();
  const [waivers, setWaivers] = useState([]);
  const [templateIndex, setTemplateIndex] = useState(0);

  const [editorState, setEditorState] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [publishReady, setPublishReady] = useState(false);

  const handleConfirmPublish = () => {
    setWaiverPublish(true);
    setPublishReady(true);
  }

  useEffect(() => {
    if (!publishReady) {
      return;
    }
    setPublishReady(false);
    handleSubmit(handleSaveTemplate)().then(result => {
      setShowConfirm(false);
    });
  }, [publishReady]);


  const handleClosePublish = () => {
    setShowConfirm(false);
  }

  useEffect(() => {
    reset();
  }, []);

  const {register, handleSubmit, setValue, reset} = useForm({
    values: handleDefaultValues(waiver)
  });

  useEffect(() => {
    if (guid && 'new' !== guid) {
      setIsNew(false);
      setWaiverReady(false);
      getCompanyWaiver(guid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          setWaiver(result);
          setWaiverBody(result.waiverBody);
          setWaiverReady(true);
          setEventWaiver(result.eventWaiver);
        }
      });
    } else {
      reset();
      setWaiver({
        waiverName: null,
        id: null,
        guid: null,
        waiverBody: '',
      });

      setIsNew(true);
      setWaiverReady(true);
    }
  }, [guid])


  const formItems = [
    {icon: "person", label: "Parent / Guardian Name", replacement: "legal-name", type: "input"},
    {icon: "person_add", label: "Child", replacement: "child-name", type: "input"},
    {icon: "check_box", label: "Agree", replacement: "checkbox-agree", type: "checkbox"},
    {icon: "edit", label: "Signature", replacement: "signature", type: "text"},
    {icon: "event", label: "Date", replacement: "date", type: "date"},
  ];

  const sidenavItems = [
    {icon: "person", label: "First Name", replacement: "firstName"},
    {icon: "person", label: "Last Name", replacement: "lastName"},
    {icon: "person_outline", label: "Full Name", replacement: "fullName"},
    {icon: "people_outline", label: "Children Name", replacement: "childName"},
    {icon: "event", label: "Current Date", replacement: "currentDate"},
  ];

  const handleChange = (e, editor) => {
    setWaiverBody(editor.getData());
  }

  const confirmWaiverPublish = () => {
    let confirmation = {
      title: 'Publish Waiver?',
      message: 'This will publish the waiver to all customers.',
      entity: {}
    }
    setConfirmation(confirmation);
    setShowConfirm(true);
  }

  const handleSaveTemplate = async (data) => {
    setSaving(true);
    let d = {...data};
    d.waiverBody = waiverBody;
    d.publish = waiverPublish;
    d.eventWaiver = eventWaiver;

    let promise = saveCompanyWaiver(d);
    promise.then(result => {
      setSaving(false);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setWaiver(result);
        setWaiverBody(result.waiverBody);

        let _context = {...context};
        const toastMessage = waiverPublish ? 'Waiver successfully published.' : 'Waiver successfully saved.';
        _context.toast = {
          message: toastMessage,
          type: 'success'
        }
        setContext({..._context});
        setWaiverPublish(false);

        if (isNew) {
          navigate(`/communication/waivers/${result.guid}`);
        }
      }
    });
  }


  const renderItems = (itemList) => {
    return itemList.map(({icon, label, href, replacement, type}, key) => {
      const itemKey = `item-${key}`;

      return (

        type === "divider" ? <Divider key={itemKey} sx={{my: 1}}/> :
          <MDBox key={itemKey} component="li" pt={0}>
            <MDTypography
              component="a"
              href={`#${href}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                insertText('{' + replacement + '}');
              }}
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              sx={({
                     borders: {borderRadius},
                     functions: {pxToRem},
                     palette: {light},
                     transitions,
                   }) => ({
                display: "flex",
                alignItems: "center",
                borderRadius: borderRadius.md,
                padding: `${pxToRem(10)} ${pxToRem(16)}`,
                transition: transitions.create("background-color", {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                }),

                "&:hover": {
                  backgroundColor: light.main,
                },
              })}
            >
              <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                <Icon fontSize="small">{icon}</Icon>
              </MDBox>
              {label}
            </MDTypography>
          </MDBox>
      );
    });
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {

          const body = new FormData();
          loader.file.then((file) => {
            const fileType = file.type;
            const fileName = file.name;
            const reader = new FileReader();

            reader.onload = function (evt) {
              let fileRequest = {
                imageType: fileType,
                imageName: fileName,
                data: btoa(evt.target.result)
              }

              uploadTemplateFile(fileRequest).then(result => {
                if (result.error) {
                  setContext({...context, error: {message: result.message}});
                } else {
                  loader.uploaded = true;
                  loader.uploadUrl = result;
                }
                resolve({
                  default: result.fileLocation
                })
              });
            }
            reader.readAsBinaryString(file);
          });
        })
      }
    }
  }

  const [blurEditor, setBlurEditor] = useState();

  const insertText = (text) => {
    if (!blurEditor) {
      return;
    }

    blurEditor.model.change(writer => {
      writer.insertText(text + " ", blurEditor.model.document.selection.getFirstPosition());
      writer.setSelection(writer.createPositionAt(blurEditor.model.document.getRoot(), 'end'));
    });
  };

  const handleReady = (editor) => {
    setBlurEditor(editor);
  };

  const handleBlur = (event, editor) => {
    setBlurEditor(editor);
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleWaiverSubmit = (e) => {
    const formData = {};
    const form = document.getElementById('waiverForm');
    const elements = form.elements;
    for (let j in elements) {
      if (!elements[j].className?.match('replacement')) {
        continue;
      }
      if (!elements[j].value) {
        continue;
      }
      formData[elements[j].name] = elements[j].value;
    }
  }

  const formatReplacements = (html, replacement, placeholder, type) => {
    let _html = html;
    const pattern = '{' + replacement + '}';

    const regex = new RegExp(pattern, 'g');

    let matches = _html.match(regex);

    matches.forEach((item, index) => {
      _html = _html.replace(item, '<input required placeholder="'
        + placeholder + '" type="' + type + '" name="' + replacement + '-' + index + '" class="replacement" />');
    });

    return _html;
  }

  const formatPlaceholders = (html, replacement, placeholder, type) => {
    let _html = html;
    const pattern = '{' + replacement + '}';

    const regex = new RegExp(pattern, 'g');

    let matches = _html.match(regex);

    matches.forEach((item, index) => {
      _html = _html.replace(item, '{' + replacement + '-' + index + '}');
    });

    return _html;
  }

  const toForm = (html) => {
    const submitFn = '<s' + 'cript>\n function bar(e) { alert("foo"); \n e.preventDefault(); console.dir(e); const form = document.getElementById(\'waiverForm\'); console.dir(form); e.preventDefault(); return false; }\n</s' + 'cript>';
    const submitBtn = '<button onclick="bar()">Submit</button>';

    let _html = html;
    for (let j in formItems) {
      let item = formItems[j];
      _html = formatReplacements(_html, item.replacement, item.label, item.type);
    }
    // _html = formatReplacements(_html, 'legal-name', 'Legal Name', 'input');
    // _html = formatReplacements(_html, 'checkbox-agree', 'Agree', 'checkbox');
    // _html = formatReplacements(_html, 'date', 'Date', 'date');
    // _html = formatReplacements(_html, 'signature', 'Signature', 'text');


    if (1 == 1) {
      return _html;
    }

    return '<div><form id="waiverForm">' +  //
      _html  //
      + submitBtn  //
      + '</form>'  //
      + submitFn //
      + '</div>';
  }

  const toggleEventWaiver = () => {
    setEventWaiver(!eventWaiver);
  }

  return (
    <DashboardLayout>

      <Meta pageTitle={'Waiver Manager'}/>

      <ContextSearchNavbar title={'Waiver Manager'}/>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MDBox
              sx={{
                position: "sticky",
                top: "25px",
              }}>
              <Card
                sx={{
                  borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                }}
              >
                <MDTypography variant="h5" fontWeight="regular" p={2} pb={0}>
                  Waiver Replacements
                </MDTypography>
                <Divider/>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  pt={0}
                  m={0}
                  sx={{listStyle: "none"}}
                >
                  {renderItems(sidenavItems)}
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>

              <Grid container spacing={3}>
                <Grid item xs={12} id="company-info">
                  <Card>
                    <MDBox p={3}>
                      <Row>
                        <Col className={'col'} xs={12}>
                          <MDButton
                            variant="gradient"
                            size={'small'}
                            disabled={saving}
                            color="info"
                            className={'pull-right'}>
                            <span className={'fa fa-trash'}></span>&nbsp;
                            Archive Waiver</MDButton>
                        </Col>
                      </Row>
                      <MDTypography variant="h5" fontWeight="regular" className={'mb-2 d-block'}>
                        {waiver?.waiverName || 'Waiver Template'}
                      </MDTypography>

                      {waiverReady && <form onSubmit={handleSubmit(handleSaveTemplate)} id={'templateForm'}>
                        <Row>
                          {waiver.publishDate && <p className={'text-info'}>This waiver was published on {formatDate(waiver.publishDate)}</p>}
                          <Col className={'col'} sm={12} xs={12}>
                            <div className="form-group my-2">
                              <MDInput
                                variant="outlined" type="text"
                                {...register('waiverName')}
                                autoComplete="off" required
                                className="form-control" id="waiverName"
                                label="Enter Waiver Name"/>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className={'col'} sm={12} xs={12}>

                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                htmlSupport: {
                                  allow: [
                                    {
                                      name: /.*/,
                                      attributes: true,
                                      classes: true,
                                      styles: true
                                    }
                                  ]
                                },
                                placeholder: 'Enter Template Body',
                                toolbar: CONFIG.TOOLBAR,
                                plugins: CONFIG.PLUGINS,
                                extraPlugins: [
                                  uploadPlugin
                                ]
                              }}
                              data={waiverBody || ''}
                              onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log('Editor is ready to use!', editor);
                                handleReady(editor);
                              }}
                              onChange={(event, editor) => {
                                handleChange(event, editor);
                              }}
                              onBlur={(event, editor) => {
                                handleBlur(event, editor);
                              }}
                              onFocus={(event, editor) => {
                                // console.log('Focus.', editor);
                              }}
                            />
                            <Divider/>
                          </Col>
                        </Row>
                        <Row>
                          <Col className={'col'} sm={12} xs={12}>

                            <label
                              htmlFor="eventWaiver">
                              Event Waiver
                              <Switch
                                name={'eventWaiver'}
                                id={'eventWaiver'}
                                checked={eventWaiver}
                                style={{marginRight: '5px'}}
                                onChange={toggleEventWaiver}
                              /> </label>
                            <MDTypography variant="p" fontWeight="light" className={'mb-2 d-block'}>
                              Event Waivers can be assigned to Event Categories and will be agreed to at the time of
                              Event
                              booking. For example, a "Birthday Party Waiver" can be assigned to the "Birthday Party
                              Event
                              Type" and agreed to at the time of Party booking.
                            </MDTypography>

                          </Col>
                        </Row>
                        <Row>
                          <Col className={'col'} sm={12} xs={12}>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              size={'medium'}
                              disabled={saving}
                              color="info"
                              className={'pull-right'}>
                              <span className={'fa fa-save'}></span>&nbsp;
                              Save Waiver</MDButton>

                            <MDButton
                              type="submit"
                              variant="gradient"
                              size={'medium'}
                              disabled={saving}
                              color="dark"
                              onClick={(e) => {
                                e.preventDefault();
                                confirmWaiverPublish();
                              }}
                              className={'pull-right mx-2'}>
                              <span className={'fa fa-save'}></span>&nbsp;
                              {waiverPublish ? 'Publishing...' : 'Publish'} Waiver</MDButton>
                          </Col>
                        </Row>

                      </form>
                      }
                    </MDBox>
                  </Card>

                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      {
        confirmation && <Confirm confirmation={confirmation}
                                 show={showConfirm}
                                 saving={saving}
                                 handleConfirm={handleConfirmPublish}
                                 handleClose={handleClosePublish}/>
      }

      <Footer mt={5}/>

    </DashboardLayout>
  );
}