import {useContext} from "react";
import {AppContext} from "../../lib/AppContext";
import logo from '../../assets/images/psb-logo-animated.gif';

export default function ModalLoading(props) {
  const [context, setContext] = useContext(AppContext);

  return (
    <div className={'loading mt-1'}>
      <p className={'text-center'}>
        <img
          style={{
            width: '65px',
            height: '60px',
          }}
          src={logo} className={'logo-sm logo'}/>
    </p>
</div>
)
  ;

}